import { useState } from "react"
import { enUS } from "date-fns/locale"
import { DateRangePicker } from "react-nice-dates"
export const DateRangePickerComp = ({ startRangeDate, endRangeDate, setStartRangeDate, setEndRangeDate }: any) => {
  return (
    <DateRangePicker startDate={startRangeDate} endDate={endRangeDate} onStartDateChange={setStartRangeDate} onEndDateChange={setEndRangeDate} minimumLength={1} maximumDate={new Date()} format="MM.dd.yyyy" locale={enUS}>
      {({ startDateInputProps, endDateInputProps }) => (
        <div className="date-range flex justify-between text-[14px]">
          <input className="input-focused bg-white w-28 px-1 border border-1 border-gray-200 rounded mr-1" {...startDateInputProps} placeholder="Start date" />
          <input className="input-focused bg-white w-28 px-1 border border-1 border-gray-200 rounded " {...endDateInputProps} placeholder="End date" />
        </div>
      )}
    </DateRangePicker>
  )
}
