import { IOption } from "../../components/Inputs/Select/Select.interfaces"
import * as types from "./actionTypes"
export const setDate = (date: Date): { type: string; payload: Date } => {
  return {
    type: types.SET_DATE,
    payload: date,
  }
}
export const nextDate = (): { type: string } => {
  return {
    type: types.NEXT_DATE,
  }
}
export const prevDate = (): { type: string } => {
  return {
    type: types.PREV_DATE,
  }
}
export const setAppts = (appts: any): { type: string; payload: any } => {
  return {
    type: types.SET_APPTS,
    payload: appts,
  }
}
export const fetchAppts = (payload: any): { type: string; payload: any } => {
  return {
    type: types.FETCH_APPTS,
    payload: payload,
  }
}
export const setProviders = (providers: any): { type: string; payload: any } => {
  return {
    type: types.SET_PROVIDERS,
    payload: providers,
  }
}
export const fetchProviders = (payload: any): { type: string; payload: any } => {
  return {
    type: types.FETCH_PROVIDERS,
    payload: payload,
  }
}
export const setOperatories = (operatories: any): { type: string; payload: any } => {
  return {
    type: types.SET_OPERATORIES,
    payload: operatories,
  }
}
export const fetchOperatories = (payload: any): { type: string; payload: any } => {
  return {
    type: types.FETCH_OPERATORIES,
    payload: payload,
  }
}
export const handleApptsSubscription = (payload: any): { type: string; payload: any } => {
  return {
    type: types.HANDLE_APPTS_SUBSCRIPTION,
    payload: payload,
  }
}
export const addAppointment = (payload: any): { type: string; payload: any } => {
  return {
    type: types.ADD_APPT,
    payload: payload,
  }
}
export const deleteAppointment = (payload: any): { type: string; payload: any } => {
  return {
    type: types.DELETE_APPT,
    payload: payload,
  }
}
export const updatePatientInAppts = (payload: any): { type: string; payload: any } => {
  return {
    type: types.UPDATE_PATIENT_IN_APPTS,
    payload: payload,
  }
}
export const updateLocalAppointment = (payload: any): { type: string; payload: any } => {
  return {
    type: types.UPDATE_LOCAL_APPOINTMENT,
    payload: payload,
  }
}

export const schedulerPageLocationAndDate = (payload: any) => {
  return {
    type: types.SCHEDULE_PAGE_LOCATION_AND_DATE,
    payload: payload,
  }
}
