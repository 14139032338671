import { useState, useEffect, useRef } from "react"
import { useTable } from "react-table"
import shortid from "shortid"
import { MessageWrapper } from "./Table.utils"
import Error from "../../../components/DataDisplay/Error"
import Spinner from "../../../components/Spinner"

const VirtualTable = ({ tableId, columns, data, queueKey, isLoading }: any): JSX.Element => {
  const cellSize = "px-2 py-2"
  const tableScrollerRef = useRef<HTMLDivElement>(null)
  const [queueRows, setQueueRows] = useState([])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: columns,
    data: queueRows,
  })

  // initial page load call
  useEffect(() => {
    if (data.length) {
      const filteredList = data.filter((x: any) => x?.status === queueKey)
      const sortedFilteredList = filteredList.sort(function (x: any, y: any) {//sort the array where the wait time of patient is longest at top and it decreases as it gets to the bottom.
        return new Date(x.updatedAt).getTime() - new Date(y.updatedAt).getTime()
      })
      setQueueRows(sortedFilteredList)
    }
  }, [data])

  return (
    <div className="mt-4 flex flex-1 flex-col font-medium relative" id={tableId}>
      { isLoading ? <Spinner name={`h-10 w-10`} /> : (queueRows.length > 0 ? (
        <div className="-my-2 overflow-hidden pr-0.5 sm:-mx-6 lg:-mx-8 flex-1 flex-col">
          <div className="py-2 align-middle min-w-full sm:px-6 lg:px-8 flex flex-1 h-full flex-col">
            <div className="overflow-auto p-2 -m-1.5 sm:p-1 sm:-m-1 h-full dark:border-transparent">
              <table className="relative shadow rounded-lg border-b border-collapse border-gray-200 dark:border-b-0 min-w-full divide-y divide-gray-200 dark:divide-black-700" {...getTableProps()}>
                <thead className="bg-white dark:bg-black-900 text-gray-400">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={`${shortid.generate()}`}>
                      {headerGroup.headers.map((column) => (
                        <th className={`group ${cellSize} text-left text-sm font-semibold dark:text-white text-gray-400 uppercase tracking-wider`} {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className="bg-white divide-y divide-gray-200" {...getTableBodyProps()}>
                  {rows.map((row: any) => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()} className="hover:bg-gray-100 dark:hover:bg-black-800 transition duration-200 cursor-pointer">
                        {row.cells.map((cell: any) => {
                          return (
                            <td {...cell.getCellProps()} className={`${cellSize} whitespace-nowrap text-sm`} role="cell">
                              {cell.render("Cell")}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <>
          <MessageWrapper>
            <Error type="no-data" title="" message={"No patients in waiting queue"} styles={{ position: "absolute", top: "0", right: "0", left: "0", bottom: "0" }} />
          </MessageWrapper>
        </>
      ))}
    </div>
  )
}

export default VirtualTable
