import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"

interface ChatItem {
  userId: string
  question: string
  answer: string
  objectId: string
}

interface SideModalProps {
  isOpen: boolean
  onClose: () => void
  selectedChatItem?: ChatItem | null
}

const SideModal: React.FC<SideModalProps> = ({ isOpen, selectedChatItem, onClose }) => {
  const [editableQuestion, setEditableQuestion] = useState("")
  const [editableAnswer, setEditableAnswer] = useState("")
  const apiUrl = process.env.REACT_APP_SERVER_URL_CHAT || ""
  const baseUrl = process.env.REACT_APP_API_URL_GPT || ""



  useEffect(() => {
    if (selectedChatItem) {
      setEditableQuestion(selectedChatItem.question)
      setEditableAnswer(selectedChatItem.answer)
    }
  }, [selectedChatItem])

  const handleClose = (): void => {
    onClose()
  }

  const removeHtmlTags = (input: string): string => input.replace(/<[^>]*>/g, "")

  const cleanedAnswer = removeHtmlTags(editableAnswer)

  const handleSave = async () => {
    try {
      if (!selectedChatItem) {
        throw new Error("Chat data is not available");
      }

      const response = await fetch(`${apiUrl}/api/chats/updateById/${selectedChatItem.objectId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: editableQuestion,
          approvedAnswer: editableAnswer,
          category: "approved",
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to update message. Status: ${response.status}`);
      }
  
      toast.success("Chat Updated Successfully and Ready for Training!");
  
      const gptCorrectionResponse = await fetch(`${baseUrl}/correction`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: editableQuestion,
          response: editableAnswer,
        }),
      });
  
      if (!gptCorrectionResponse.ok) {
        throw new Error(`Failed to send correction to GPT. Status: ${gptCorrectionResponse.status}`);
      }
  
      toast.success("Correction sent successfully to GPT for Training!");
  
      onClose();
      window.location.reload();
    } catch (error) {
      toast.error("Error Updating message! Please try again");
    }
  };
  

  return (
    <div>
      {isOpen && (
        <div className={`fixed inset-0 bg-gray-800 bg-opacity-50 z-60`}>
          <div role="dialog" tabIndex={-1} aria-modal="true" className={`fixed right-0 top-0 bottom-0 w-full max-w-md transition-all duration-300 transform ${isOpen ? "translate-x-0" : "translate-x-full"} translate-y-0 translate-z-0 bg-white shadow-lg`}>
            <header className="p-4 text-lg font-bold">Add Corrections</header>
            <div className="p-4">
              <div className="mb-4">
                <label htmlFor="question" className="block font-bold mb-1">
                  Question<span className="text-red-500">*</span>
                </label>
                <textarea rows={2} name="question" id="question" required className="w-full border rounded-md p-2" value={editableQuestion} onChange={(e) => setEditableQuestion(e.target.value)} placeholder="user question"></textarea>
              </div>
              <div className="mb-4">
                <label htmlFor="modelAnswer" className="block font-bold mb-1">
                  Model Answer<span className="text-red-500">*</span>
                </label>
                <p className="text-gray-500 mb-2">Write the correct answer to the question raised</p>
                <textarea rows={10} name="modelAnswer" id="modelAnswer" required className="w-full border rounded-md p-2" value={cleanedAnswer} onChange={(e) => setEditableAnswer(e.target.value)} placeholder="I'm sorry, but I don't have any information about Mark Zuckerberg."></textarea>
              </div>
            </div>
            <div className="px-6 flex justify-end space-x-4">
              <button type="button" onClick={handleClose} className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-400 focus:outline-none focus:ring focus:border-blue-300">
                Cancel
              </button>
              <button type="submit" onClick={handleSave} className="bg-green-600 text-white border-gray-400 px-4 py-2 rounded-lg hover:bg-green-500">
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SideModal
