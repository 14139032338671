import { Fragment, useEffect, useState } from "react"
import { ReviewCard } from "./reviewCard"
import { getFeedback, getFeedbackByProvider } from "../../Store/Feedback/actions"
import { useDispatch, useSelector } from "react-redux"
import PatientSelect from "../../components/Inputs/PatientSelect"
import ProviderSelect from "../../components/Inputs/Provider/ProviderSelect"
import Select from "../../components/Inputs/Select"
import useLocationsSelect from "../../hooks/useLocationsSelect"
import { formatDate } from "../../utils/helpers"
import { ChevronDownIcon, ExclamationIcon } from "@heroicons/react/solid"
import { Chart } from "./gauge"
import { Listbox, Menu, Tab, Transition } from "@headlessui/react"
import classNames from "classnames"
import { DateRangePickerComp } from "./dateRangePicker"
import Parse from "parse"
import { toast } from "react-toastify"
import { ReviewLinks } from "./reviewLinks"

const sources = [
  { id: 1, name: "All Reviews", value: "all" },
  { id: 2, name: "Web", value: "web" },
  { id: 2, name: "Mobile", value: "mobile" },
]

export const Reviews = () => {
  const authUser = useSelector((state: any) => state?.Auth?.user)
  // Tab States
  const tabs = ["Reviews", "Reviews Links"]
  const [activeTab, setActiveTab] = useState(0)
  const search = window.location.search
  const params = new URLSearchParams(search)
  const appointmentId = params.get("id")

  // For Testing the Send Review Form Link Notification
  const handleSendNotification = async () => {
    if (appointmentId) {
      try {
        const appt = await new Parse.Query("AppointmentV1").include("patient").get(appointmentId)
        if (appt.get("status") !== "COMPLETED") {
          toast.error("Appointment is not Completed yet!")
        } else {
          if (appt.get("patient")?.get("phones")?.[0].number !== "") {
            Parse.Cloud.run("sendPatientReviewFormNotification", {
              appointmentId: appointmentId, // "9000034631451",
            })
            toast.success("Review link sent!")
          } else {
            toast.error("Number does not exist")
          }
        }
      } catch (error: any) {
        toast.error(error.message)
      }
    } else {
      toast.error("No Appointment Id given!")
    }
  }
  return (
    <section className="overflow-y-auto max-h-[calc(100vh-100px)] min-w-0 h-full flex flex-col dark:bg-black-700 relative">
      <Tab.Group selectedIndex={activeTab} onChange={setActiveTab}>
        <Tab.List className="flex space-x-8">
          {tabs.map((tab) => (
            <Tab key={tab} className={({ selected }): string => classNames(selected ? "border-green-500 text-green-600" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300", "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm outline-none")}>
              {tab}
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
      {activeTab === 0 ? (
        <>
          <div className="flex items-center justify-between sm:my-3">
            <h2 className="text-3xl xl:ml-10 mb-5 sm:mb-0 font-bold dark:text-white">Reviews</h2>

            <button
              className="rounded-md xl:mr-10 py-2 px-2 flex justify-between dark:text-white border 
                border-gray-300 bg-white dark:bg-black-900 dark:border-black-900 shadow-sm focus:outline-none 
                focus:ring-2 dark:focus:ring-offset-black-700 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
              onClick={handleSendNotification}
            >
              Send Review Link
            </button>
          </div>
          <div className="w-full xl:w-[1300px] xl:mx-auto">{authUser?.isProvider ? <ReviewsForProvider providerId={authUser?.objectId} /> : <ReviewsForAdmin />}</div>
        </>
      ) : (
        <ReviewLinks />
      )}
    </section>
  )
}

export const NotFoundComp = ({ paragraph }: any) => {
  return (
    <div className="mt-10 h-full">
      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 lg:h-14 lg:w-14 rounded-full bg-white-100 shadow-xl border border-1 border-gray-100 dark:bg-black-800 mx-auto ">
        <ExclamationIcon className="h-6 w-6 text-red-600 dark:text-black-200" aria-hidden="true" />
      </div>
      <p className="text-center text-gray-400 text-lg mt-3">{paragraph}</p>
    </div>
  )
}
export const SourceSelect = ({ reviewSource, setReviewSource }: any) => {
  return (
    <div className="w-full text-sm sm:text-base relative">
      <Listbox value={reviewSource} onChange={setReviewSource}>
        {({ open }) => (
          <>
            <Listbox.Button className="relative w-full dark:bg-black-900 bg-white border dark:border-black-900 border-gray-300 rounded-md shadow-sm pl-3 pr-3 py-2 text-left dark:text-white cursor-default focus:outline-none focus:ring-2 dark:focus:ring-offset-black-700 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm">
              {reviewSource && reviewSource?.name}
            </Listbox.Button>
            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute z-30 mt-1 w-full dark:bg-black-800 bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {sources.map((source: any, i: any) => (
                  <Listbox.Option key={i} value={source} className={({ active }) => classNames(active ? "text-white dark:bg-black-900 bg-gray-600" : "dark:text-black-600 text-gray-900", "cursor-default select-none relative py-2 px-3")}>
                    {source.name}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
    </div>
  )
}

export const ReviewsForProvider = ({ providerId }: any) => {
  const dispatch = useDispatch()
  const reviewList = useSelector((state: any) => state?.Feedback?.AllReviews)

  useEffect(() => {
    dispatch(
      getFeedbackByProvider({
        providerId: providerId,
      })
    )
  }, [])

  return (
    <div className="overflow-y-auto h-full max-h-[calc(100vh-100px)] pb-2 pr-5">
      <div className="grid grid-cols-1 gap-4">
        {reviewList.length ? (
          reviewList.map((review: any, i: any) => {
            return <ReviewCard key={i} review={review} />
          })
        ) : (
          <div className="h-96">
            <NotFoundComp paragraph="No reviews found!" />
          </div>
        )}
      </div>
    </div>
  )
}

export const ReviewsForAdmin = () => {
  const dispatch = useDispatch()
  const [patient, setPatient] = useState<any>(null)
  const [provider, setProvider] = useState<any>(null)
  const [location, setLocation] = useState<any>(null)
  const [reviewSource, setReviewSource] = useState<any>(sources[0])
  const [detractors, setDetractors] = useState<any>()
  const [passives, setPassives] = useState<any>()
  const [promoters, setPromoters] = useState<any>()
  const [nps, setNps] = useState<any>(0)
  const [startRangeDate, setStartRangeDate] = useState<any>()
  const [endRangeDate, setEndRangeDate] = useState<any>()

  const reviewList = useSelector((state: any) => state?.Feedback?.AllReviews)
  const appointment = useSelector((state: any) => state?.Appointment)

  const [locationArrayState] = useLocationsSelect(appointment?.locations || [])

  useEffect(() => {
    if (patient || provider || location || reviewSource || startRangeDate || endRangeDate) {
      dispatch(
        getFeedback({
          patientId: patient?.id ?? patient?.objectId,
          providerId: provider?.id,
          locationId: location?.id,
          source: reviewSource.value,
          startRangeDate: startRangeDate ? startRangeDate : null,
          endRangeDate: endRangeDate ? endRangeDate : null,
        })
      )
    }
  }, [patient, provider, location, reviewSource, startRangeDate, endRangeDate])

  const handleResetFilter = () => {
    setPatient(null)
    setProvider(null)
    setLocation(null)
    setReviewSource(sources[0])
    setStartRangeDate(null)
    setEndRangeDate(null)
  }

  const handleNPS = () => {
    const totalReviews = reviewList?.length
    let detractorsArray = []
    let promotersArray = []
    let passivesArray = []
    for (let list of reviewList) {
      if (list?.rating && list.rating >= 1 && list.rating <= 6) {
        detractorsArray.push(list.rating)
      } else if (list?.rating && (list.rating === 7 || list.rating === 8)) {
        passivesArray.push(list.rating)
      } else if (list?.rating && (list.rating === 9 || list.rating === 10)) {
        promotersArray.push(list.rating)
      }
    }
    setDetractors({
      percentage: (detractorsArray.length / totalReviews) * 100 ?? 0,
      length: detractorsArray.length,
    })
    setPassives({
      percentage: (passivesArray.length / totalReviews) * 100,
      length: passivesArray.length,
    })
    setPromoters({
      percentage: (promotersArray.length / totalReviews) * 100,
      length: promotersArray.length,
    })
  }

  useEffect(() => {
    const npsValue = !isNaN(promoters?.percentage) && !isNaN(detractors?.percentage) ? Number((promoters?.percentage - detractors?.percentage).toFixed(1)) : 0
    setNps(npsValue)
  }, [promoters, detractors])

  useEffect(() => {
    handleNPS()
  }, [reviewList])

  return (
    <>
      <div>
        <div className="font-normal hidden sm:block text-gray-700 dark:text-gray-400 max-w-[338px] mx-auto">{!isNaN(nps) && <Chart value={nps} />}</div>
        <table className="text-left table-fixed w-full text-gray-600 text-sm xl:text-md mb-2 shadow">
          <thead className="font-medium bg-gray-100">
            <tr>
              <th className="py-1 px-2">DETRACTORS (0-6)</th>
              <th className="py-1 px-2">PASSIVES (7-8)</th>
              <th className="py-1 px-2">PROMOTERS (9-10)</th>
              <th className="py-1 px-2">NET PROMOTER SCORE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-1 px-2 pb-0">
                <div>{`${detractors?.length > 0 ? detractors?.percentage?.toFixed(2) : 0}%`}</div>
              </td>
              <td className="py-1 px-2 pb-0">
                <div>{`${passives?.length > 0 ? passives?.percentage?.toFixed(2) : 0}%`}</div>
              </td>
              <td className="py-1 px-2 pb-0">
                <div>{`${promoters?.length > 0 ? promoters?.percentage?.toFixed(2) : 0}%`}</div>
              </td>
              <td className="py-1 px-2 pb-0">
                <div>{nps}</div>
              </td>
            </tr>
            <tr>
              <td className="px-2">
                <div>{detractors?.length}</div>
              </td>
              <td className="px-2">
                <div>{passives?.length}</div>
              </td>
              <td className="px-2">
                <div>{promoters?.length}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="xl:px-12">
        <div className="flex flex-wrap my-8 sm:my-4 lg:my-8">
          <div className="max-w-xs p-[5px] bg-gray-200 shadow-md rounded-md text-sm hidden md:flex justify-center items-center mr-2 mb-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
              />
            </svg>
          </div>
          <div className="max-w-xs w-[115px] p-[5px] bg-gray-100 shadow-md rounded-md text-sm mr-2 mb-2">
            <SourceSelect reviewSource={reviewSource} setReviewSource={setReviewSource} />
          </div>
          <div className="w-[220px] p-[5px] bg-gray-100 shadow-md rounded-md text-sm mr-2 mb-2 relative">
            <Menu>
              <Menu.Button className="w-full">
                <div
                  className="rounded-md py-2 px-2 flex justify-between dark:text-white border 
                border-gray-300 bg-white dark:bg-black-900 dark:border-black-900 shadow-sm focus:outline-none 
                focus:ring-2 dark:focus:ring-offset-black-700 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
                >
                  <span className="mr-1">{startRangeDate ? `${formatDate(startRangeDate)} - ${formatDate(endRangeDate)}` : "By Date Range"}</span> <ChevronDownIcon className=" w-5" />
                </div>
              </Menu.Button>
              <Menu.Items>
                <div className="relative text-lg">
                  <div className="absolute bg-white right-0 top-1 w-60 p-1 border border-1 border-gray-200 rounded z-10">
                    <div className="relative">
                      <DateRangePickerComp startRangeDate={startRangeDate} setStartRangeDate={setStartRangeDate} endRangeDate={endRangeDate} setEndRangeDate={setEndRangeDate} />
                    </div>
                  </div>
                </div>
              </Menu.Items>
            </Menu>
          </div>
          <div className="max-w-xs w-48 lg:w-52 p-[5px] bg-gray-100 shadow-md rounded-md text-sm mr-2 mb-2">
            <PatientSelect className="py-2" onChange={(patient: any) => setPatient(patient)} placeholder="Search Patient" patient={patient} isReviewRequest={true} />
          </div>
          <div className="max-w-xs w-48 lg:w-52 p-[5px] bg-gray-100 shadow-md rounded-md text-sm mr-2 mb-2">
            <ProviderSelect className="py-2" onChange={(provider: any) => setProvider(provider)} placeholder="Search Provider" provider={provider} />
          </div>
          <div className="w-[170px] p-[5px] bg-gray-100 shadow-md rounded-md text-sm mr-2 mb-2">
            <Select defaultValue="Select Location" options={locationArrayState} location={location} onChange={(event: any) => setLocation(event)} key="id" />
          </div>

          <div className="flex items-center justify-center">
            <button type="button" onClick={handleResetFilter} className="bg-gray-200 hover:bg-gray-400 hover:text-white hover:shadow-md px-4 rounded-full text-sm py-2 text-gray-600">
              Reset
            </button>
          </div>
        </div>

        <div className="overflow-y-auto h-full max-h-[calc(100vh-300px)] pb-2 pr-5">
          <div className="grid grid-cols-1 gap-4">
            {reviewList.length ? (
              reviewList.map((review: any, i: any) => {
                return <ReviewCard key={i} review={review} />
              })
            ) : (
              <div className="h-96">
                <NotFoundComp paragraph="No reviews found!" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
