import produce from "immer"
import { IAppointment } from "../../pages/schedule/schedule-template/Template.interfaces"
import { Operatory } from "../../Types/OperatoryTypes"
import { LOGOUT } from "../Auth/actionTypes"
import * as types from "./actionTypes"

interface InitialState {
  date: any
  appointments: IAppointment[]
  providers: any[]
  operatories: Operatory[]
  appointmentsSubscription: any
  schedulerPageLocationAndDate: boolean
}

const initialState: InitialState = {
  date: new Date(),
  appointments: [],
  providers: [],
  operatories: [],
  appointmentsSubscription: null,
  schedulerPageLocationAndDate: false,
}

const Schedule = produce((state, action) => {
  switch (action.type) {
    case LOGOUT:
      state.date = new Date()
      state.appointments = []
      state.providers = []
      state.operatories = []
      state.appointmentsSubscription = null
      state.schedulerPageLocationAndDate = false
      break
    case types.SCHEDULE_PAGE_LOCATION_AND_DATE:
      state.schedulerPageLocationAndDate = action.payload
      break
    case types.SET_DATE:
      state.date = action.payload
      break
    case types.NEXT_DATE: {
      const nextDate = new Date(state.date)
      nextDate.setDate(nextDate.getDate() + 1)
      state.date = nextDate
      break
    }
    case types.PREV_DATE: {
      const prevDate = new Date(state.date)
      prevDate.setDate(prevDate.getDate() - 1)
      state.date = prevDate
      break
    }
    case types.SET_APPTS: {
      state.appointments = action.payload
      break
    }
    case types.SET_PROVIDERS: {
      state.providers = action.payload
      break
    }
    case types.SET_OPERATORIES: {
      state.operatories = action.payload
      break
    }
    case types.HANDLE_APPTS_SUBSCRIPTION: {
      if (state.appointmentsSubscription && state?.appointmentsSubscription?.unsubscribe) {
        state?.appointmentsSubscription?.unsubscribe?.()
      }
      state.appointmentsSubscription = action.payload
      break
    }
    case types.ADD_APPT: {
      state.appointments = [...state.appointments, action.payload]
      break
    }
    case types.DELETE_APPT: {
      state.appointments = state.appointments?.filter?.((appt: any) => appt?.objectId !== action?.payload?.objectId)
      break
    }
    case types.UPDATE_PATIENT_IN_APPTS: {
      state.appointments = state.appointments?.map?.((appt: any) => {
        if (action?.payload?.objectId === appt?.patient?.objectId) {
          return { ...appt, patient: { ...appt?.patient, ...action?.payload } }
        }
        return appt
      })
      break
    }
    case types.UPDATE_LOCAL_APPOINTMENT: {
      state.appointments = state.appointments?.map?.((appt: any) => {
        if (action?.payload?.objectId === appt?.objectId) {
          return { ...appt, ...action.payload }
        }
        return appt
      })
      break
    }
    default:
      break
  }
}, initialState)

export default Schedule
