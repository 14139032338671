import { Dialog, Transition } from "@headlessui/react"
import { ExclamationIcon } from "@heroicons/react/outline"
import { Fragment, useRef } from "react"
import Button from "../../components/Inputs/Button"

const BillingConfirmationPopup = ({ handleCharge, confirmationPopup, appointment, setConfirmationPopup, loading, title, bodyText }: any): JSX.Element => {
  const handleCancel = () => {
    setConfirmationPopup(false)
  }

  return (
    <Transition.Root show={confirmationPopup} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-y-auto" onClose={handleCancel} style={{ zIndex: 100 }}>
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity overlay-dialog" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white dark:bg-black-700 rounded-lg space-y-3 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full p-10">
              <div className="sm:flex sm:flex-col">
                <div className="flex-shrink-0 flex items-center justify-center h-10 mb-5 w-10 lg:h-14 lg:w-14 rounded-full bg-white-100 shadow-xl border border-1 border-gray-100 dark:bg-black-800 mx-auto ">
                  <ExclamationIcon className="h-6 w-6 text-red-600 dark:text-black-200" aria-hidden="true" />
                </div>
                <div className="text-center mt-5 sm:text-left">
                  <Dialog.Title as="h2" className="text-xl text-center leading-6 font-medium text-gray-900 dark:text-white">
                    {title}
                  </Dialog.Title>
                  <div className="my-4 ">
                    <p className="text-md text-gray-500 text-center dark:text-gray-200 px-4 mb-10">
                      {bodyText}&nbsp;
                      <b>
                        {appointment?.get('firstName')}&nbsp;{appointment?.get('lastName')}
                      </b>
                      ?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse justify-center">
                <div>
                  <Button className="w-32 ml-3 border-green-500" variant="outlined" onClick={() => handleCharge(appointment.id)} loading={loading}>
                    Agree
                  </Button>
                </div>
                <div>
                  <Button className="w-32 border-red-500" variant="outlined" onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default BillingConfirmationPopup
