import React, { useState } from "react"
import QuestionCard from "./QuestionCard"

const Index: React.FC = () => {
  const [activeTab, setActiveTab] = useState("approved")

  const handleTabClick = (tab: string) => {
    setActiveTab(tab)
  }

  return (
    <div>
      <div className="flex items-center flex-col ml-6 mb-4 mt-6 sm:flex-row justify-start">
        <button className={`mb-2 lg:mb-0 px-4 py-2 border-2 rounded-lg ${activeTab === "approved" ? "bg-green-600 text-white border-gray-400" : "bg-transparent text-green-600 border-gray-200"} transition duration-300 ease-in-out`} onClick={() => handleTabClick("approved")}>
          Approved
        </button>
        <button className={`mb-2 lg:mb-0 mx-0 sm:mx-4 px-4 py-2 border-2 rounded-lg ${activeTab === "pending" ? "bg-green-600 text-white border-gray-400" : "bg-transparent text-green-600 border-gray-200"} transition duration-300 ease-in-out`} onClick={() => handleTabClick("pending")}>
          Pending
        </button>
        <button className={`mb-2 lg:mb-0 px-4 py-2 border-2 rounded-lg ${activeTab === "ignored" ? "bg-green-600 text-white border-gray-400" : "bg-transparent text-green-600 border-gray-200"} transition duration-300 ease-in-out`} onClick={() => handleTabClick("ignored")}>
          Ignored
        </button>
      </div>
      <div className="pb-2 md:pb-5">
        {activeTab === "approved" && <QuestionCard category={"approved"} />}
        {activeTab === "pending" && <QuestionCard category={"pending"} />}
        {activeTab === "ignored" && <QuestionCard category={"ignored"} />}
      </div>
    </div>
  )
}

export default Index
