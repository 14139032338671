import Parse from "parse"
import { useEffect, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { Tab } from "@headlessui/react"
import { IReactivationLogDetailsModalProps } from "./AutomateModal.interfaces"
import Spinner from "../Spinner"

const ReactivationLogDetailsModal = ({ isOpen, closeModal, automateModalSection, reactivation }: IReactivationLogDetailsModalProps): JSX.Element => {
  const tabs = [
    {
      index: 0,
      title: "Activity Logs",
    },
  ]

  const [selectedIndex, setSelectedIndex] = useState(automateModalSection)
  const [page, setPage] = useState<any>(automateModalSection)
  const [logs, setLogs] = useState<Parse.Object[]>([])

  useEffect(() => {
    setPage(automateModalSection)
    setSelectedIndex(automateModalSection)
  }, [automateModalSection])

  useEffect(() => {
    getAllReactivationLogs()
  }, [reactivation])

  const getAllReactivationLogs = async () => {
    const query = reactivation.relation("logs").query()
    query.addAscending("createdAt")
    const allLogs = await query.find()
    console.log(allLogs)
    setLogs(allLogs)
  }

  return (
    <Transition appear show={isOpen} as={"div"}>
      <Dialog as="div" className="fixed inset-0 overflow-y-auto z-50" onClose={() => closeModal(false)}>
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:px-4 sm:p-0">
          <Transition.Child
            as={"div"}
            className="w-full"
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white dark:bg-black-700 rounded-lg text-left overflow-visible transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-4xl sm:w-full" style={{ boxShadow: "rgb(0 0 0 / 10%) 1px 1px 8px 5px" }}>
              <section className="flex flex-col max-w-screen-2xl overflow-scroll sm:pt-40 mx-auto md:pt-0">
                <div className="w-full px-4 py-4 xs:py-20 md:px-10 lg:px-8 2xl:pr-20 2xl:pl-8 border-b border-gray-300">
                  <h1 className="font-medium text-xl text-gray-700 dark:text-white capitalize">Reactivation Details For {reactivation.createdAt.toLocaleString("en-US")}</h1>
                </div>
                <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                  <Tab.List className="flex space-x-8 border-gray-300 border-b relative" style={{ justifyContent: "center" }}>
                    {tabs.map((tab) => (
                      <Tab
                        key={tab.index}
                        className={`${tab.index === page ? "text-green-500 border-green-400" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"} whitespace-nowrap py-2 px-1 border-b-2 font-medium text-md`}
                        onClick={() => {
                          setPage(tab.index)
                        }}
                      >
                        {tab.title}
                      </Tab>
                    ))}
                  </Tab.List>
                  {page == 0 ? (
                    <>
                      <div className="flex-1 flex flex-col-reverse md:flex-row">
                        <div className="flex flex-col automationHeight flex-1 w-full px-4 pt-4 pb-5 overflow-scroll xs:py-8 md:w-1/2 md:px-8 lg:px-8 lg:pt-5 2xl:pr-8 2xl:pl-8 mb-8 border-r border-gray-300">
                          <div className="p-4 mb-5 h-auto relative" style={{ boxShadow: "rgb(0 0 0 / 10%) 1px 1px 8px 5px" }}>
                            <div className="flex relative flex-col flex-1 w-full px-4 pt-0 pb-5">
                              <p className="mb-3 text-lg font-semibold">History logs</p>
                              <div className="overflow-auto historyLogSection pl-4">
                                <ol className="relative border-l border-slate-600 dark:border-slate-700">
                                  {logs?.length > 0 ? (
                                    <>
                                      {logs.map((log: Parse.Object) => {
                                        const patient = log.get("data").patient
                                        return (
                                          <div className={`flex flex-col flex-1 w-full px-4 ml-4 pt-4 mb-5 overflow-scroll bg-${log.get("code") === 200 ? "green-100" : "red-100"}`}>
                                            <div className="flex">
                                              <div>
                                                <li key={log.id} className="mb-3 ml-4">
                                                  <div
                                                    className={`absolute w-3 h-3 bg-${log.get("code") === 200 ? "green-600" : "red-600"} rounded-full mt-1.5 -left-1.5 border border-white dark:border-${log.get("code") === 200 ? "green-100" : "red-100"}-900 dark:bg-${
                                                      log.get("code") === 200 ? "green-100" : "red-100"
                                                    }-700`}
                                                  ></div>
                                                  <time className="mb-1 text-sm font-normal leading-none text-gray-500 dark:text-gray-500">{log.createdAt.toLocaleString("en-US")}</time>
                                                  <h3 className="text-md font-semibold text-gray-900 dark:text-white">
                                                    {patient?.get("firstName")} {patient?.get("lastName")}
                                                  </h3>
                                                  <time className="mb-1 text-sm font-normal leading-none text-gray-500 dark:text-gray-500">{patient?.get("emailAddress")}</time>
                                                  <p className="text-sm font-normal text-gray-500 dark:text-gray-400 flex">
                                                    <b>Code: {log.get("code")}</b>&nbsp;
                                                  </p>
                                                  <p className="mb-4 text-sm font-normal text-gray-500 dark:text-gray-400 flex">
                                                    <b>Message :</b>&nbsp;{log.get("message")}
                                                  </p>
                                                </li>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      })}
                                    </>
                                  ) : (
                                    <Spinner name={`h-10 w-10`} />
                                  )}
                                </ol>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : page == 1 ? (
                    <>All Patients Tab UI</>
                  ) : null}
                </Tab.Group>
                <div className="p-4 border-t border-gray-300 text-right">
                  <button
                    type="button"
                    className="mr-2 inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                    onClick={() => closeModal(false)}
                  >
                    Close
                  </button>
                </div>
              </section>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
export default ReactivationLogDetailsModal
