import * as Parse from "parse"
import { fork, put, all, takeLatest } from "redux-saga/effects"
import * as types from "./actionTypes"
import { sagaErrorHandler } from "../../utils/SagaErrorHandler"
import { IAppointment } from "../../pages/schedule/schedule-template/Template.interfaces"
import { excludeParseQueryFields, formatDate } from "../../utils/helpers"
import { APPOINTMENT_WHITELIST, OPERATORY_WHITELIST } from "../../constants/Whitelists"
import { addAppointment, deleteAppointment, handleApptsSubscription, setAppts, setOperatories, setProviders } from "./actions"
import { updateHeaderRow, updateTimeSlots } from "../../pages/schedule/utils"
import { Operatory } from "../../Types/OperatoryTypes"
import _ from "lodash"
import momentTimeZone from "moment-timezone"

function* fetchApptsRequest({ payload }: any): any {
  const { date, location, status, providers, dispatch, loadingProgressRef, callback } = payload
  const appointmentQuery = new Parse.Query("AppointmentV1")

  loadingProgressRef.current?.continuousStart(0)

  const LocationV1 = Parse.Object.extend("LocationV1")

  const fdate = formatDate(date, "YYYY-MM-DD")

  const start = momentTimeZone.tz(fdate, "America/Los_Angeles").startOf("day")
  const end = momentTimeZone.tz(fdate, "America/Los_Angeles").endOf("day")

  /**
   * Exclude unnecessary fields from query.
   */
  excludeParseQueryFields({
    query: appointmentQuery,
    whitelist: APPOINTMENT_WHITELIST,
  })

  appointmentQuery.notEqualTo("ascendSyncCompleted", false)
  appointmentQuery.include(["patient", "teamMembers"]) // Include patient and teamMembers object in each appoitnment
  if (location && location.value) {
    appointmentQuery.equalTo("location", new LocationV1({ id: location?.value }))
  }
  appointmentQuery.greaterThanOrEqualTo("start", start.format())
  appointmentQuery.lessThan("start", end.format())

  if (providers?.length) {
    appointmentQuery.containedIn(
      "provider",
      providers.map((p: any) => p?.value)
    )
  }
  if (status?.length) {
    appointmentQuery.containedIn(
      "status",
      status.map((s: any) => s?.value?.toUpperCase?.())
    )
  }

  const subscription: any = yield appointmentQuery.subscribe()

  dispatch(handleApptsSubscription(subscription))

  subscription?.on("close", () => {
    window.console.log("subscription closed ....")
  })
  subscription?.on("open", async () => {
    window.console.log("subscription opened")
  })
  subscription.on("create", async (appointment: any) => {
  })

  subscription?.on("update", async (appointment: any) => {
    const appt = appointment?.toJSON?.()
    const teamMembersQuery = new Parse.Query("_User")
    let teamMembers: any = null
    if (appt?.teamMembers?.length > 0) {
      teamMembersQuery.containedIn("objectId", appt?.teamMembers?.map?.((member: any) => member.objectId) || [])
      teamMembers = await teamMembersQuery.find()
    } else {
      teamMembers = []
    }
    dispatch(deleteAppointment({ ...appt, start: appt?.start?.iso ? appt?.start?.iso : appt?.start, end: appt?.end?.iso }))
    dispatch(addAppointment({ ...appt, start: appt?.start?.iso ? appt?.start?.iso : appt?.start, end: appt?.end?.iso, teamMembers: teamMembers?.map((member: any) => member?.toJSON?.()) }))
  })

  subscription?.on("delete", (appointment: any) => {
    window.console.log("Appointment Deleted: ", appointment, ", Appointment ID: ", appointment?.id)
    const appt = appointment?.toJSON?.()
    dispatch(deleteAppointment({ ...appt, start: appt?.start?.iso ? appt?.start?.iso : appt?.start, end: appt?.end?.iso }))
  })
  subscription?.on("enter", async (appointment: any) => {
    const appt = appointment?.toJSON?.()
    const teamMembersQuery = new Parse.Query("_User")
    let teamMembers: any = null
    if (appt?.teamMembers?.length > 0) {
      teamMembersQuery.containedIn("objectId", appt?.teamMembers?.map?.((member: any) => member.objectId) || [])
      teamMembers = await teamMembersQuery.find()
    } else {
      teamMembers = []
    }
    dispatch(addAppointment({ ...appt, start: appt?.start?.iso ? appt?.start?.iso : appt?.start, end: appt?.end?.iso, teamMembers: teamMembers?.map((member: any) => member?.toJSON?.()) }))
  })

  subscription?.on("leave", (appointment: any) => {
    const appt = appointment?.toJSON?.()
    dispatch(deleteAppointment({ ...appt, start: appt?.start?.iso ? appt?.start?.iso : appt?.start, end: appt?.end?.iso }))
  })

  try {
    let result = yield appointmentQuery.find()
    result = result.map((appointment: any): IAppointment => {
      const appt = appointment?.toJSON?.()
      return { ...appt, start: appt?.start?.iso, end: appt?.end?.iso }
    })

    yield put(setAppts(result))
    callback()
  } catch (error: any) {
    yield sagaErrorHandler(error)
    callback()
  }
}
function* fetchProvidersRequest({ payload }: any): Generator<any, any, unknown> {
  const { callback } = payload
  const response = new Parse.Query("ProviderV1").equalTo("active", true)

  try {
    const result = yield response.findAll()
    yield put(setProviders(result))
    callback()
  } catch (error: any) {
    yield sagaErrorHandler(error)
    callback()
  }
}
function* fetchOperatoriesRequest({ payload }: any): Generator<any, any, unknown> {
  const { location, callback } = payload
  const response = Parse.Object.extend("OperatoryV1")
  const query = new Parse.Query(response)

  /**
   * Exclude unnecessary fields from query.
   */
  excludeParseQueryFields({ query: query, whitelist: OPERATORY_WHITELIST })

  /**
   * Apply Filters
   */
  query.equalTo("location", location.value)
  query.equalTo("active", true)

  try {
    const result: any = yield query.find()

    if (!result) return

    let operatories: any = result?.map?.((operatory: any): Operatory => operatory?.toJSON?.())

    operatories = _.orderBy(operatories, "shortName", "asc").map((operatory: Operatory, index: number) => ({
      ...operatory,
      className: index % 2 ? "bg-appointment-green" : "bg-appointment-blue",
    }))

    operatories = operatories?.filter((opt: Operatory) => opt.objectId && opt.active)

    updateHeaderRow(operatories)
    updateTimeSlots()

    yield put(setOperatories(operatories))
    callback()
  } catch (error: any) {
    yield sagaErrorHandler(error)
    callback()
  }
}
export function* watchFetchAppts(): Generator<any, any, unknown> {
  yield takeLatest(types.FETCH_APPTS, fetchApptsRequest)
}
export function* watchFetchProviders(): Generator<any, any, unknown> {
  yield takeLatest(types.FETCH_PROVIDERS, fetchProvidersRequest)
}
export function* watchFetchOperatories(): Generator<any, any, unknown> {
  yield takeLatest(types.FETCH_OPERATORIES, fetchOperatoriesRequest)
}
export default function* ScheduleSaga(): Generator<any, any, unknown> {
  yield all([fork(watchFetchAppts), fork(watchFetchProviders), fork(watchFetchOperatories)])
}
