import { useEffect, useState } from "react"
import { TrashIcon, PencilIcon, EyeIcon, EyeOffIcon, ArrowRightIcon } from "@heroicons/react/outline"
import { BsToggleOff, BsToggleOn } from "react-icons/bs"
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts"
import { deletePolicy, getPolicyList, policyStatus } from "../../Store/Automation/actions"
import { useDispatch, useSelector } from "react-redux"
import TextField from "../Inputs/TextField"

const ManagePolicy = (props: any): JSX.Element => {
  const { closeModal, setPage, setSelectedPolicyId } = props
  const dispatch = useDispatch()
  let policy = useSelector((state: any) => state?.Automation?.policyList)
  const [policyDetails, setPolicyDetails] = useState<any>()
  useEffect(() => {
    dispatch(getPolicyList())
  }, [])
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28"]
  return (
    <>
      <div className="flex-1 flex flex-col-reverse md:flex-row h-full">
        <div className="flex flex-col automationHeight flex-1 w-full px-4 pt-4 pb-5 overflow-auto xs:py-8 md:w-1/2 md:px-8 lg:px-8 lg:pt-5 2xl:pr-8 2xl:pl-8 mb-8 border-r border-gray-300">
          <div className="mb-8 w-64 ml-auto">
            <TextField placeholder="Search" />
          </div>
          {policy.length > 0 ? (
            <>
              {policy?.map((obj: any, index: string) => {
                let data = [
                  { name: "Executed count", value: obj.executedCount },
                  { name: "Pending count", value: obj.pendingCount },
                  { name: "Failed count", value: obj.failedCount },
                ]
                return (
                  <div key={index} className="p-4 mb-5 relative" style={{ boxShadow: "rgb(0 0 0 / 10%) 1px 1px 8px 5px" }}>
                    <div className="flex">
                      <div>
                        <p>{obj.name}</p>
                        <div className="flex mt-2">
                          <p className="font-semibold">Status:</p>
                          <button type="button" onClick={() => dispatch(policyStatus({ policyId: obj.objectId, isActive: !obj.isActive }))}>
                            {obj.isActive ? (
                              <div className="flex">
                                <BsToggleOn className={`h-6 w-6 ml-2`} />
                                <p className="text-xs ml-2 mt-1 text-green-600 font-semibold">Enabled</p>
                              </div>
                            ) : (
                              <div className="flex">
                                <BsToggleOff className={`h-6 w-6 ml-2`} />
                                <p className="text-xs ml-2 mt-1 text-gray-600 font-semibold">Disabled</p>
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="ml-auto text-mobile-gray-600 flex">
                        <div className="mr-3 mt-4">
                          {policyDetails === obj.objectId ? (
                            <button type="button" onClick={() => setPolicyDetails("")}>
                              <EyeOffIcon className={`h-4 w-4 mr-2`} />
                            </button>
                          ) : (
                            <button type="button" onClick={() => setPolicyDetails(obj.objectId)}>
                              <EyeIcon className={`h-4 w-4 mr-2`} />
                            </button>
                          )}
                          <button
                            type="button"
                            onClick={() => {
                              setSelectedPolicyId(obj.objectId)
                              setPage(1)
                            }}
                          >
                            <PencilIcon className={`h-4 w-4`} />
                          </button>
                          <button type="button" onClick={() => dispatch(deletePolicy(obj.objectId))}>
                            <TrashIcon className={`h-4 w-4 ml-2`} />
                          </button>
                        </div>
                      </div>
                    </div>
                    {policyDetails === obj.objectId ? (
                      <div className="flex-1 flex flex-col-reverse md:flex-row h-full">
                        <div className="flex flex-col h-64 flex-1 w-full pt-4 pb-5 overflow-auto md:w-1/2 md:px-4 lg:px-4 lg:pt-5 2xl:pr-4 2xl:pl-4 border-r border-gray-300">
                          <ResponsiveContainer width="100%" height="100%">
                            <PieChart width={300} height={300}>
                              <Pie startAngle={180} endAngle={0} isAnimationActive={true} data={data} cx={150} cy={150} innerRadius={60} outerRadius={80} fill="#8884d8" paddingAngle={5} dataKey="value" label>
                                {data.map((entry, index) => (
                                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                              </Pie>
                              <Tooltip />
                            </PieChart>
                          </ResponsiveContainer>
                          <div className="flex" style={{ marginLeft: "11%" }}>
                            <p className="mr-3 text-gray-500 text-xs">
                              Executed:&nbsp;
                              <span style={{ color: COLORS[0] }}>
                                <b>{obj.executedCount}</b>
                              </span>
                            </p>
                            <p className="mr-3 text-gray-500 text-xs">
                              Pending:&nbsp;
                              <span style={{ color: COLORS[1] }}>
                                <b>{obj.pendingCount}</b>
                              </span>
                            </p>
                            <p className="text-gray-500 text-xs">
                              Failed:&nbsp;
                              <span style={{ color: COLORS[2] }}>
                                <b>{obj.failedCount}</b>
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col h-64 flex-1 w-full px-4 pt-0 pb-5 overflow-auto md:w-1/2 md:px-4 lg:px-4 lg:pt-0 2xl:pr-4 2xl:pl-4">
                          <p className="mb-3 text-sm font-semibold">History logs</p>
                          <ol className="relative border-l border-green-400 dark:border-green-700">
                            <li className="mb-3 ml-4">
                              <div className="absolute w-3 h-3 bg-green-400 rounded-full mt-1.5 -left-1.5 border border-white dark:border-green-900 dark:bg-green-700"></div>
                              <time className="mb-1 text-xs font-normal leading-none text-gray-500 dark:text-gray-500">February 2022</time>
                              <h3 className="text-sm font-semibold text-gray-900 dark:text-white">Application UI code in Tailwind CSS</h3>
                              <p className="mb-4 text-sm font-normal text-gray-500 dark:text-gray-400 flex">
                                Key: type <ArrowRightIcon className={`h-3 w-3 mr-2 ml-2 mt-1`} /> Key: objectId
                              </p>
                            </li>
                          </ol>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )
              })}
            </>
          ) : (
            <p className="font-medium text-lg text-gray-700 dark:text-white text-center mt-10">No policy avaliable</p>
          )}
        </div>
      </div>
      <div className="p-4 border-t border-gray-300 text-right">
        <button
          type="button"
          className="mr-2 inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
          onClick={() => closeModal(false)}
        >
          Close
        </button>
      </div>
    </>
  )
}
export default ManagePolicy
