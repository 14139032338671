import * as types from "./actionTypes"

export const getFeedback = (data: any) => {
  return {
    type: types.GET_FEEDBACK,
    payload: data,
  }
}
export const getFeedbackByProvider = (data: any) => {
  return {
    type: types.GET_FEEDBACK_BY_PROVIDER,
    payload: data,
  }
}

export const getAllFeedbackSuccess = (data: any) => {
  return {
    type: types.GET_FEEDBACK_LIST_SUCCESS || types.GET_FEEDBACK || types.GET_FEEDBACK_BY_PROVIDER,
    payload: data,
  }
}
