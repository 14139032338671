import { RegisterData } from "../../Types/PatientTypes"
import * as types from "./actionTypes"
import Patient from "./reducer"

export const registerPatient = (data: RegisterData) => {
  return {
    type: types.REGISTER_PATIENT,
    payload: data,
  }
}
export const updatePatientInfo = (data: any) => {
  return {
    type: types.UPDATE_PATIENT_INFO,
    payload: data,
  }
}
export const getPatientTypes = () => {
  return {
    type: types.GET_PATIENT_TYPES,
  }
}
export const getPatientTypesSuccess = (data: any) => {
  return {
    type: types.GET_PATIENT_TYPES_SUCCESS,
    payload: data,
  }
}
export const uploadAttachment = (data: any) => {
  return {
    type: types.UPLOAD_ATTACHMENT,
    payload: data,
  }
}
export const getFamilyMember = (data: any) => {
  return {
    type: types.GET_FAMILY_MEMBER,
    payload: data,
  }
}
export const getFamilyMemberSuccess = (data: any) => {
  return {
    type: types.GET_FAMILY_MEMBER_SUCCESS,
    payload: data,
  }
}
export const addNewFamilyMember = (data: any) => {
  return {
    type: types.ADD_NEW_FAMILY_MEMBER,
    payload: data,
  }
}
