import Parse from "parse"
import React, { UIEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table"
import { Box, Typography, TextField } from "@mui/material"
import type { ColumnFiltersState, SortingState } from "@tanstack/react-table"
import type { Virtualizer } from "@tanstack/react-virtual"
import { useInfiniteQuery } from "@tanstack/react-query"

//Date Picker Imports
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

type UserApiResponse = {
  data: Array<any>
  meta: {
    totalRowCount: number
  }
}

const columns: MRT_ColumnDef<any>[] = [
  {
    accessorFn: (row) => row?.patient?.firstName + " " + row?.patient?.lastName, //accessorFn used to join multiple data into a single cell
    id: "fullName", //id is still required when using accessorFn instead of accessorKey
    header: "Patient Name",
    enableSorting: false,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    enableClickToCopy: true,
    Cell: ({ cell, row }) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        {row.original.patient?.profileImage?.url ? (
          <img alt="img" height={5} src={row.original.patient.profileImage?.url} loading="lazy" style={{ height: "30px", borderRadius: "50%" }} />
        ) : (
          <div className="flex items-center justify-center rounded-full w-8 h-8 border p-3">{row?.original?.patient?.firstName?.[0]}</div>
        )}
        <Typography>
          {row?.original?.patient?.firstName} {row?.original?.patient?.lastName}
        </Typography>
      </Box>
    ),
  },
  {
    header: "Patient Phone",
    enableSorting: false,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    enableClickToCopy: false,
    Cell: ({ cell, row }) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <div className="white-space: pre">
          {row?.original?.patient?.phones?.map((ph: any) => (
            <Typography> {ph?.number} </Typography>
          ))}
        </div>
      </Box>
    ),
  },
  {
    accessorFn: (row) => row?.patient?.emailAddress, //accessorFn used to join multiple data into a single cell
    id: "patientEmail", //id is still required when using accessorFn instead of accessorKey
    header: "Patient Email",
    enableSorting: false,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    enableClickToCopy: true,
    size: 250,
    Cell: ({ cell, row }) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Typography> {row?.original?.patient?.emailAddress} </Typography>
      </Box>
    ),
  },
  {
    accessorKey: "subscriberName",
    header: "Subscriber Name",
    enableClickToCopy: true,
    size: 200,
  },
  {
    accessorFn: (row) => new Date(row.dateOfBirth.iso), //convert to Date for sorting and filtering
    id: "dateOfBirth",
    enableClickToCopy: true,
    header: "Date of Birth",
    filterFn: "lessThanOrEqualTo",
    sortingFn: "datetime",
    Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString('en-GB', { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' }), //render Date as a string
    Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
    //Custom Date Picker Filter from @mui/x-date-pickers
    Filter: ({ column }) => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          onChange={(newValue) => {
            column.setFilterValue(newValue)
          }}
          renderInput={(params) => <TextField {...params} helperText={"Filter Mode: Lesss Than"} sx={{ minWidth: "120px" }} variant="standard" />}
          value={column.getFilterValue()}
        />
      </LocalizationProvider>
    ),
  },
  {
    accessorKey: "subscriberId",
    header: "Subscriber Id",
    enableClickToCopy: true,
    size: 200,
  },
  {
    accessorKey: "subscriberSsn",
    header: "Subscriber SSN",
    enableClickToCopy: true,
    size: 200,
  },
  {
    accessorKey: "membershipId",
    header: "Membership Id",
    enableClickToCopy: true,
    size: 200,
  },
  {
    accessorKey: "insuranceProvider",
    header: "Insurance Provider",
    enableClickToCopy: true,
    size: 200,
  },
  {
    accessorKey: "state",
    header: "State",
    enableClickToCopy: true,
  },
  {
    accessorFn: (row) => new Date(row.createdAt), //convert to Date for sorting and filtering
    id: "createdAt",
    enableClickToCopy: true,
    header: "Created At",
    filterFn: "lessThanOrEqualTo",
    sortingFn: "datetime",
    Cell: ({ cell }) => cell.getValue<Date>()?.toString(), //render Date as a string
    // Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
    //Custom Date Picker Filter from @mui/x-date-pickers
    Filter: ({ column }) => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          onChange={(newValue) => {
            column.setFilterValue(newValue)
          }}
          renderInput={(params) => <TextField {...params} helperText={"Filter Mode: Lesss Than"} sx={{ minWidth: "120px" }} variant="standard" />}
          value={column.getFilterValue()}
        />
      </LocalizationProvider>
    ),
  },
]

const fetchSize = 100

export default function Insurance(): JSX.Element {
  const pageSize = 100
  const [loading, setLoading] = useState<any>(false)
  const [skip, setSkip] = useState(0)

  const tableContainerRef = useRef<HTMLDivElement>(null) //we can get access to the underlying TableContainer element and react to its scroll events
  const rowVirtualizerInstanceRef = useRef<Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null) //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState<string>()
  const [sorting, setSorting] = useState<SortingState>([])

  const { data, fetchNextPage, isError, isFetching, isLoading } = useInfiniteQuery<UserApiResponse>(
    ["table-data", columnFilters, globalFilter, sorting],
    async ({ pageParam = 0 }) => {
      const insuranceQuery = new Parse.Query("Insurance")
      insuranceQuery.include("patient")
      insuranceQuery.limit(fetchSize)
      insuranceQuery.skip(pageParam * fetchSize)
      insuranceQuery.withCount()

      if (sorting.length) {
        sorting.forEach((sort) => {
          if (sort.desc) {
            insuranceQuery.descending(sort.id)
          } else {
            insuranceQuery.ascending(sort.id)
          }
        })
      } else {
        insuranceQuery.descending("createdAt")
      }
      if (columnFilters.length) {
        columnFilters.forEach((filter: any) => {
          insuranceQuery.matches(filter.id, filter.value, "i")
        })
      }
      if (globalFilter) {
        insuranceQuery.matches("subscriberName", globalFilter as any, "i")
      }
      setSkip(pageParam + 1)
      const insurance: any = await insuranceQuery.find()
      const data = insurance.results.map((insurance: Parse.Object) => insurance.toJSON())
      const response: any = {
        data: insurance.results.map((insurance: Parse.Object) => insurance.toJSON()),
        meta: {
          totalRowCount: insurance.count,
        },
      }
      return response
    },
    {
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )

  const flatData = useMemo(() => data?.pages.flatMap((page) => page.data) ?? [], [data])

  const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0
  const totalFetched = flatData.length

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement
        //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
        if (scrollHeight - scrollTop - clientHeight < 400 && !isFetching && totalFetched < totalDBRowCount) {
          fetchNextPage()
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  )

  //scroll to top of table when sorting or filters change
  useEffect(() => {
    if (flatData.length && rowVirtualizerInstanceRef?.current) {
      rowVirtualizerInstanceRef?.current?.scrollToIndex(0)
    }
  }, [sorting, columnFilters, globalFilter])

  //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current)
  }, [fetchMoreOnBottomReached])

  return (
    <>
      <p className="text-2xl font-semibold mb-8">Insurance</p>
      <MaterialReactTable
        columns={columns}
        data={flatData}
        enablePagination={false}
        enableRowNumbers
        enableRowVirtualization //optional, but recommended if it is likely going to be more than 100 rows
        manualFiltering
        manualSorting
        muiTableContainerProps={{
          ref: tableContainerRef, //get access to the table container element
          sx: { maxHeight: "600px" }, //give the table a max height
          onScroll: (
            event: UIEvent<HTMLDivElement> //add an event listener to the table container element
          ) => fetchMoreOnBottomReached(event.target as HTMLDivElement),
        }}
        muiToolbarAlertBannerProps={
          isError
            ? {
              color: "error",
              children: "Error loading data",
            }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onSortingChange={setSorting}
        renderBottomToolbarCustomActions={() => (
          <Typography>
            Fetched {totalFetched} of {totalDBRowCount} total rows.
          </Typography>
        )}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //get access to the virtualizer instance
        rowVirtualizerProps={{ overscan: 10 }}
      />
    </>
  )
}
