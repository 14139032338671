import { formatDate } from "../../utils/helpers"
import { BsFillEmojiNeutralFill, BsFillEmojiSmileFill, BsFillEmojiFrownFill, BsFillQuestionCircleFill } from "react-icons/bs"
// import { useMemo } from "react"
import Avatar from "../../components/DataDisplay/Avatar"

export const ReviewCard = ({ review }: any) => {
  return (
    <div className="w-full mb-6">
      <div className="flex">
        <div className="pr-3">
          {review?.rating > 0 ? (
            review?.rating === 7 || review?.rating === 8 ? (
              <BsFillEmojiNeutralFill size={28} className="mr-1 fill-yellow-500" />
            ) : review?.rating === 9 || review?.rating === 10 ? (
              <BsFillEmojiSmileFill size={28} className="mr-1 fill-green-500" />
            ) : (
              <BsFillEmojiFrownFill size={28} className="mr-1 fill-red-500" />
            )
          ) : (
            <BsFillQuestionCircleFill size={28} className="mr-1 fill-gray-500" />
          )}
        </div>
        <div className="w-full text-md">
          <p className="text-gray-700">{review?.message ?? ""}</p>
          <div className="grid grid-cols-10 gap-2 md:gap-4 text-gray-500 text-sm mt-2">
            <p className="col-span-2 flex items-center">{formatDate(review?.createdAt, "MM/DD/YYYY HH:mm a")}</p>
            <div className="col-span-2 flex items-center">
              By:
              <div className="mx-2">
                <Avatar image={review?.patient?.profileImage ? review?.patient?.profileImage : null} firstName={review?.patient?.firstName ?? ""} className="font-bold text-xl text-gray-800 bg-transparent border border-gray-800" size="user" />
              </div>
              <span className="text-md text-gray-700 font-medium">{`${review?.patient?.firstName ?? ""} ${review?.patient?.lastName ?? ""}`} </span>{" "}
            </div>
            <p className="col-span-2 flex items-center">
              Location: <span className="text-md text-gray-700 font-medium ml-2">{review?.location?.name?.replace("Total Health Dental Care", "") ?? "N/A"}</span>{" "}
            </p>
            <p className="lg:col-span-3 col-span-2 flex items-center">
              Provider: <span className="text-md text-gray-700 font-medium ml-2">{`${review?.provider?.firstName ?? ""} ${review?.provider?.lastName ?? ""}`} </span>{" "}
            </p>
            <p className="col-span-2 lg:col-span-1 text-right flex items-center justify-end">
              Score:
              <span className="bg-blue-100 text-blue-800 text-xs font-semibold px-1 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-2"> {`${!review?.rating ? "N/A" : review?.rating}`} </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
