import React from "react"

const Tooltip = ({ title, className }: { title: string; className?: string }): JSX.Element => {
  return (
    <div className={`inline-block pointer-events-none opacity-0 group-hover:opacity-100 transition absolute z-10 py-1.5 px-3 text-xs font-medium text-white bg-gray-900 rounded-lg shadow-sm dark:bg-gray-700 ${className}`}>
      {title}
      <span className="absolute bottom-0 left-1/2 -translate-x-1/2 w-2 h-2 translate-y-1/2 transform rotate-45 bg-gray-900 dark:bg-gray-700"></span>
    </div>
  )
}

export default Tooltip
