import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

export default function Home(): JSX.Element {
  const navigate = useNavigate()
  useEffect(() => {
    navigate("/schedule")
  })
  return <></>
}
