import Highcharts from "highcharts/highcharts.js"
import highchartsMore from "highcharts/highcharts-more.js"
import solidGauge from "highcharts/modules/solid-gauge.js"
import HighchartsReact from "highcharts-react-official"

highchartsMore(Highcharts)
solidGauge(Highcharts)

export const Chart = (props: any) => {
  const { value } = props
  const GaugeChart = {
    chart: {
      type: "gauge",
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      height: 350,
      width: 350,
      plotBorderWidth: 0,
      plotShadow: false,
    },

    title: {
      text: "Net Promoter Score",
      style: {
        fontSize: "22px",
        fontStyle: "bold",
      },
    },

    pane: {
      startAngle: -150,
      endAngle: 149.9,
      background: null,
      center: ["50%", "50%"],
      size: "80%",
    },

    credits: {
      enabled: false,
    },

    yAxis: {
      min: -100,
      max: 100,
      tickPixelInterval: 200,
      tickPosition: "inside",
      tickColor: "#FFFFFF",
      tickLength: 38,
      tickWidth: 2,
      minorTickInterval: null,
      labels: {
        distance: 20,
        style: {
          fontSize: "14px",
        },
      },
      plotBands: [
        {
          from: 0,
          to: -100,
          color: "#cf2f2f", // red
          thickness: 38,
        },
        {
          from: 0,
          to: 100,
          color: "#429a2b", // green
          thickness: 38,
        },
      ],
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: true,
        },
        linecap: "round",
        stickyTracking: false,
        rounded: false,
      },
    },

    series: [
      {
        name: "NPS",
        data: [value],
        dataLabels: {
          format: "{y} NPS",
          borderWidth: 0,
          color: (Highcharts.defaultOptions.title && Highcharts.defaultOptions.title.style && Highcharts.defaultOptions.title.style.color) || "#333333",
          style: {
            fontSize: "20px",
          },
        },
        dial: {
          radius: "64%",
          backgroundColor: "#b8b8b8",
          baseWidth: 12,
          topWidth: 0,
          baseLength: "0%",
          rearLength: "0%",
        },
        pivot: {
          backgroundColor: "#b8b8b8",
          radius: 7,
        },
      },
    ],
  }

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={GaugeChart} />
    </div>
  )
}
