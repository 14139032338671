import Parse from "parse"
import { useState, useEffect } from "react"
import { PlusIcon, TrashIcon, XIcon } from "@heroicons/react/outline"
import Select from "react-select"
import Icon from "../DataDisplay/Icon"
import TextField from "../Inputs/TextField"
import Textarea from "../Inputs/Textarea"
import { toast } from "react-toastify"

const ActionSection = (props: any): JSX.Element => {
  const { sourceValue, actionValues, setActionValues, singlePolicy } = props
  const [actions, setActions] = useState<any>(singlePolicy?.actions ? singlePolicy?.actions?.map((obj: any) => obj) : [])
  const [ccField, setCcField] = useState<any>(actionValues[0] ? actionValues?.map((obj: any) => obj?.params.map((obj1: any) => (obj?.fieldType && obj?.value.length > 0 ? true : false))) : false)

  useEffect(() => {
    getActions()
  }, [sourceValue])

  const getActions = async () => {
    const query = new Parse.Query("AutomationActions")
    const getActions = await query.findAll()
    setActions(getActions?.map((obj: any) => obj.toJSON()))
  }
  useEffect(() => {
    setActions(singlePolicy?.actions ? singlePolicy?.actions?.map((obj: any) => obj) : [])
  }, [singlePolicy])

  let handleChange = async (i: any, e: any, actionValuesIndex?: any, object?: any) => {
    let newcriteriaValues = [...actionValues]
    if (e.name === "action") {
      let actionData = {
        value: e.value,
        action: e.action,
        label: e.label,
      }
      newcriteriaValues[i].actionName = actionData
      const query = new Parse.Query("AutomationActions")
      const actionParams = await query.get(newcriteriaValues[i].actionName.value)
      let result = await actionParams.relation("params").query().ascending("priority").find()
      newcriteriaValues[i].params = result?.map((obj: any) => {
        return {
          objectId: obj.id,
          name: obj?.attributes?.name,
          priority: obj?.attributes?.priority,
          placeholder: obj?.attributes?.label,
          fieldType: obj?.attributes?.fieldType,
          value: obj?.attributes?.fieldType === "cc" ? [] : "",
        }
      })
    } else if (object.fieldType === "cc") {
      let emailSyntex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value)
      if (!emailSyntex) return toast.error("Please add valid email")
      newcriteriaValues[i].params[actionValuesIndex].value = [...object.value, e.target.value]
      JSON.stringify(newcriteriaValues[i].params[actionValuesIndex].value)
      e.target.value = ""
    } else {
      newcriteriaValues[i].params[actionValuesIndex].value = e.target.value
    }
    newcriteriaValues[i].priority = i
    setActionValues(newcriteriaValues)
  }

  let addNewAction = () => {
    setActionValues([...actionValues, { actionName: "", params: [] }])
  }

  let removeAction = (i: any) => {
    let newcriteriaValues = [...actionValues]
    newcriteriaValues.splice(i, 1)
    setActionValues(newcriteriaValues)
  }

  const removeTags = (i: any, actionValuesIndex?: any, tagValueIndex?: any) => {
    let newcriteriaValues = [...actionValues]
    newcriteriaValues[i].params[actionValuesIndex].value.splice(tagValueIndex, 1)
    setActionValues(newcriteriaValues)
  }

  let injectionMessage = actionValues.filter((obj: any) => obj?.actionName?.action === "sendEmail" || obj?.actionName?.action === "sendPhoneMessage" || obj?.actionName?.action === "recreation")

  return (
    <div className="flex flex-col automationHeight flex-1 w-full px-4 pt-4 pb-5 overflow-auto md:w-1/2 md:px-8 lg:px-8 lg:pt-5 2xl:pr-8 2xl:pl-8 mb-8 border-r border-gray-300">
      {injectionMessage.length > 0 ? (
        <div className="mb-3">
          {sourceValue?.name === "AppointmentV1" ? (
            <>
              <p className="font-medium text-sm text-gray-700 dark:text-white">
                - For appointment provider
                <b className="ml-2">{`{appointment.provider}`}</b>
              </p>
              <p className="font-medium text-sm text-gray-700 dark:text-white">
                - For appointment recall link
                <b className="ml-2">{`{recallLink}`}</b>
              </p>
              <p className="font-medium text-sm text-gray-700 dark:text-white">
                - For appointment reactivation link
                <b className="ml-2">{`{reactivationLink}`}</b>
              </p>
            </>
          ) : null}
          <p className="font-medium text-sm text-gray-700 dark:text-white">
            - For patients name
            <b className="ml-2">{`{patient.name}`}</b>
          </p>
          <p className="font-medium text-sm text-gray-700 dark:text-white">
            - For patients name
            <b className="ml-2">{`{patient.name}`}</b>
          </p>
          <p className="font-medium text-sm text-gray-700 dark:text-white">
            - For patients home office
            <b className="ml-2">{`{patient.office}`}</b>
          </p>
        </div>
      ) : null}
      {actionValues?.map((action: any, index: any) => {
        let actionIndex = index
        let selectedKey = actionValues?.map((obj: any) => obj?.actionName?.action)
        return (
          <div key={actionIndex}>
            <div className="p-4 mb-5" style={{ boxShadow: "rgb(0 0 0 / 10%) 1px 1px 8px 5px" }}>
              <div className="">
                <Select
                  placeholder="Select action"
                  value={action?.actionName || ""}
                  options={actions
                    ?.filter((key: any) => !selectedKey.includes(key.name))
                    .map((action: any, index: any) => {
                      return {
                        value: action?.objectId,
                        label: action?.label,
                        action: action?.name,
                        params: action,
                        index: index,
                        name: "action",
                      }
                    })}
                  onChange={(e) => {
                    handleChange(actionIndex, e)
                  }}
                />
              </div>
              {action?.params?.length > 0 ? (
                <div className="mt-5">
                  {action?.actionName?.action === "sendEmail" ? (
                    <div className="text-right">
                      <span onClick={() => setCcField(!ccField)} className="cursor-pointer border-b-2">
                        Cc Bcc
                      </span>
                    </div>
                  ) : null}
                  {action?.params?.map((obj: any, index: any) => {
                    let actionValuesIndex = index
                    return (
                      <div key={obj?.objectId}>
                        {obj.fieldType === "textfield" ? (
                          <>
                            <p className="mb-1 capitalize">{obj?.placeholder}</p>
                            <TextField type={obj.type} className="mb-3" name={obj.name} value={obj.value || ""} placeholder={obj.placeholder} onChange={(e) => handleChange(actionIndex, e, actionValuesIndex, obj)} />
                          </>
                        ) : obj.fieldType === "cc" ? (
                          <>
                            {ccField ? (
                              <>
                                <p className="mb-1 capitalize">{obj?.placeholder}</p>
                                <div className="tags-input mb-1">
                                  <ul id="tags">
                                    {obj.value?.map((tag: any, index: any) => {
                                      let tagValueIndex = index
                                      return (
                                        <li key={index} className="tag">
                                          <span className="tag-title">{tag}</span>
                                          <span className="tag-close-icon" onClick={() => removeTags(actionIndex, actionValuesIndex, tagValueIndex)}>
                                            <Icon icon={XIcon} fontSize="text-sm" />
                                          </span>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                  <input type="text" onKeyUp={(e) => (e.key === "Enter" ? handleChange(actionIndex, e, actionValuesIndex, obj) : null)} placeholder="Press enter to add tags" />
                                </div>
                                <p className="text-xs mb-3">Press enter after complete email</p>
                              </>
                            ) : null}
                          </>
                        ) : (
                          <>
                            <p className="mb-1 capitalize">{obj?.placeholder}</p>
                            <Textarea className="mb-3" name={obj.name} value={obj.value || ""} placeholder={obj.placeholder} rows={4} onChange={(e) => handleChange(actionIndex, e, actionValuesIndex, obj)} />
                          </>
                        )}
                      </div>
                    )
                  })}
                  {actionValues.length > 1 ? (
                    <button type="button" onClick={() => removeAction(index)} className="w-full rounded-md border border-transparent bg-red-100 px-4 py-1 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2">
                      <Icon icon={TrashIcon} fontSize="text-lg" />
                    </button>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        )
      })}

      {actionValues?.map((obj: any) => obj.actionName.value).length != actions.length ? (
        <button type="button" onClick={() => addNewAction()} className="w-32 font-medium text-sm text-gray-700 dark:text-white capitalize">
          <Icon icon={PlusIcon} fontSize="text-sm" classNames="mt-1" /> Add condition
        </button>
      ) : null}
    </div>
  )
}
export default ActionSection
