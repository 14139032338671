import Parse from "parse"
import { useEffect, useState } from "react"
import { PlusIcon, TrashIcon } from "@heroicons/react/outline"
import Select from "react-select"
import Icon from "../DataDisplay/Icon"
import { getSourceKeys, getSources } from "../../Store/Automation/actions"
import { useDispatch, useSelector } from "react-redux"
import { dataTypeFilters, dataTypeFiltersProps, dropdownSelectValues, dropdownSelectValuesProps } from "../../constants/constants"
import TextField from "../Inputs/TextField"
import { toast } from "react-toastify"

const CriteriaSection = (props: any): JSX.Element => {
  const dispatch = useDispatch()
  const { sourceValue, setSourceValue, criteriaValues, setCriteriaValues } = props

  let sources = useSelector((state: any) => state?.Automation?.sources)
  let sourceKey = useSelector((state: any) => state?.Automation?.sourceKeys)

  useEffect(() => {
    dispatch(getSources())
  }, [])

  useEffect(() => {
    if (sourceValue) dispatch(getSourceKeys(sourceValue?.name))
  }, [sourceValue])

  let handleChange = async (i: any, e: any) => {
    let newcriteriaValues = [...criteriaValues]
    if (newcriteriaValues[i][e]) {
      newcriteriaValues[i][e.target.name] = e.target.value
    } else {
      if (e.name === "operator") {
        newcriteriaValues[i].operator = e
        newcriteriaValues[i].value = ""
      } else if (e.name === "key") {
        newcriteriaValues[i].key = e
        newcriteriaValues[i].value = ""
        // if (newcriteriaValues[i].key.value === "gender" || newcriteriaValues[i].key.value === "status" || newcriteriaValues[i].key.value === "patientTypes") {
        //   try {
        //     let response = await Parse.Cloud.run("getDistinctValues", {
        //       table: sourceValue?.name,
        //       column: "STATUS",
        //     })
        //     setSelectDropdownOptions(response.map((obj: any) => obj.toJSON()))
        //   } catch (error: any) {
        //     toast.error(JSON.stringify(error.message))
        //   }
        // }
      } else if (e.name === "value") {
        newcriteriaValues[i].value = e
      } else {
        newcriteriaValues[i][e.target.name] = e.target.value
      }
    }
    newcriteriaValues[i].priority = i
    setCriteriaValues(newcriteriaValues)
  }

  let addFormFields = () => {
    setCriteriaValues([...criteriaValues, { key: "", operator: "", value: "", priority: "" }])
  }

  let removeFormFields = (i: any) => {
    let newcriteriaValues = [...criteriaValues]
    newcriteriaValues.splice(i, 1)
    setCriteriaValues(newcriteriaValues)
  }

  return (
    <div className="flex flex-col automationHeight flex-1 w-full px-4 pt-4 pb-5 overflow-auto xs:py-8 md:w-1/2 md:px-8 lg:px-8 lg:pt-5 2xl:pr-8 2xl:pl-8 mb-8 border-r border-gray-300">
      <div className="p-4 mb-5" style={{ boxShadow: "rgb(0 0 0 / 10%) 1px 1px 8px 5px" }}>
        <div className="">
          <Select
            placeholder="Source"
            name="Source"
            options={sources?.map((source: any) => {
              return {
                value: source?.objectId,
                label: source?.label,
                name: source?.name,
              }
            })}
            value={sourceValue}
            onChange={(e) => {
              setSourceValue(e)
              setCriteriaValues([{ key: "", operator: "", value: "", priority: "" }])
            }}
          />
        </div>
      </div>
      {criteriaValues?.map((element: any, index: string) => {
        let selectedKey = criteriaValues?.map((obj: any) => obj.key.value)
        let type = element.key.type
        let value = element.key.value
        return (
          <div key={index} className="p-4 mb-5" style={{ boxShadow: "rgb(0 0 0 / 10%) 1px 1px 8px 5px" }}>
            <div className="mb-2">
              <Select
                placeholder="key"
                value={element.key || ""}
                options={sourceKey
                  ?.filter((key: any) => !selectedKey.includes(key.label))
                  .map((source: any) => {
                    const text = source?.label
                    const result = text.replace(/([A-Z])/g, " $1")
                    const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
                    return {
                      value: source?.label,
                      label: finalResult,
                      type: source?.value?.type,
                      name: "key",
                    }
                  })}
                onChange={(e: any) => {
                  handleChange(index, e)
                }}
              />
            </div>
            <div className="mb-2 ">
              {type ? (
                <Select
                  name="operator"
                  placeholder="operator"
                  value={element.operator || ""}
                  options={dataTypeFilters[type as keyof dataTypeFiltersProps].map((obj: any) => {
                    return {
                      value: obj?.value,
                      label: obj?.label,
                      getInput: obj?.getInput,
                      name: "operator",
                    }
                  })}
                  onChange={(e: any) => {
                    handleChange(index, e)
                  }}
                />
              ) : null}
            </div>
            {element.operator.getInput && element.key.type === "Date" ? (
              <div className="mb-2">
                <TextField
                  placeholder="YYYY-MM-DD"
                  type="date"
                  name="value"
                  value={element.value || ""}
                  onChange={(e) => {
                    handleChange(index, e)
                  }}
                />
              </div>
            ) : element.operator.getInput && element.key.type === "Boolean" ? (
              <>
                <div className="flex mb-3 mt-3">
                  <div className="flex items-center mr-3">
                    <input name="value" type="radio" value="True" onChange={(e) => handleChange(index, e)} checked={element.value === "True"} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" />
                    <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">True</label>
                  </div>
                  <div className="flex items-center">
                    <input name="value" value="False" onChange={(e) => handleChange(index, e)} checked={element.value === "False"} type="radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" />
                    <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">False</label>
                  </div>
                </div>
              </>
            ) : (element.operator.getInput && element.key.value === "status") || (element.operator.getInput && element.key.value === "gender") || (element.operator.getInput && element.key.value === "patientTypes") || (element.operator.getInput && element.key.value === "patientStatus") ? (
              <div className="mb-2">
                <Select
                  name="value"
                  value={element.value ?? ""}
                  options={dropdownSelectValues[value as keyof dropdownSelectValuesProps].map((obj: any) => {
                    return {
                      value: obj?.value,
                      label: obj?.label,
                      name: "value",
                    }
                  })}
                  onChange={(e: any) => {
                    handleChange(index, e)
                  }}
                />
              </div>
            ) : element.operator.getInput ? (
              <div className="mb-2">
                <TextField
                  type="text"
                  name="value"
                  value={element.value || ""}
                  onChange={(e) => {
                    handleChange(index, e)
                  }}
                />
              </div>
            ) : null}
            {selectedKey?.length != sourceKey?.length ? (
              <button type="button" onClick={() => removeFormFields(index)} className="w-full rounded-md border border-transparent bg-red-100 px-4 py-1 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2">
                <Icon icon={TrashIcon} fontSize="text-lg" />
              </button>
            ) : null}
          </div>
        )
      })}
      {sourceValue?.label ? (
        <button type="button" onClick={() => addFormFields()} className="w-32 font-medium text-sm text-gray-700 dark:text-white capitalize">
          <Icon icon={PlusIcon} fontSize="text-sm" classNames="mt-1" /> Add condition
        </button>
      ) : null}
    </div>
  )
}
export default CriteriaSection
