import { useEffect, useState } from "react"
import EllipsisProgress from "../../Feedback/Progress/Ellipsis"
import * as Parse from "parse"

import { SearchIcon, XIcon } from "@heroicons/react/outline"
import Icon from "../../DataDisplay/Icon"

export default function ProviderSelect({ onChange, placeholder, borderLess, className, iconClassName, tableContainerClassName, avatarClassName, rowClickAble = true, provider }: any): JSX.Element {
  const [filteredOptions, setFilteredOptions] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const [query, setQuery] = useState("")
  const [selectedPerson, setSelectedPerson] = useState<any>()
  const [shouldSearch, setShouldSearch] = useState(true)

  const fetchAutocompletePredictions = async (value?: any) => {
    const data = value.split(" ")

    setLoading(true)

    const firstNameQuery = new Parse.Query("ProviderV1")
    const lastNameQuery = new Parse.Query("ProviderV1")
    if (data.length <= 1) {
      firstNameQuery.startsWith("firstName", value || " ")
      lastNameQuery.startsWith("lastName", value || " ")
    } else if (data.length > 1) {
      let fn = data[0]
      let ln = data[1]

      firstNameQuery.startsWith("firstName", fn || " ")
      lastNameQuery.fullText("lastName", ln || " ")
    }

    const mainQuery = Parse.Query.and(Parse.Query.or(firstNameQuery, lastNameQuery))
    mainQuery
      .findAll()
      .then(function (results) {})
      .catch(function (error) {})

    const result = await mainQuery.findAll()
    const providersArray = result
      ?.map((provider: any): any => {
        return {
          id: provider?.id,
          firstName: provider?.attributes?.firstName,
          lastName: provider?.attributes?.lastName,
          address1: provider?.attributes?.address1,
          profileImage: null,
        }
      })
      .filter((provider: any) => provider.firstName || provider.lastName)
    setFilteredOptions(providersArray)
    setLoading(false)
  }

  const onClickAutocompletePrediction = (option: any) => {
    setShouldSearch(false)
    setSelectedPerson(option)
    onChange && onChange(option)
    setQuery(`${option.firstName} ${option.lastName}`)
    setFilteredOptions([])
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event?.target?.value)
    setShouldSearch(true)
    if (!event?.target?.value) {
      setSelectedPerson(undefined)
    }
  }

  useEffect(() => {
    if (query && shouldSearch) {
      const delay = setTimeout(() => fetchAutocompletePredictions(query), 2000)
      return () => clearTimeout(delay)
    }
  }, [query, shouldSearch])

  useEffect(() => {
    if (!provider) {
      setQuery("")
      onChange && onChange(null)
      setSelectedPerson(undefined)
      setFilteredOptions([])
    }
  }, [provider])

  return (
    <>
      <div className="relative">
        <div className={`relative flex items-center`}>
          <input
            value={query}
            onChange={handleChange}
            className={`w-full rounded-md ${selectedPerson ? "pl-10" : "pl-10"} py-2 pr-10  dark:text-white ${
              !borderLess ? "border border-gray-300 bg-white dark:bg-black-900 dark:border-black-900 shadow-sm focus:outline-none focus:ring-2 dark:focus:ring-offset-black-700 focus:ring-offset-2 focus:ring-gray-500" : "focus:outline-none"
            }  ${className} sm:text-sm`}
            placeholder={placeholder ? placeholder : `Search provider`}
          />
          {selectedPerson && (
            <>
              {selectedPerson?.profileImage ? (
                <div
                  className={`w-6 h-6 rounded-full flex absolute top-1/2 transform -translate-y-1/2 left-2.5 bg-cover ${avatarClassName}`}
                  style={{
                    backgroundImage: `url(${selectedPerson?.profileImage})`,
                  }}
                ></div>
              ) : (
                <div className={`w-6 h-6 rounded-full flex items-center justify-center absolute capitalize left-2.5 bg-black-700 dark:bg-black-700 text-white ${avatarClassName}`}>{selectedPerson.firstName[0] && selectedPerson.lastName[0]}</div>
              )}
            </>
          )}
          {!selectedPerson ? (
            <div className="pointer-events-none absolute flex justify-center left-3 text-gray-500 text-lg">
              <Icon icon={SearchIcon}></Icon>
            </div>
          ) : null}
          {loading && (
            <div className="absolute top-1/2 transform -translate-y-1/2 right-6">
              <div className="h-full w-full flex items-center justify-center">
                <div className="spinner-wrapper">
                  <EllipsisProgress color="gray-600" />
                </div>
              </div>
            </div>
          )}
          {query.length > 0 || filteredOptions.length > 0 ? (
            <button
              onClick={() => {
                setQuery("")
                onChange && onChange(null)
                setSelectedPerson(undefined)
                setFilteredOptions([])
              }}
              className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
            >
              <XIcon className={`h-5 w-5 ${iconClassName} text-gray-400 dark:text-gray-200`} aria-hidden="true" />
            </button>
          ) : null}
        </div>
        <div className={tableContainerClassName ? tableContainerClassName : `w-full mt-2 sm:w-full sm:max-w-full z-30 absolute`}>
          {filteredOptions.length > 0 ? (
            <div className="overflow-auto  border max-h-96 shadow rounded-lg">
              <table className="min-w-full divide-y divide-gray-300 dark:divide-black-700">
                <thead className="bg-gray-50 dark:bg-black-900">
                  <tr>
                    <th scope="col" className="whitespace-nowrap py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-6">
                      Provider
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-black-700 bg-white  dark:bg-black-900">
                  {filteredOptions
                    .filter((provider) => provider.providerStatus !== "INACTIVE")
                    .map((option) => (
                      <tr
                        key={option.id}
                        onClick={() => (rowClickAble ? onClickAutocompletePrediction(option) : null)}
                        className={`${selectedPerson && selectedPerson.id === option.id ? "bg-blue-200 dark:bg-black-800 " : ""}  ${rowClickAble ? " cursor-pointer hover:bg-blue-50 dark:hover:bg-black-800 " : ""}  `}
                      >
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 dark:text-gray-200 sm:pl-6 flex items-center">
                          <>
                            {option?.profileImage ? (
                              <div
                                className="w-6 h-6 rounded-full bg-cover"
                                style={{
                                  backgroundImage: `url(${option?.profileImage})`,
                                }}
                              ></div>
                            ) : (
                              <div className="w-6 h-6 rounded-full flex items-center justify-center bg-gray-800 dark:bg-black-700 text-white">{option.firstName[0]}</div>
                            )}
                          </>
                          <span className="ml-2">
                            {option.firstName} {option.lastName}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}
