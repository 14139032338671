import { useEffect, useRef } from "react"
import ScheduleTemplate from "./schedule-template"

import Aside from "./aside"

import CreateAppointment from "./modals/CreateAppointment"
import { useSelector, useDispatch } from "react-redux"
import { useState } from "react"
import { getPatientTypes } from "../../Store/Patient/actions"
import useLocalStorage from "use-local-storage"
import { MultiValue } from "react-select"
import { IAppointment, ICellData } from "./schedule-template/Template.interfaces"
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar"
import { IOption as IMultiSelectOption } from "../../components/Inputs/MultiSelect/MultiSelect.interfaces"
import { IOption } from "../../components/Inputs/Select/Select.interfaces"
import { setLocation } from "../../Store/Appointment/actions"
import { schedulerPageLocationAndDate } from "../../Store/Schedule/actions"

import { fetchAppts, fetchOperatories, fetchProviders, updateLocalAppointment, updatePatientInAppts } from "../../Store/Schedule/actions"

const Schedule = (): JSX.Element => {
  const dispatch = useDispatch()
  const scheduleState = useSelector((state: any) => state?.Schedule)

  const [selectedAppt, setSelectedAppt] = useState<IAppointment | null>(null)
  const [selectedCell, setSelectedCell] = useState<ICellData | null>(null)

  const loadingProgressRef = useRef<LoadingBarRef | null>(null)

  const location = useSelector((state: any) => state?.Appointment?.location)

  const [isCreatingAppt, setIsCreatingAppt] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Filters
  const [showApptInfo, setShowApptInfo] = useLocalStorage("showApptInfo", true)
  const [providers, setProviders] = useLocalStorage<MultiValue<IMultiSelectOption>>("providers-filter", [])
  const [apptStatus, setApptStatus] = useLocalStorage<MultiValue<IMultiSelectOption>>("appt-status-filter", [])

  useEffect(() => {
    setShowApptInfo(true)
  }, [])

  const date = useSelector((state: any) => state?.Schedule.date)

  const updateAppointmentClosure = (appointment: IAppointment): void => {
    dispatch(updateLocalAppointment(appointment))
  }
  const updatePatientInApptsClosure = (patient: IAppointment["patient"]): void => {
    if (patient) dispatch(updatePatientInAppts(patient))
  }

  const [isFetchedAppointments, setIsFetchedAppointments] = useState(false)
  const [isFetchedProviders, setIsFetchedProviders] = useState(false)
  const [isFetchedOperatories, setIsFetchedOperatories] = useState(false)

  useEffect(() => {
    setIsFetchedAppointments(false)
    dispatch(
      fetchAppts({
        location,
        status: apptStatus,
        providers,
        date,
        dispatch,
        loadingProgressRef,
        callback: () => {
          setIsFetchedAppointments(true)
        },
      })
    )
  }, [location, apptStatus, providers, date])

  useEffect(() => {
    dispatch(getPatientTypes())
    dispatch(
      fetchProviders({
        callback: () => {
          setIsFetchedProviders(true)
        },
      })
    )
  }, [])

  useEffect(() => {
    if (!location?.value) return
    setIsFetchedOperatories(false)
    dispatch(
      fetchOperatories({
        location,
        callback: () => {
          setIsFetchedOperatories(true)
        },
      })
    )
  }, [location])

  useEffect(() => {
    if (isFetchedOperatories && isFetchedAppointments && isFetchedProviders) {
      loadingProgressRef?.current?.complete()
    }
  }, [isFetchedOperatories, isFetchedAppointments, isFetchedProviders])

  useEffect(() => {
    if (!selectedAppt) {
      dispatch(schedulerPageLocationAndDate(false))
    }
  }, [setSelectedAppt])

  return (
    <div className="h-full flex flex-col absolute inset-0">
      <LoadingBar color="#63b245" height={4} ref={loadingProgressRef} />

      <div className="flex-1 relative dark:bg-black-700 text-gray-400 flex overflow-hidden">
        <Aside
          providersData={scheduleState?.providers}
          showApptInfo={showApptInfo}
          setShowApptInfo={setShowApptInfo}
          setIsCreatingAppt={setIsCreatingAppt}
          setLocation={(location: any): { type: string; payload: IOption } => dispatch(setLocation(location))}
          location={location}
          providers={providers}
          setProviders={setProviders}
          apptStatus={apptStatus}
          setApptStatus={setApptStatus}
        />
        <section className={`h-full flex-1 dark:text-white relative ${scheduleState?.operatories?.length ? "opacity-100" : "opacity-0"} delay-200`}>
          <ScheduleTemplate
            updateLocalAppointment={updateAppointmentClosure}
            setSelectedCell={setSelectedCell}
            setIsCreatingAppt={setIsCreatingAppt}
            setSelectedAppt={setSelectedAppt}
            operatories={scheduleState?.operatories || []}
            appointments={isFetchedAppointments ? scheduleState?.appointments?.filter((appt: any) => appt.ascend_id) || [] : []}
            showApptInfo={showApptInfo}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        </section>
      </div>
      {isCreatingAppt ? (
        <CreateAppointment
          updatePatientInAppts={updatePatientInApptsClosure}
          location={location}
          setSelectedCell={setSelectedCell}
          operatories={scheduleState?.operatories}
          selectedAppt={selectedAppt}
          selectedCell={selectedCell}
          setSelectedAppt={setSelectedAppt}
          setIsCreatingAppt={setIsCreatingAppt}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      ) : null}
    </div>
  )
}

export default Schedule
