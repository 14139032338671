import { Fragment, useEffect, useState } from "react"
import Button from "../../components/Inputs/Button"
import { useDispatch, useSelector } from "react-redux"
import { getReviewPlatformAddress, storeReviewPlatformAddress, updateReviewPlatformAddress, deleteReviewPlatformAddress } from "../../Store/ReviewPlatformAddress/actions"
import { Dialog, Transition } from "@headlessui/react"
import { FormikProvider, useFormik, Form } from "formik"
import * as Yup from "yup"
import useLocationsSelect from "../../hooks/useLocationsSelect"
import Select from "../../components/Inputs/Select"
import { toast } from "react-toastify"
import { AiTwotoneEdit, AiTwotoneDelete } from "react-icons/ai"
import { ExclamationIcon } from "@heroicons/react/outline"

export const ReviewLinks = () => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [confirmation, setConfirmation] = useState({ show: false, data: null })
  const [location, setLocation] = useState<any>("")
  const [updateReviewLink, setUpdateReviewLink] = useState<any>(null)
  const locations = useSelector((state: any) => state?.ReviewPlatformAddress?.LocationReviewLinks)

  function closeModal() {
    setIsOpen(false)
    setLocation("")
    setUpdateReviewLink(null)
  }
  function openModal(reviewLink: any) {
    setIsOpen(true)
    if (reviewLink) {
      setUpdateReviewLink(reviewLink)
    }
  }
  function closeConfirmationModal() {
    setConfirmation({ show: false, data: null })
  }
  function openConfirmationModal(reviewLink: any) {
    setConfirmation({ show: true, data: reviewLink })
  }

  useEffect(() => {
    dispatch(getReviewPlatformAddress(null))
  }, [])

  return (
    <>
      <div className="my-5">
        <div className="flex justify-between">
          <h2 className="text-2xl xl:ml-10 sm:mb-0 font-bold dark:text-white tracking-wide">Location Review Links</h2>
          <button
            className="rounded-md xl:mr-10 py-2 px-2 flex justify-between dark:text-white border 
                border-gray-300 bg-white dark:bg-black-900 dark:border-black-900 shadow-sm focus:outline-none 
                focus:ring-2 dark:focus:ring-offset-black-700 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
            onClick={() => openModal(null)}
          >
            Add Review Link
          </button>
        </div>
      </div>
      <div className="flex flex-col xl:mx-10">
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="border rounded-lg overflow-y-auto h-full max-h-[calc(100vh-180px)]">
              <table className="table-auto w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">Location</th>
                    <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase max-w-md break-words">Yelp Link</th>
                    <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase max-w-md break-words">Google Link</th>
                    <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {locations?.length ? (
                    locations.map((loc: any, i: any) => {
                      return (
                        <tr key={i} className="hover:bg-gray-100 dark:hover:bg-black-800 transition duration-200">
                          <td className="px-6 py-3 text-sm lg:text-md">{loc?.location?.name?.replace("Total Health Dental Care ", "")}</td>
                          <td className="px-6 py-3 text-sm lg:text-md max-w-md break-words">{loc.yelpLink ?? "N/A"}</td>
                          <td className="px-6 py-3 text-sm lg:text-md max-w-md break-words">{loc.googleLink ?? "N/A"}</td>
                          <td className="px-6 py-3 text-sm lg:text-md">
                            <div className="flex">
                              <div className="px-2 cursor-pointer" onClick={() => openModal(loc)}>
                                <AiTwotoneEdit size={22} color="#0047ba" />
                              </div>
                              <div className="px-2 cursor-pointer" onClick={() => openConfirmationModal(loc)}>
                                <AiTwotoneDelete size={22} color="#c72700" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr className="w-full hover:bg-gray-100 dark:hover:bg-black-800 transition duration-200">
                      <td className="px-6 py-3 text-sm lg:text-md"> No record found!</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ReviewLinkDialog closeModal={closeModal} isOpen={isOpen} setLocation={setLocation} location={location} updateReviewLink={updateReviewLink} />
      {confirmation.show && <ConfirmDeleteDialog closeModal={closeConfirmationModal} confirmation={confirmation} />}
    </>
  )
}

export const ConfirmDeleteDialog = ({ closeModal, confirmation }: any) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const handleDelete = () => {
    setLoading(true)
    dispatch(
      deleteReviewPlatformAddress({
        id: confirmation.data.objectId,
        setLoading: setLoading,
        closeModal: closeModal,
      })
    )
  }
  return (
    <>
      <Transition appear show={confirmation.show} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 overflow-y-auto z-50" onClose={closeModal}>
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:px-4 sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity overlay-dialog" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white dark:bg-black-700 rounded-lg space-y-3 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-xl sm:w-full p-7">
                <div className="sm:flex sm:flex-col">
                  <div className="flex-shrink-0 flex items-center justify-center h-10 mb-3 w-10 lg:h-14 lg:w-14 rounded-full bg-white-100 shadow-xl border border-1 border-gray-100 dark:bg-black-800 mx-auto ">
                    <ExclamationIcon className="h-6 w-6 text-red-600 dark:text-black-200" aria-hidden="true" />
                  </div>
                  <div className="text-center mt-5 sm:text-left">
                    <Dialog.Title as="h2" className="text-xl text-center leading-6 font-medium text-gray-900 dark:text-white">
                      Delete Review Link
                    </Dialog.Title>
                    <div className="my-4 ">
                      <p className="text-md text-gray-500 text-center dark:text-gray-200 px-4 mb-5">Please confirm to delete Review Link for {confirmation.data.location.name.replace("Total Health Dental Care", "")}?</p>
                    </div>
                  </div>
                </div>
                <div className="sm:mt-4 sm:flex sm:flex-row-reverse justify-center">
                  <div>
                    <Button className="w-32 ml-2 border-green-500" variant="outlined" onClick={handleDelete} loading={loading}>
                      Confirm
                    </Button>
                  </div>
                  <div>
                    <Button className="w-32 mr-2 border-red-500" variant="outlined" onClick={closeModal}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
export const ReviewLinkDialog = ({ closeModal, isOpen, updateReviewLink, setLocation, location }: any) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (updateReviewLink) {
      setLocation({
        id: `${updateReviewLink?.location?.objectId}`,
        value: `${updateReviewLink?.location?.objectId}`,
        name: updateReviewLink?.location?.name?.replace("Total Health Dental Care ", ""),
        timeZone: updateReviewLink?.location?.timeZone,
      })
    }
  }, [updateReviewLink])

  const locations = useSelector((state: any) => state?.Appointment?.locations)
  let [locationArrayState] = useLocationsSelect(locations || [])

  const initialValues: any = {
    yelpLink: updateReviewLink ? updateReviewLink.yelpLink : "",
    googleLink: updateReviewLink ? updateReviewLink.googleLink : "",
  }

  //   const reviewLinkSchema = Yup.object().shape({
  //     googleLink: Yup.string().required("Please enter Yelp Link"),
  //     yelpLink: Yup.string().required("Please enter Google Link"),
  //   })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    // validationSchema: reviewLinkSchema,
    onSubmit: async (values) => {
      setLoading(true)

      if (updateReviewLink) {
        dispatch(
          updateReviewPlatformAddress({
            yelpLink: values.yelpLink,
            googleLink: values.googleLink,
            location: location,
            setLocation: setLocation,
            setLoading: setLoading,
            resetForm: formik.resetForm,
            closeModal: closeModal,
            id: updateReviewLink.objectId,
          })
        )
      } else {
        if (location) {
          dispatch(
            storeReviewPlatformAddress({
              yelpLink: values.yelpLink,
              googleLink: values.googleLink,
              location: location,
              setLocation: setLocation,
              setLoading: setLoading,
              resetForm: formik.resetForm,
              closeModal: closeModal,
            })
          )
        } else {
          toast.error("Select location to continue")
          setLoading(false)
        }
      }
    },
  })
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 overflow-y-auto z-50" onClose={closeModal}>
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:px-4 sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity overlay-dialog" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white dark:bg-black-700 rounded-lg text-left overflow-visible transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-xl sm:w-full" style={{ boxShadow: "rgb(0 0 0 / 10%) 1px 1px 8px 5px" }}>
                <section className="flex flex-col max-w-screen-2xl overflow-hidden sm:pt-40 mx-auto md:pt-0">
                  <div className="px-4 py-4 xs:py-20 md:px-10 lg:px-8">
                    <h1 className="font-medium text-2xl text-gray-700 dark:text-white capitalize mb-5">{updateReviewLink ? "Update Review Link" : "Add Review Link"}</h1>
                    <div>
                      <FormikProvider value={formik}>
                        <Form noValidate onSubmit={formik.handleSubmit}>
                          <div className="w-full mb-5">
                            <label htmlFor="googleLink" className="block text-sm font-medium text-gray-700">
                              Location
                            </label>
                            <div className="mt-1">
                              <Select value={location} options={locationArrayState} onChange={(event: any) => setLocation(event)} key="id" />
                            </div>
                          </div>
                          <div className="space-y-1 mb-5">
                            <label htmlFor="googleLink" className="block text-sm font-medium text-gray-700">
                              Google Link
                            </label>
                            <div className="mt-1 mb-3">
                              <input
                                name="googleLink"
                                type="text"
                                value={formik.values && formik.values.googleLink}
                                onChange={formik.handleChange}
                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 shadow-sm placeholder:text-gray-400 focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                              />
                              {/* {formik.touched.googleLink && formik.errors.googleLink ? <span className="text-sm text-red-500">{formik.errors.googleLink}</span> : null} */}
                            </div>
                          </div>
                          <div className="space-y-1 mb-5">
                            <label htmlFor="yelpLink" className="block text-sm font-medium text-gray-700">
                              Yelp Link
                            </label>
                            <div className="mt-1">
                              <input
                                name="yelpLink"
                                type="text"
                                value={formik.values && formik.values.yelpLink}
                                onChange={formik.handleChange}
                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 shadow-sm placeholder:text-gray-400 focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                              />
                              {/* {formik.touched.yelpLink && formik.errors.yelpLink ? <span className="text-sm text-red-500">{formik.errors.yelpLink}</span> : null} */}
                            </div>
                          </div>
                          <div className="flex w-full mb-3">
                            <Button type="button" color="gray" className="mr-2 w-1/2" onClick={closeModal}>
                              Cancel
                            </Button>
                            <Button type="submit" color="indigo" className="ml-2 w-1/2" loading={loading}>
                              {updateReviewLink ? "Update" : "Save"}
                            </Button>
                          </div>
                        </Form>
                      </FormikProvider>
                    </div>
                  </div>
                </section>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
