export interface dataTypeFiltersProps {
  String: {
    value: string
    label: string
    getInput: boolean
  }[]
  Number: {
    value: string
    label: string
    getInput: boolean
  }[]
  Boolean: {
    value: string
    label: string
    getInput: boolean
  }[]
  Array: {
    value: string
    label: string
    getInput: boolean
  }[]
  Object: {
    value: string
    label: string
    getInput: boolean
  }[]
  Date: {
    value: string
    label: string
    getInput: boolean
  }[]
  Pointer: {
    value: string
    label: string
    getInput: boolean
  }[]
}
export interface dropdownSelectValuesProps {
  gender: {
    value: string
    label: string
  }[]
  status: {
    value: string
    label: string
  }[]
  patientTypes: {
    value: string
    label: string
  }[]
  patientStatus: {
    value: string
    label: string
  }[]
}

export const dataTypeFilters: dataTypeFiltersProps = {
  String: [
    {
      value: "exists",
      label: "Exist",
      getInput: false,
    },
    {
      value: "doesNotExist",
      label: "Does not exist",
      getInput: false,
    },
    {
      value: "equalTo",
      label: "Equal to",
      getInput: true,
    },
    {
      value: "notEqualTo",
      label: "Not Equal to",
      getInput: true,
    },
    {
      value: "startsWith",
      label: "Starts with",
      getInput: true,
    },
    {
      value: "endsWith",
      label: "Ends with",
      getInput: true,
    },
    {
      value: "contains",
      label: "Contains",
      getInput: true,
    },
    {
      value: "fullText",
      label: "Full text",
      getInput: true,
    },
  ],
  Number: [
    {
      value: "exists",
      label: "Exist",
      getInput: false,
    },
    {
      value: "doesNotExist",
      label: "Does not exist",
      getInput: false,
    },
    {
      value: "equalTo",
      label: "Equal to",
      getInput: true,
    },
    {
      value: "notEqualTo",
      label: "Not Equal to",
      getInput: true,
    },
    {
      value: "lessThan",
      label: "Less than",
      getInput: true,
    },
    {
      value: "lessThanOrEqualTo",
      label: "Less than or equal to",
      getInput: true,
    },
    {
      value: "greaterThan",
      label: "Greater than",
      getInput: true,
    },
    {
      value: "greaterThanOrEqualTo",
      label: "Greater than or equal to",
      getInput: true,
    },
  ],
  Boolean: [
    {
      value: "exists",
      label: "Exists",
      getInput: false,
    },
    {
      value: "doesNotExist",
      label: "Does not exist",
      getInput: false,
    },
    {
      value: "equalTo",
      label: "Equal to",
      getInput: true,
    },
    {
      value: "notEqualTo",
      label: "Not equal to",
      getInput: true,
    },
  ],
  Array: [
    {
      value: "exists",
      label: "Exists",
      getInput: false,
    },
    {
      value: "doesNotExist",
      label: "Does not exist",
      getInput: false,
    },
    {
      value: "containsAll",
      label: "Contains all",
      getInput: true,
    },
    {
      value: "matchesQuery",
      label: "Matches query",
      getInput: true,
    },
    {
      value: "doesNotMatchQuery",
      label: "Does not match query",
      getInput: true,
    },
    {
      value: "matchesKeyInQuery",
      label: "Matches key in query",
      getInput: true,
    },
    {
      value: "doesNotMatchKeyInQuery",
      label: "Does not match key in query",
      getInput: true,
    },
  ],
  Object: [
    {
      value: "exists",
      label: "Exists",
      getInput: false,
    },
    {
      value: "doesNotExist",
      label: "Does not exist",
      getInput: false,
    },
    {
      value: "matchesKeyInQuery",
      label: "Matches key in query",
      getInput: true,
    },
    {
      value: "doesNotMatchKeyInQuery",
      label: "Does not match key in query",
      getInput: true,
    },
    {
      value: "matchesQuery",
      label: "Matches query",
      getInput: true,
    },
    {
      value: "doesNotMatchQuery",
      label: "Does not match query",
      getInput: true,
    },
    {
      value: "containsAll",
      label: "Contains all",
      getInput: true,
    },
  ],
  Date: [
    {
      value: "exists",
      label: "Exists",
      getInput: false,
    },
    {
      value: "doesNotExist",
      label: "Does not exist",
      getInput: false,
    },
    {
      value: "equalTo",
      label: "Equal to",
      getInput: true,
    },
    {
      value: "notEqualTo",
      label: "Not equal to",
      getInput: true,
    },
    {
      value: "greaterThan",
      label: "Greater than",
      getInput: true,
    },
    {
      value: "greaterThanOrEqualTo",
      label: "Greater than or equal to",
      getInput: true,
    },
    {
      value: "lessThan",
      label: "Less than",
      getInput: true,
    },
    {
      value: "lessThanOrEqualTo",
      label: "Less than or equal to",
      getInput: true,
    },
  ],
  Pointer: [
    {
      value: "exists",
      label: "Exists",
      getInput: false,
    },
    {
      value: "doesNotExist",
      label: "Does not exist",
      getInput: false,
    },
    {
      value: "equalTo",
      label: "Equal to",
      getInput: true,
    },
    {
      value: "notEqualTo",
      label: "Not equal to",
      getInput: true,
    },
  ],
}
export const dropdownSelectValues: dropdownSelectValuesProps = {
  gender: [
    {
      value: "M",
      label: "Male",
    },
    {
      value: "F",
      label: "Female",
    },
    {
      value: "O",
      label: "Other",
    },
  ],
  status: [
    {
      value: "COMPLETED",
      label: "Completed",
    },
    {
      value: "CHAIR",
      label: "Chair",
    },
    {
      value: "CONFIRMED",
      label: "Confirmed",
    },
    {
      value: "BROKEN",
      label: "Broken",
    },
    {
      value: "READY",
      label: "Ready",
    },
    {
      value: "CHECKOUT",
      label: "Checkout",
    },
    {
      value: "UNCONFIRMED",
      label: "Unconfirmed",
    },
    {
      value: "LATE",
      label: "Late",
    },
    {
      value: "HERE",
      label: "Here",
    },
    {
      value: "NO_SHOW",
      label: "No show",
    },
    {
      value: "LEFT_MESSAGE",
      label: "Left message",
    },
  ],
  patientTypes: [
    {
      value: "Type 1 - Treat only Emergencies",
      label: "Type 1 - Treat only Emergencies",
    },
    {
      value: "Type 2 - Within Insurance limits preventative care",
      label: "Type 2 - Within Insurance limits preventative care",
    },
    {
      value: "Type 3 - Open to elective / Cosmetic Care",
      label: "Type 3 - Open to elective / Cosmetic Care",
    },
    {
      value: "Type 4 - Cash Patient / Elective or Emergency Only. No Preventative",
      label: "Type 4 - Cash Patient / Elective or Emergency Only. No Preventative",
    },
  ],
  patientStatus: [
    {
      value: "NEW",
      label: "New",
    },
    {
      value: "DUPLICATE",
      label: "Duplicate",
    },
    {
      value: "NON-PATIENT",
      label: "Non patient",
    },
    {
      value: "ACTIVE",
      label: "Active",
    },
    {
      value: "INACTIVE",
      label: "Inactive",
    },
  ],
}
