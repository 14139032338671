import Parse from "parse"
import { BsToggleOff, BsToggleOn } from "react-icons/bs"
import { FcCheckmark } from "react-icons/fc"
import { RxCross2 } from "react-icons/rx"
import * as Yup from "yup"
import { Form, FormikProvider, useFormik } from "formik"
import { Dialog, Transition } from "@headlessui/react"
import { toast } from "react-toastify"
import { useState } from "react"
import Button from "../Inputs/Button"
import { useSelector } from "react-redux"

const CronJobsParamsModal = (props: any): JSX.Element => {
  const { viewMoreModal, viewMoreDetail, setViewMoreModal, cronJobs, setCronJobs, togglesendEmailsToPatients, toggleSendSmsToPatient } = props
  let user = useSelector((state: any) => state?.Auth?.user)
  const [loading, setLoading] = useState(false)
  const [reactivationVerification, setReactivationVerification] = useState(1)
  interface MyFormValues {
    password: string
  }
  const loginUserSchema = Yup.object().shape({
    password: Yup.string().required("Please enter password"),
  })

  const initialValues: MyFormValues = {
    password: "",
  }
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: loginUserSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true)
        await Parse.User.logIn(user?.email?.toLowerCase(), values.password)
        setReactivationVerification(2)
        setLoading(false)
        formik.resetForm()
      } catch (error: any) {
        setLoading(false)
        toast.error(JSON.stringify(error.message))
      }
    },
  })

  return (
    <Transition appear show={viewMoreModal} as={"div"}>
      <Dialog as="div" className="fixed inset-0 overflow-y-auto z-50" onClose={() => setViewMoreModal(false)}>
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:px-4 sm:p-0">
          <Transition.Child
            as={"div"}
            className="w-5/12"
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white dark:bg-black-700 rounded-lg text-left overflow-visible transform transition-all w-full" style={{ boxShadow: "rgb(0 0 0 / 10%) 1px 1px 8px 5px" }}>
              <section className="flex flex-col sm:pt-40 mx-auto md:pt-0">
                <div className="w-full border-b border-gray-300">
                  {reactivationVerification === 1 ? (
                    <>
                      <div className="p-16">
                        <h2 className="mt-5 text-xl mb-8 font-bold text-gray-900">Enter credentials</h2>
                        <FormikProvider value={formik}>
                          <Form noValidate onSubmit={formik.handleSubmit}>
                            <div>
                              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                              </label>
                              <div className="mt-1 mb-3">
                                <input
                                  readOnly={true}
                                  name="email"
                                  type="email"
                                  autoComplete="email"
                                  value={user?.email}
                                  className="block w-full bg-gray-200 appearance-none rounded-md border border-gray-300 px-3 py-2 shadow-sm placeholder:text-gray-400 focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div className="mb-8 space-y-1">
                              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                              </label>
                              <div className="mt-1">
                                <input
                                  name="password"
                                  type="password"
                                  value={formik.values && formik.values.password}
                                  onChange={formik.handleChange}
                                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 shadow-sm placeholder:text-gray-400 focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                                />
                                {formik.touched.password && formik.errors.password ? <span className="text-sm text-red-500">{formik.errors.password}</span> : null}
                              </div>
                            </div>
                            <Button type="submit" color="gray" className="w-full" loading={loading}>
                              Sign in
                            </Button>
                          </Form>
                        </FormikProvider>
                      </div>
                      <div className="p-4 border-t border-gray-300 text-right">
                        <button
                          type="button"
                          className="mr-2 inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                          onClick={() => {
                            setViewMoreModal(false)
                            setReactivationVerification(1)
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="w-full px-4 py-4 xs:py-20 md:px-10 lg:px-8 2xl:pr-20 2xl:pl-8 border-b border-gray-300">
                        <h1 className="font-medium text-2xl text-gray-700 dark:text-white capitalize">Parameters</h1>
                      </div>
                      <div className="p-5">
                        <div className="bg-green-50 p-5">
                          <div className="grid grid-flow-row-dense grid-cols-3 grid-rows-3 mb-2">
                            <p className="mr-3 text-green-700 text-sm font-semibold">Send Sms To Patients:</p>
                            <button
                              className={viewMoreDetail?.obj?.get("arguments").get("sendSmsToPatients") ? "text-green-700 col-span-2" : "text-red-500 col-span-2"}
                              type="button"
                              onClick={async () => {
                                await toggleSendSmsToPatient(viewMoreDetail?.obj?.get("arguments"))
                                cronJobs[viewMoreDetail?.index] = viewMoreDetail?.obj
                                setCronJobs([...cronJobs])
                              }}
                            >
                              {viewMoreDetail?.obj?.get("arguments").get("sendSmsToPatients") ? (
                                <div className="flex">
                                  <BsToggleOn className={`h-6 w-6`} />
                                  <p className="text-sm ml-2 text-green-600 font-semibold">Enabled</p>
                                </div>
                              ) : (
                                <div className="flex">
                                  <BsToggleOff className={`h-6 w-6`} />
                                  <p className="text-sm ml-2 text-red-600 font-semibold">Disabled</p>
                                </div>
                              )}
                            </button>
                            <p className="mr-3 text-green-700 text-sm font-semibold">Send Email To Patients:</p>
                            <button
                              className={viewMoreDetail?.obj?.get("arguments").get("sendEmailsToPatients") ? "text-green-700 col-span-2" : "text-red-500 col-span-2"}
                              type="button"
                              onClick={async () => {
                                await togglesendEmailsToPatients(viewMoreDetail?.obj?.get("arguments"))
                                cronJobs[viewMoreDetail?.index] = viewMoreDetail?.obj
                                setCronJobs([...cronJobs])
                              }}
                            >
                              {viewMoreDetail?.obj?.get("arguments").get("sendEmailsToPatients") ? (
                                <div className="flex">
                                  <BsToggleOn className={`h-6 w-6`} />
                                  <p className="text-sm ml-2 text-green-600 font-semibold">Enabled</p>
                                </div>
                              ) : (
                                <div className="flex">
                                  <BsToggleOff className={`h-6 w-6`} />
                                  <p className="text-sm ml-2 text-red-600 font-semibold">Disabled</p>
                                </div>
                              )}
                            </button>
                            <p className="mr-3 text-green-700 text-sm font-semibold">Last Appointment:</p>
                            <p className="mr-3 text-gray-500 text-xs mt-1  col-span-2">{viewMoreDetail?.obj?.get("arguments").get("lastAppointmentDaysAgo")} days ago</p>
                          </div>
                          <div className="grid grid-flow-row-dense grid-cols-3 grid-rows-1 mb-2">
                            <p className="mr-3 text-green-700 text-sm font-semibold">CC Emails</p>
                            <div className="col-span-2">
                              {viewMoreDetail?.obj
                                ?.get("arguments")
                                .get("ccEmails")
                                .map((email: string) => (
                                  <p className="mr-3 text-gray-500 text-sm mt-1">{email}</p>
                                ))}
                            </div>
                          </div>
                          <div className="w-full">
                            <p className="mr-3 text-green-700 text-sm font-semibold">Email Template:</p>
                            <textarea
                              rows={8}
                              placeholder={""}
                              className="w-100 mt-2 mb-3 px-3 py-2 bg-transparent border dark:border-black-900 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-mobile-green-50"
                              value={viewMoreDetail?.obj?.get("arguments").get("emailTemplate") || ""}
                              onChange={(e) => {
                                viewMoreDetail?.obj?.get("arguments").set("emailTemplate", e.target.value)
                                cronJobs[viewMoreDetail?.index] = viewMoreDetail?.obj
                                setCronJobs([...cronJobs])
                              }}
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="p-4 border-t border-gray-300 text-right">
                        <button
                          type="button"
                          className="mr-2 inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                          onClick={() => {
                            setViewMoreModal(false)
                            setReactivationVerification(1)
                          }}
                        >
                          <RxCross2 className={`h-6 w-6`} />
                        </button>
                        <button
                          type="button"
                          className="mr-2 inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                          onClick={() => {
                            viewMoreDetail?.obj.save()
                            toast.success("Email templete updated successfully")
                          }}
                        >
                          <FcCheckmark className={`h-6 w-6`} />
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </section>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default CronJobsParamsModal
