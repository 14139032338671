import Parse from "parse"
import { fork, put, all, takeLatest } from "redux-saga/effects"
import { toast } from "react-toastify"
import { LOGIN, REGISTER_USER, RESET_USER_PASSWORD } from "./actionTypes"
import { sagaErrorHandler } from "../../utils/SagaErrorHandler"
import { loginSuccess } from "./actions"

function* loginUser({ payload }: any): any {
  const { email, password, resetForm, setBtnDisable, navigate } = payload
  try {
    setBtnDisable(true)
    yield Parse.User.logIn(email.toLowerCase(), password)
    toast.success("Login successfull")
    setBtnDisable(false)
    navigate("/schedule")
    resetForm()
    yield put(loginSuccess())
  } catch (error: any) {
    setBtnDisable(false)
    yield sagaErrorHandler(error)
  }
}

function* resetPasswordRequest({ payload }: any): any {
  const { email, resetForm, setBtnDisable, navigate } = payload
  try {
    setBtnDisable(true)
    yield Parse.Cloud.run("resetUserPassword", { email: email })
    toast.success("Reset Password Link sent")
    setBtnDisable(false)
    navigate("/crm")
    resetForm()
  } catch (error: any) {
    setBtnDisable(false)
    yield sagaErrorHandler(error)
  }
}

function* registerUser({ payload }: any): any {
  const { firstName, lastName, email, password, resetForm, navigate, setBtnDisable } = payload
  try {
    setBtnDisable(true)
    yield Parse.Cloud.run("signup", {
      firstName: firstName,
      lastName: lastName,
      email: email.toLowerCase(),
      password: password,
    })
    resetForm()
    setBtnDisable(false)
    toast.success("User registered successfully")
    navigate("/crm")
  } catch (error: any) {
    setBtnDisable(false)
    yield sagaErrorHandler(error)
  }
}

export function* watchLogin() {
  yield takeLatest(LOGIN, loginUser)
}
export function* watchRegister() {
  yield takeLatest(REGISTER_USER, registerUser)
}
export function* watchResetPassword() {
  yield takeLatest(RESET_USER_PASSWORD, resetPasswordRequest)
}

export default function* authSaga() {
  yield all([fork(watchLogin), fork(watchRegister), fork(watchResetPassword)])
}
