import cronstrue from "cronstrue"
import Parse from "parse"
import { useEffect, useState } from "react"
import { BsToggleOff, BsToggleOn } from "react-icons/bs"
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline"
import Spinner from "../Spinner"
import { HiDocumentText } from "react-icons/hi"
import ReactivationLogDetailsModal from "./ReactivationLogDetailsModal"
import Button from "../Inputs/Button"
import CronJobsParamsModal from "./CronJobsParamsModal"

const DATE_OPTIONS = { weekday: "long", year: "numeric", month: "long", day: "numeric" }

const CronJobs = (props: any): JSX.Element => {
  const { closeModal } = props

  const [patientListLoader, setPatientListLoader] = useState<boolean>(false)
  const [isLogDetailsModalOpen, setIsLogDetailsModalOpen] = useState<boolean>(false)
  const [cronJobs, setCronJobs] = useState<Parse.Object[]>([])
  const [selectedCron, setSelectedCron] = useState<Parse.Object | null>()
  const [selectedCronHistoryLogs, setSelectedCronHistoryLogs] = useState<Parse.Object[]>([])
  const [selectedHistoryLogs, setSelectedHistoryLogs] = useState<Parse.Object | null>()
  const [selectedHistoryLogsPatients, setSelectedHistoryLogsPatients] = useState<Parse.Object[]>([])
  const [viewMoreModal, setViewMoreModal] = useState<boolean>(false)
  const [viewMoreDetail, setViewMoreDetail] = useState<any>()

  useEffect(() => {
    fetchCronJobs()
  }, [])

  useEffect(() => {
    if (selectedHistoryLogs) {
      setSelectedHistoryLogsPatients([])
      findAllPatientsForSelectedHistoryLogs(selectedHistoryLogs)
    } else {
      setSelectedHistoryLogsPatients([])
    }
  }, [selectedHistoryLogs])

  useEffect(() => {
    if (!selectedCron) {
      setSelectedCronHistoryLogs([])
    } else {
      getCronHistoryLogs(selectedCron.get("targetClassName"), selectedCron.get("executionType"))
    }
    console.log(selectedCron)
  }, [selectedCron])

  const viewHistoryLogsDetails = async (reactivation: Parse.Object) => {
    console.log(reactivation)
    setSelectedHistoryLogs(reactivation)
    setIsLogDetailsModalOpen(true)
  }

  const findAllPatientsForSelectedHistoryLogs = async (selectedHistoryLogs: Parse.Object) => {
    setPatientListLoader(true)
    const patients = await selectedHistoryLogs.relation("patients").query().findAll()
    setPatientListLoader(false)
    setSelectedHistoryLogsPatients(patients)
  }

  const togglesendEmailsToPatients = async (cronArguments: Parse.Object) => {
    cronArguments.set("sendEmailsToPatients", !cronArguments.get("sendEmailsToPatients"))
    await cronArguments.save()
  }

  const toggleSendSmsToPatient = async (cronArguments: Parse.Object) => {
    cronArguments.set("sendSmsToPatients", !cronArguments.get("sendSmsToPatients"))
    await cronArguments.save()
  }

  const fetchCronJobs = async () => {
    const CronJob = Parse.Object.extend("CronJob")
    const query = new Parse.Query(CronJob)
    query.include("arguments")
    const results = await query.find()
    setCronJobs(results)
  }

  const getCronHistoryLogs = async (targetClassName: string, executionType: string) => {
    const TargetClass = Parse.Object.extend(targetClassName)
    const query = new Parse.Query(TargetClass)
    query.equalTo("executionType", executionType)
    query.addDescending("createdAt")
    const logs = await query.find()
    setSelectedCronHistoryLogs(logs)
    return logs
  }

  return (
    <>
      <div className="flex-1 flex flex-col-reverse md:flex-row">
        <div className="flex flex-col automationHeight flex-1 w-full px-4 py-5 overflow-auto xs:py-8 md:w-1/2 md:px-8 lg:px-8 lg:pt-5 2xl:pr-8 2xl:pl-8 border-r border-gray-300">
          {cronJobs.length > 0 ? (
            <>
              {cronJobs?.map((obj: Parse.Object, index) => {
                return (
                  <div key={index} className="p-4 mb-2 h-auto relative rounded-lg" style={{ boxShadow: "rgb(0 0 0 / 10%) 0px 0px 3px 2px" }}>
                    <div className="flex">
                      <div>
                        <div className="flex mt-1">
                          <p className="font-semibold">{obj.get("label")}</p>
                          <button
                            className={obj.get("isActive") ? "text-green-700" : "text-red-500"}
                            type="button"
                            onClick={() => {
                              obj.set("isActive", !obj.get("isActive"))
                              obj.save()
                              cronJobs[index] = obj
                              setCronJobs([...cronJobs])
                            }}
                          >
                            {obj.get("isActive") ? (
                              <div className="flex ml-2">
                                <BsToggleOn className={`h-6 w-6 ml-2`} />
                                <p className="text-xs ml-2 mt-1 text-green-600 font-semibold">Enabled</p>
                              </div>
                            ) : (
                              <div className="flex ml-2">
                                <BsToggleOff className={`h-6 w-6 ml-2`} />
                                <p className="text-xs ml-2 mt-1 text-red-600 font-semibold">Disabled</p>
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="ml-auto text-mobile-gray-600 flex">
                        {selectedCron?.id === obj.id ? (
                          <button type="button" onClick={() => setSelectedCron(null)}>
                            <EyeOffIcon className={`text-green-600 h-8 w-8`} />
                          </button>
                        ) : (
                          <button type="button" onClick={() => setSelectedCron(obj)}>
                            <EyeIcon className={`text-green-600 h-8 w-8`} />
                          </button>
                        )}
                      </div>
                    </div>
                    {selectedCron?.id === obj.id ? (
                      <div className="flex-1 flex flex-col-reverse md:flex-row">
                        <div className="w-full md:w-1/3 mt-2">
                          <div className="w-full px-4 pt-4 pb-5 bg-green-50">
                            <div className="grid grid-flow-row-dense grid-cols-2 grid-rows-1 mb-2">
                              <p className="mr-3 text-green-700 text-sm font-semibold">Cron String:</p>
                              <p className="text-gray-500 text-sm text-center">{obj.get("crontab")}</p>
                            </div>
                            <div className="grid grid-flow-row-dense grid-cols-3 grid-rows-1 mb-2">
                              <p className="mr-3 text-green-700 text-sm font-semibold">Next Run:</p>
                              <p className="text-gray-500 col-span-2 text-sm text-center">{cronstrue.toString(obj.get("crontab"), { verbose: true, use24HourTimeFormat: true })}</p>
                            </div>
                            <p className="mr-3 text-green-700 text-sm font-semibold mb-2">Description:</p>
                            <p className="text-gray-500 col-span-2 text-sm">{obj.get("description")}</p>
                            <div className="text-center mt-3">
                              <Button
                                variant="outlined"
                                className="text-lime-700 mx-auto"
                                onClick={() => {
                                  setViewMoreModal(true)
                                  setViewMoreDetail({ obj: obj, index: index })
                                }}
                              >
                                View More
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="flex relative flex-col flex-1 w-full px-4 pt-0 pb-5 md:w-1/2 md:px-4 lg:px-4 lg:pt-0 2xl:pr-4 2xl:pl-4 ">
                          <p className="mb-3 text-lg font-semibold">History logs</p>
                          <div className="overflow-auto historyLogSection pl-4">
                            <ol className="relative border-l border-green-600 w-full dark:border-green-700">
                              {selectedCronHistoryLogs?.length > 0 ? (
                                <>
                                  {selectedCronHistoryLogs.map((log: Parse.Object) => {
                                    return (
                                      <div className="w-full px-4 py-3 ml-4 mb-5 overflow-auto bg-green-50">
                                        <li key={log.id}>
                                          <div className="flex">
                                            <div className="w-[80%]">
                                              <div className="absolute w-3 h-3 bg-green-600 rounded-full mt-1.5 -left-1.5 border border-white dark:border-green-900 dark:bg-green-700"></div>
                                              <time className="mb-1 text-xs font-normal leading-none text-gray-500 dark:text-gray-500">{log.createdAt.toLocaleString("en-US")}</time>
                                              <h5 className="text-md font-semibold text-gray-900 dark:text-white mb-1">({log.get("patientCount") ?? 0}) Patients Found For {log.get("controlClass")}</h5>
                                              <p className="text-xs font-normal text-gray-500 dark:text-gray-400 flex">
                                                <b>Last Appt Date :</b>&nbsp;{log.get("executionDate").toLocaleDateString("en-US", DATE_OPTIONS)}
                                              </p>
                                            </div>
                                            <div className="mt-6 w-[35%]">
                                              <p className="text-xs font-normal text-gray-500 dark:text-gray-400 flex w-[35%]">
                                                <b>Successful Appointments Count : &nbsp; {log.get("appointmentSuccessCount") ?? 0}</b>
                                              </p>
                                            </div>
                                            <div className="ml-auto mt-6 mr-1">
                                              <button type="button" onClick={() => setSelectedHistoryLogs(selectedHistoryLogs?.id === log.id ? null : log)}>
                                                {selectedHistoryLogs?.id === log.id ? <EyeOffIcon className={`text-green-700 h-5 w-5 ml-1`} /> : <EyeIcon className={`text-green-700 h-5 w-5 ml-1`} />}
                                              </button>
                                              {selectedHistoryLogs?.id === log.id ? <ReactivationLogDetailsModal reactivation={log} automateModalSection={0} isOpen={isLogDetailsModalOpen} closeModal={() => setIsLogDetailsModalOpen(false)} /> : <></>}
                                              <button type="button" onClick={() => viewHistoryLogsDetails(log)} className="ml-1">
                                                <HiDocumentText className={`text-green-700 h-5 w-5`} />
                                              </button>
                                            </div>
                                          </div>
                                          {selectedHistoryLogs?.id === log.id ? (
                                            <>
                                              <div className="flex h-52 flex-col flex-1 w-full px-4 pt-4 pb-5 overflow-auto bg-green-50 mt-3">
                                                <ol className="relative border-l border-green-600 dark:border-green-700">
                                                  {patientListLoader && (
                                                    <div className="absolute mt-2 top-0 left-0 w-full h-full flex justify-center items-center z-10 bg-mobile-grey-50 bg-opacity-25">
                                                      <Spinner name={`h-10 w-10`} />
                                                    </div>
                                                  )}
                                                  {!patientListLoader ? (
                                                    selectedHistoryLogsPatients?.length > 0 ? (
                                                      selectedHistoryLogsPatients?.map((patient: Parse.Object) => {
                                                        return (
                                                          <div className="flex">
                                                            <li className="mb-3 ml-4" onClick={() => { }}>
                                                              <div className="absolute w-3 h-3 bg-green-600 rounded-full mt-1.5 -left-1.5 border border-white dark:border-green-900 dark:bg-green-700"></div>
                                                              <h3 className="text-sm font-semibold text-green-900 dark:text-white">
                                                                <a href={`https://live4.dentrixascend.com/pm#/patient/overview/${patient.id}`} target="_blank" className="mb-1 leading-none text-green-700 dark:text-gray-500">
                                                                  {patient.get("firstName")} {patient.get("lastName")}
                                                                </a>
                                                              </h3>
                                                              <p className="mb-1 text-sm font-normal leading-none text-gray-500 dark:text-green-700">{patient.get("emailAddress")}</p>
                                                              {patient?.get("phones")?.map((obj: any) => {
                                                                return <p className="mb-1 text-sm font-normal leading-none text-gray-500 dark:text-green-700">{obj?.number}</p>
                                                              })}
                                                            </li>
                                                          </div>
                                                        )
                                                      })
                                                    ) : (
                                                      <p className="text-lg text-center">No patient list are available</p>
                                                    )
                                                  ) : null}
                                                </ol>
                                              </div>
                                            </>
                                          ) : null}
                                        </li>
                                      </div>
                                    )
                                  })}
                                </>
                              ) : (
                                <div className="absolute mt-8 top-0 left-0 w-full h-full flex justify-center items-center z-10 bg-mobile-grey-50 bg-opacity-25">
                                  <Spinner name={`h-10 w-10`} />
                                </div>
                              )}
                            </ol>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )
              })}
            </>
          ) : (
            <p className="font-medium text-lg text-gray-700 dark:text-white text-center mt-10">No CRON JOBS avaliable</p>
          )}
        </div>
      </div>
      <div className="p-4 border-t border-gray-300 text-right">
        <button
          type="button"
          className="mr-2 inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
          onClick={() => closeModal(false)}
        >
          Close
        </button>
      </div>
      <CronJobsParamsModal viewMoreModal={viewMoreModal} viewMoreDetail={viewMoreDetail} setViewMoreModal={setViewMoreModal} cronJobs={cronJobs} setCronJobs={setCronJobs} togglesendEmailsToPatients={togglesendEmailsToPatients} toggleSendSmsToPatient={toggleSendSmsToPatient} />
    </>
  )
}
export default CronJobs
