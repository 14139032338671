import React, { useEffect, useState } from "react"
import { IOPTION, IPracticeProcedureProps } from "./PracticeProcedureSelect.interfaces"
import { customStyles } from "./PracticeProcedureSelect.styles"
import Select, { components, DropdownIndicatorProps, GroupBase, MultiValueGenericProps, OptionProps } from "react-select"
import Parse from "parse"
import Icon from "../../DataDisplay/Icon"
import { CheckIcon } from "@heroicons/react/solid"

const getOptionObject = (option: any): IOPTION => ({
  value: option.id,
  label: option.adaCode + " " + option.description,
})

const mapOptions = (values: any): Promise<IOPTION[]> => values.map((option: any) => getOptionObject(option))

const loadOptions = async (): Promise<any> => mapOptions(await fetchOptions())

const fetchOptions = async (): Promise<any> => {
  const PracticeProcedureV1 = Parse.Object.extend("PracticeProcedureV1")

  const query = new Parse.Query(PracticeProcedureV1)

  const res: any = await query.findAll()

  const data = res.map((procedure: any) => {
    return {
      id: procedure.id,
      adaCode: procedure?.attributes?.adaCode,
      description: procedure?.attributes?.description,
    }
  })

  return data
}
const fetchSelectedOptions = async (selectedProcedures: any): Promise<any> => {
  const PracticeProcedureV1 = Parse.Object.extend("PracticeProcedureV1")

  const query = new Parse.Query(PracticeProcedureV1)

  query.containedIn("objectId", selectedProcedures)

  const res: any = await query.find()

  const data = res.map((procedure: any) => {
    return {
      id: procedure.id,
      adaCode: procedure?.attributes?.adaCode,
      description: procedure?.attributes?.description,
    }
  })

  return data
}

const PracticeProcedureSelect = ({
  onChange,
  value,
  selected,
  isDark,
  menuBorderRadius,
  border,
  borderWidth,
  borderTopWidth,
  borderRightWidth,
  borderLeftWidth,
  borderBottomWidth,
  borderColor,
  borderTopColor,
  borderRightColor,
  borderLeftColor,
  borderBottomColor,
  borderStyle,
  borderTopStyle,
  borderRightStyle,
  borderLeftStyle,
  borderBottomStyle,
  borderRadius,
  backgroundColor,
}: IPracticeProcedureProps): JSX.Element => {
  const [options, setOptions] = useState([])
  const [isLoadingOptions, setIsLoadingOptions] = useState(false)
  const [isLoadingSelectedOptions, setIsLoadingSelectedOptions] = useState(false)

  useEffect(() => {
    setIsLoadingOptions(true)
    loadOptions().then((data: any) => {
      setOptions(data)
      setIsLoadingOptions(false)
    })
  }, [])

  useEffect(() => {
    if (selected?.length > 0) {
      setIsLoadingSelectedOptions(true)
      fetchSelectedOptions(selected).then((procedures: any) => {
        onChange?.(procedures?.map((procedure: any) => getOptionObject(procedure)))
        setIsLoadingSelectedOptions(false)
      })
    }
  }, [selected])

  const MultiValueContainer = ({ selectProps, data }: MultiValueGenericProps<any, true, GroupBase<any>>): JSX.Element => {
    const label = data.label
    const allSelected = selectProps?.value
    const index = allSelected.findIndex ? allSelected.findIndex((selected: any) => selected.label === label) : null
    const isLastSelected = index === allSelected.length - 1
    const labelSuffix = isLastSelected ? ` ` : ", "
    const val = `${label}${labelSuffix}`
    return <>{val}</>
  }
  const DropdownIndicator = (props: DropdownIndicatorProps<any, true, GroupBase<any>>): JSX.Element => {
    let arrow = (
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className={`h-5 w-5 text-gray-400`}>
          <path fillRule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd"></path>
        </svg>
      </div>
    )
    return <components.DropdownIndicator {...props}>{arrow}</components.DropdownIndicator>
  }

  const Option = (props: OptionProps<any, true, GroupBase<any>>): JSX.Element => {
    return (
      <div>
        <components.Option {...props}>
          <input type="checkbox" className="hidden" checked={props?.isSelected} onChange={(): null => null} />
          <label className={`text-base sm:text-sm w-full flex justify-between items-center`}>
            <span className="dark:text-white">{props?.label}</span>
            {props?.isSelected && (
              <span className={`${props?.isFocused ? "#fff" : "text-primary"} text-xl flex items-center justify-center`}>
                <Icon icon={CheckIcon} />
              </span>
            )}
          </label>
        </components.Option>
      </div>
    )
  }

  return (
    <span className={`block relative items-center group`} data-toggle="popover" data-trigger="focus">
      <Select
        isMulti
        isLoading={isLoadingOptions || isLoadingSelectedOptions}
        options={options}
        value={value}
        isClearable
        components={{
          MultiValueContainer: MultiValueContainer,
          DropdownIndicator: DropdownIndicator,
          IndicatorSeparator: () => null,
          Option: Option,
        }}
        onChange={onChange}
        styles={customStyles({
          isDark,
          menuBorderRadius,
          border,
          borderWidth,
          borderTopWidth,
          borderRightWidth,
          borderLeftWidth,
          borderBottomWidth,
          borderColor,
          borderTopColor,
          borderRightColor,
          borderLeftColor,
          borderBottomColor,
          borderStyle,
          borderTopStyle,
          borderRightStyle,
          borderLeftStyle,
          borderBottomStyle,
          borderRadius,
          backgroundColor,
        })}
      />
      {value?.length > 1 ? (
        <div className="absolute right-8 transform -translate-y-full -top-2 items-center ml-6 group-hover:flex hidden">
          <div className="relative mx-2 bg-black-900">
            <div className="bg-black text-white text-xs rounded py-2 px-4 right-0 bottom-full">
              {value?.map?.((option: IOPTION, index: number) => (
                <React.Fragment key={index}>
                  {option.label}
                  {index === value?.length - 1 ? "" : ", "}
                </React.Fragment>
              ))}
              <svg className="absolute h-2 right-0 mr-3 top-full text-black-900" x="0px" y="0px" viewBox="0 0 255 255" xmlSpace="preserve">
                <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
              </svg>
            </div>
          </div>
        </div>
      ) : null}
    </span>
  )
}

export default PracticeProcedureSelect
