import * as Parse from "parse"
import { fork, put, all, takeLatest } from "redux-saga/effects"
import * as types from "./actionTypes"
import { sagaErrorHandler } from "../../utils/SagaErrorHandler"
import { getReviewPlatformAddressSuccess } from "./actions"
import { toast } from "react-toastify"

function* getReviewPlatformAddressRequest(): any {
  try {
    const response = yield new Parse.Query("ReviewPlatformAddress").descending("createdAt").include(["location"]).find()

    yield put(getReviewPlatformAddressSuccess(response?.map((reviewLink: any) => reviewLink.toJSON())))
  } catch (error: any) {
    sagaErrorHandler(error)
  }
}

function* storeReviewPlatformAddressRequest({ payload }: any): any {
  const { googleLink, yelpLink, resetForm, setLoading, location, setLocation, closeModal } = payload
  try {
    if (googleLink || yelpLink) {
      const locationQuery = new Parse.Query("LocationV1")
      const locationObject: any = yield locationQuery.get(location?.value)

      const ReviewPlatformAddress = Parse.Object.extend("ReviewPlatformAddress")
      const reviewPlatformAddress = new ReviewPlatformAddress()
      reviewPlatformAddress.set("googleLink", googleLink)
      reviewPlatformAddress.set("yelpLink", yelpLink)
      reviewPlatformAddress.set("location", locationObject)

      const result = yield reviewPlatformAddress.save()
      if (result) {
        toast.success("Review Link Added Successfully!")
        resetForm()
        setLocation(null)
        setLoading(false)
        closeModal()
      }

      const response = yield new Parse.Query("ReviewPlatformAddress").descending("createdAt").include(["location"]).find()
      yield put(getReviewPlatformAddressSuccess(response?.map((link: any) => link.toJSON())))
    } else {
      toast.error("Please add a link!")
      setLoading(false)
      return
    }
  } catch (error: any) {
    setLoading(false)
    sagaErrorHandler(error)
  }
}

function* updateReviewPlatformAddressRequest({ payload }: any): any {
  const { id, googleLink, yelpLink, resetForm, setLoading, location, setLocation, closeModal } = payload
  try {
    if (googleLink || yelpLink) {
      const locationQuery = new Parse.Query("LocationV1")
      const locationObject: any = yield locationQuery.get(location?.value)

      const reviewPlatformAddressQuery = new Parse.Query("ReviewPlatformAddress")
      const reviewPlatformAddressObject: any = yield reviewPlatformAddressQuery.get(id)

      reviewPlatformAddressObject.set("googleLink", googleLink)
      reviewPlatformAddressObject.set("yelpLink", yelpLink)
      reviewPlatformAddressObject.set("location", locationObject)

      const result = yield reviewPlatformAddressObject.save()
      if (result) {
        toast.success("Review Link Updated Successfully!")
        resetForm()
        setLocation(null)
        setLoading(false)
        closeModal()
      }

      const response = yield new Parse.Query("ReviewPlatformAddress").descending("createdAt").include(["location"]).find()

      yield put(getReviewPlatformAddressSuccess(response?.map((link: any) => link.toJSON())))
    } else {
      toast.error("Please add a link!")
      setLoading(false)
      return
    }
  } catch (error: any) {
    setLoading(false)
    sagaErrorHandler(error)
  }
}

function* deleteReviewPlatformAddressRequest({ payload }: any): any {
  const { id, setLoading, closeModal } = payload
  try {
    const reviewPlatformAddressQuery = new Parse.Query("ReviewPlatformAddress")
    const reviewPlatformAddressObject: any = yield reviewPlatformAddressQuery.get(id)

    yield reviewPlatformAddressObject.destroy().then(
      () => {
        toast.success("Review Link Deleted Successfully")
        setLoading(false)
        closeModal()
      },
      (error: any) => {
        setLoading(false)
        sagaErrorHandler(error)
      }
    )
    const response = yield new Parse.Query("ReviewPlatformAddress").descending("createdAt").include(["location"]).find()

    yield put(getReviewPlatformAddressSuccess(response?.map((link: any) => link.toJSON())))
  } catch (error: any) {
    setLoading(false)
    sagaErrorHandler(error)
  }
}

export function* watchReviewPlatformAddress() {
  yield takeLatest(types.GET_REVIEW_PLATFORM_ADDRESSES, getReviewPlatformAddressRequest)
}

export function* watchStoreReviewPlatformAddress() {
  yield takeLatest(types.STORE_REVIEW_PLATFORM_ADDRESS, storeReviewPlatformAddressRequest)
}

export function* watchUpdateReviewPlatformAddress() {
  yield takeLatest(types.UPDATE_REVIEW_PLATFORM_ADDRESS, updateReviewPlatformAddressRequest)
}
export function* watchDeleteReviewPlatformAddress() {
  yield takeLatest(types.DELETE_REVIEW_PLATFORM_ADDRESS, deleteReviewPlatformAddressRequest)
}

export default function* reviewPlatformAddressSaga() {
  yield all([fork(watchReviewPlatformAddress), fork(watchStoreReviewPlatformAddress), fork(watchUpdateReviewPlatformAddress), fork(watchDeleteReviewPlatformAddress)])
}
