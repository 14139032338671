import { useEffect, useState } from "react"
import EllipsisProgress from "../../Feedback/Progress/Ellipsis"
import { IPatientSelectProps, IPatient } from "./PatientSelect.interfaces"
import { formatDate, validateEmail, formatPhoneNumber } from "../../../pages/schedule/utils"
import * as Parse from "parse"

import { SearchIcon, XIcon } from "@heroicons/react/outline"
import Icon from "../../DataDisplay/Icon"

import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { schedulerPageLocationAndDate } from "../../../Store/Schedule/actions"
import LocationAndApptsConfirmationModal from "../../../pages/schedule/modals/confirmation/confirmLocation"

export default function PatientSelect({ onChange, placeholder, borderLess, className, iconClassName, tableContainerClassName, avatarClassName, rowClickAble = true, patient, isReviewRequest }: IPatientSelectProps): JSX.Element {
  const dispatch = useDispatch()
  const [filteredOptions, setFilteredOptions] = useState<IPatient[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [query, setQuery] = useState("")
  const [selectedPerson, setSelectedPerson] = useState<IPatient>()
  const [shouldSearch, setShouldSearch] = useState(true)

  const selectedLocation = useSelector((state: any) => state?.Appointment?.location)
  const [pendingAppointment, setPendingAppointment] = useState<any>()
  const [openConfirmLocation, setOpenConfirmLocation] = useState<boolean>(false)
  const [selectedPatientOption, setSelectedPatientOption] = useState<any>()
  const [isWelcomeCenterLocation, setIsWelcomeCenterLocation] = useState<boolean | null>(null)

  const fetchAutocompletePredictions = async (value?: any) => {
    let inputType = value
    const data = value.split(" ")
    let onlyNumbersAndSpecial = /^(?=.*?[1-9])[0-9()-]+$/gm

    setLoading(true)

    let patientQueries: any[] = []
    let contactQueries: any[] = []

    for (var i = 0; i < data.length; i++) {
      const firstNameQuery = new Parse.Query("PatientV1")
      const lastNameQuery = new Parse.Query("PatientV1")
      const emailAddressQuery = new Parse.Query("PatientV1")
      const contactEmailQuery = new Parse.Query("Contact")
      const contactPhoneQuery = new Parse.Query("Contact")

      const word = data[i]

      firstNameQuery.matches("firstName", word, "i")
      lastNameQuery.matches("lastName", word, "i")
      emailAddressQuery.matches("emailAddress", word, "i")

      contactEmailQuery.matches("email", word, "i")
      contactPhoneQuery.matches("phone", word, "i")

      patientQueries.push(Parse.Query.or(firstNameQuery, lastNameQuery, emailAddressQuery))
      contactQueries.push(Parse.Query.or(contactEmailQuery, contactPhoneQuery))
    }

    let mainPatientQuery: any
    let mainContactQuery: any

    mainPatientQuery = Parse.Query.and(...patientQueries)
    mainContactQuery = Parse.Query.and(...contactQueries)

    let patientsResult: any
    let contactsResult: any

    let finalResult: any = {}

    contactsResult = await mainContactQuery.include("patient").findAll()
    for (let i = 0; i < contactsResult.length; i++) {
      const contact = contactsResult[i]
      if (contact.get("patient")) {
        finalResult[contact.get("patient").id] = contact.get("patient")
      }
    }

    patientsResult = await mainPatientQuery.findAll()

    for (let i = 0; i < patientsResult.length; i++) {
      finalResult[patientsResult[i].id] = patientsResult[i]
    }

    const patientsArray = Object.values(finalResult)
      ?.map((patient: any): IPatient => {
        return patient?.toJSON?.()
      })
      .filter((patient: IPatient) => patient.firstName || patient.lastName)
    setFilteredOptions(patientsArray)
    if (patientsArray.length < 1) {
      toast.error("Patient does not exist with these details")
    }
    setLoading(false)
  }

  const onClickAutocompletePrediction = async (option: IPatient) => {
    if (!option.hasCompletedAppointment && !isReviewRequest) {
      const locationResponse = await new Parse.Query("LocationV1").equalTo("welcomeCenterLocation", selectedLocation?.id).equalTo("isActive", true).first()
      const patientAppointments = await new Parse.Query("AppointmentV1").equalTo("patient", option.objectId).equalTo("migrationStatus", "COMPLETE").notEqualTo("status", "BROKEN").greaterThan("start", new Date().toISOString()).find()

      if (patientAppointments.length || !locationResponse) {
        setSelectedPatientOption(option)
        setOpenConfirmLocation(true)
        if (!locationResponse) {
          setIsWelcomeCenterLocation(false)
        }
        if (patientAppointments.length) {
          setPendingAppointment(patientAppointments[0])
        }
      } else {
        goToCreateAppointment(option)
      }
    } else if (isReviewRequest) {
      setShouldSearch(false)
      setSelectedPerson(option)
      onChange && onChange(option)
      setQuery(`${option.firstName} ${option.lastName}`)
      setFilteredOptions([])
    } else {
      const patientAppointments = await new Parse.Query("AppointmentV1").equalTo("patient", option.objectId).equalTo("migrationStatus", "COMPLETE").notEqualTo("status", "BROKEN").greaterThan("start", new Date().toISOString()).find()

      if (patientAppointments.length) {
        setPendingAppointment(patientAppointments[0])
        setSelectedPatientOption(option)
        setOpenConfirmLocation(true)
      } else {
        goToCreateAppointment(option)
      }
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event?.target?.value)
    setShouldSearch(true)
    if (!event?.target?.value) {
      setSelectedPerson(undefined)
    }
  }

  useEffect(() => {
    if (query && shouldSearch) {
      const delay = setTimeout(() => fetchAutocompletePredictions(query), 2000)
      return () => clearTimeout(delay)
    }
  }, [query, shouldSearch])

  useEffect(() => {
    if (!patient) {
      setQuery("")
      onChange && onChange(null)
      setSelectedPerson(undefined)
      setFilteredOptions([])
    }
  }, [patient])

  const goToCreateAppointment = (option: any) => {
    dispatch(schedulerPageLocationAndDate(true))
    setShouldSearch(false)
    setSelectedPerson(option)
    onChange && onChange(option)
    setQuery(`${option.firstName} ${option.lastName}`)
    setFilteredOptions([])
  }

  const handleCreateAnotherAppointment = () => {
    setOpenConfirmLocation(false)
    setPendingAppointment(false)
    setIsWelcomeCenterLocation(null)
    goToCreateAppointment(selectedPatientOption)
  }

  return (
    <>
      <LocationAndApptsConfirmationModal
        selectedPatientOption={selectedPatientOption}
        setSelectedPatientOption={setSelectedPatientOption}
        setPendingAppointment={setPendingAppointment}
        openConfirmLocation={openConfirmLocation}
        setOpenConfirmLocation={setOpenConfirmLocation}
        pendingAppointment={pendingAppointment}
        handleAgree={handleCreateAnotherAppointment}
        isWelcomeCenterLocation={isWelcomeCenterLocation}
        setIsWelcomeCenterLocation={setIsWelcomeCenterLocation}
      />
      <div className="relative">
        <div className={`relative flex items-center`}>
          <input
            value={query}
            onChange={handleChange}
            className={`w-full rounded-md ${selectedPerson ? "pl-10" : "pl-10"} py-2 pr-10  dark:text-white ${
              !borderLess ? "border border-gray-300 bg-white dark:bg-black-900 dark:border-black-900 shadow-sm focus:outline-none focus:ring-2 dark:focus:ring-offset-black-700 focus:ring-offset-2 focus:ring-gray-500" : "focus:outline-none"
            }  ${className} sm:text-sm`}
            placeholder={placeholder ? placeholder : `Search patient by name, email or phone number`}
          />
          {selectedPerson && (
            <>
              {selectedPerson?.profileImage ? (
                <div
                  className={`w-6 h-6 rounded-full flex absolute top-1/2 transform -translate-y-1/2 left-2.5 bg-cover ${avatarClassName}`}
                  style={{
                    backgroundImage: `url(${selectedPerson?.profileImage})`,
                  }}
                ></div>
              ) : (
                <div className={`w-6 h-6 rounded-full flex items-center justify-center absolute capitalize left-2.5 bg-black-700 dark:bg-black-700 text-white ${avatarClassName}`}>{selectedPerson.firstName[0] && selectedPerson.lastName[0]}</div>
              )}
            </>
          )}
          {!selectedPerson ? (
            <div className="pointer-events-none absolute flex justify-center left-3 text-gray-500 text-lg">
              <Icon icon={SearchIcon}></Icon>
            </div>
          ) : null}
          {loading && (
            <div className="absolute top-1/2 transform -translate-y-1/2 right-6">
              <div className="h-full w-full flex items-center justify-center">
                <div className="spinner-wrapper">
                  <EllipsisProgress color="gray-600" />
                </div>
              </div>
            </div>
          )}
          {query.length > 0 || filteredOptions.length > 0 ? (
            <button
              onClick={() => {
                setQuery("")
                onChange && onChange(null)
                setSelectedPerson(undefined)
                setFilteredOptions([])
              }}
              className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
            >
              <XIcon className={`h-5 w-5 ${iconClassName} text-gray-400 dark:text-gray-200`} aria-hidden="true" />
            </button>
          ) : null}
        </div>
        <div className={tableContainerClassName ? tableContainerClassName : `w-full mt-2 sm:w-full sm:max-w-full z-30 absolute`}>
          {filteredOptions.length > 0 ? (
            <div className="overflow-auto  border max-h-96 shadow rounded-lg">
              <table className="min-w-full divide-y divide-gray-300 dark:divide-black-700">
                <thead className="bg-gray-50 dark:bg-black-900">
                  <tr>
                    <th scope="col" className="whitespace-nowrap py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-6">
                      Patient
                    </th>
                    <th scope="col" className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 dark:text-white">
                      Phone
                    </th>
                    <th scope="col" className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 dark:text-white">
                      Email
                    </th>
                    <th scope="col" className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 dark:text-white">
                      DOB
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-black-700 bg-white  dark:bg-black-900">
                  {filteredOptions
                    .filter((patient) => patient.patientStatus !== "INACTIVE")
                    .map((option: any) => (
                      <tr
                        key={option?.objectId}
                        onClick={() => (rowClickAble ? onClickAutocompletePrediction(option) : null)}
                        className={`${selectedPerson && selectedPerson?.objectId === option?.objectId ? "bg-blue-200 dark:bg-black-800 " : ""}  ${rowClickAble ? " cursor-pointer hover:bg-blue-50 dark:hover:bg-black-800 " : ""}  `}
                      >
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 dark:text-gray-200 sm:pl-6 flex items-center">
                          <>
                            {option?.profileImage?.url ? (
                              <div
                                className="w-6 h-6 rounded-full bg-cover"
                                style={{
                                  backgroundImage: `url(${option?.profileImage?.url})`,
                                }}
                              ></div>
                            ) : (
                              <div className="w-6 h-6 rounded-full flex items-center justify-center bg-gray-800 dark:bg-black-700 text-white">{option?.firstName?.[0]}</div>
                            )}
                          </>
                          <span className="ml-2">
                            {option?.firstName} {option?.lastName}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-gray-200">{option?.phones && formatPhoneNumber(option?.phones[0]?.number)}</td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-gray-200">{option?.emailAddress}</td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 dark:text-gray-200">{formatDate(option?.dateOfBirth, "MM/DD/YYYY")}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}
