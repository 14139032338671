import * as Parse from "parse"
import { fork, put, all, takeLatest } from "redux-saga/effects"
import * as types from "./actionTypes"
import { sagaErrorHandler } from "../../utils/SagaErrorHandler"
import moment from "moment"
import { getAllFeedbackSuccess } from "./actions"

function* getFeedbackRequest({ payload }: any): any {
  const { patientId, providerId, locationId, source, startRangeDate, endRangeDate } = payload
  try {
    const feedbackQuery = new Parse.Query("Feedback")
    if (patientId) {
      feedbackQuery.equalTo("patient", patientId)
    }
    if (providerId) {
      feedbackQuery.equalTo("provider", providerId)
    }
    if (locationId) {
      feedbackQuery.equalTo("location", locationId)
    }
    if (source) {
      if (source === "all") {
        feedbackQuery.containedIn("source", ["web", "mobile"])
      } else {
        feedbackQuery.equalTo("source", source)
      }
    }

    if (startRangeDate) {
      let end
      const start = moment(startRangeDate).startOf("day").utc().format()
      if (endRangeDate) {
        end = moment(endRangeDate).endOf("day").utc().format()
      } else {
        end = moment(startRangeDate).endOf("day").utc().format()
      }

      feedbackQuery.greaterThanOrEqualTo("createdAt", start)
      feedbackQuery.lessThan("createdAt", end)
    }
    const feedbackList = yield feedbackQuery.descending("createdAt").include(["patient", "provider", "appointment"]).find()
    yield put(
      getAllFeedbackSuccess(
        feedbackList?.map((feedback: any) => {
          const isSourceMobile = feedback.get("source") === "mobile"
          if (isSourceMobile) {
            let feedbackJSON = feedback.toJSON()
            feedbackJSON.rating = feedbackJSON.rating * 2
            return feedbackJSON
          } else {
            return feedback.toJSON()
          }
        })
      )
    )
  } catch (error: any) {
    sagaErrorHandler(error)
  }
}
function* getYelpFeedbackRequest(): any {
  try {
    const yelpFeedbackList = yield new Parse.Query("Feedback").descending("createdAt").include(["patient", "provider", "appointment"]).find()
    yield put(
      getAllFeedbackSuccess(
        yelpFeedbackList?.map((feedback: any) => {
          const isSourceMobile = feedback.get("source") === "mobile"
          if (isSourceMobile) {
            let feedbackJSON = feedback.toJSON()
            feedbackJSON.rating = feedbackJSON.rating * 2
            return feedbackJSON
          } else {
            return feedback.toJSON()
          }
        })
      )
    )
  } catch (error: any) {
    sagaErrorHandler(error)
  }
}
function* getFeedbackByProviderRequest({ payload }: any): any {
  try {
    const feedbackList = yield new Parse.Query("Feedback").descending("createdAt").include(["patient", "provider", "appointment"]).get(payload.providerId)
    yield put(
      getAllFeedbackSuccess(
        feedbackList?.map((feedback: any) => {
          const isSourceMobile = feedback.get("source") === "mobile"
          if (isSourceMobile) {
            let feedbackJSON = feedback.toJSON()
            feedbackJSON.rating = feedbackJSON.rating * 2
            return feedbackJSON
          } else {
            return feedback.toJSON()
          }
        })
      )
    )
  } catch (error: any) {
    sagaErrorHandler(error)
  }
}

export function* watchFeedback() {
  yield takeLatest(types.GET_FEEDBACK, getFeedbackRequest)
}
export function* watchFeedbackByProvider() {
  yield takeLatest(types.GET_FEEDBACK_BY_PROVIDER, getFeedbackByProviderRequest)
}
export function* watchYelpFeedback() {
  yield takeLatest(types.GET_YELP_FEEDBACK, getYelpFeedbackRequest)
}

export default function* feedbackSaga() {
  yield all([fork(watchFeedback), fork(watchYelpFeedback)])
}
