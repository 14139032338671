import React, { useState, useEffect } from "react"
import SideModal from "./sideModal"
import { toast } from "react-toastify"

interface ChatItem {
  objectId: string
  userId: string
  question: string
  answer: string
  approvedAnswer: string
  createdAt: string
}

interface QuestionCardProps {
  category: string
}

const QuestionCard: React.FC<QuestionCardProps> = ({ category }) => {
  const [isCorrectionModalOpen, setIsCorrectionModalOpen] = useState(false)
  const [selectedChatItem, setSelectedChatItem] = useState<ChatItem | null>(null)
  const [chatData, setChatData] = useState<ChatItem[]>([])

  const openCorrectionModal = async (selectedChatItem: ChatItem) => {
    setIsCorrectionModalOpen(true)
    setSelectedChatItem(selectedChatItem)
  }

  const closeCorrectionModal = (): void => {
    setIsCorrectionModalOpen(false)
  }

  const handleCategoryChange = async (selectedChatItem: ChatItem, newCategory: string) => {
    try {
      if (!selectedChatItem) {
        throw new Error("Chat data is not available")
      }

      const response = await fetch(`${process.env.REACT_APP_SERVER_URL_CHAT}/api/chats/updateById/${selectedChatItem.objectId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: selectedChatItem.userId,
          question: selectedChatItem.question,
          answer: selectedChatItem.answer,
          category: newCategory,
        }),
      })

      if (!response.ok) {
        throw new Error(`Failed to update category. Status: ${response.status}`)
      }

      toast.success(`Chat moved to ${newCategory}`)
      window.location.reload()
    } catch (error) {
      toast.error("Error updating category")
    }
  }

  function removeHtmlTags(input: string): string {
    const strippedInput = input.replace(/<[^>]*>/g, "")
    return strippedInput
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL_CHAT}/api/chats/getByCategory/${category}`)
        if (!response.ok) {
          throw new Error(`Failed to fetch chat. Status: ${response.status}`)
        }

        const data = await response.json()
        setChatData(data)
      } catch (error) {
        toast.error("Couldn't fetch data from API. Try Again!")
      }
    }

    fetchData()

    return () => {}
  }, [category])

  return (
    <div>
      {chatData.length === 0 ? (
        <div className="flex items-center justify-center mt-40 h-full">
          <p className="text-xl font-normal">No data available for the selected category.</p>
        </div>
      ) : (
        chatData.map((chatItem) => (
          <div key={chatItem.objectId} className="max-w-full px-6 mx-auto mt-2">
            <div className="block p-6 bg-white border border-gray-200 rounded-lg shadow ">
              <div className="flex items-center">
                <h6 className="mb-1 text-xl font-bold tracking-tight text-gray-900">User Question</h6>
                <p className="mb-1 ml-3 font-normal text-md tracking-tight text-gray-900">{chatItem.createdAt}</p>
              </div>
              <p className="mb-1 font-w-200 text-lg tracking-tight text-gray-900 ">{chatItem.question}</p>
              <div className="pb-20">
                <h6 className="mb-1 text-lg font-bold mt-3 text-gray-900">Chatbot Answer</h6>
                <p className="mb-2 font-w-200 text-lg tracking-tight text-gray-900 ">{category === "approved" ? removeHtmlTags(chatItem.approvedAnswer) : removeHtmlTags(chatItem.answer)}</p>{" "}
              </div>
              <div className="flex mt-2 justify-center md:justify-end">
                {category !== "approved" && category !== "ignored" && (
                  <button type="button" className="flex items-center space-x-2 bg-gray-500 text-white px-2 py-1 rounded-lg hover:bg-gray-400 focus:outline-none focus:ring focus:border-blue-300" onClick={() => openCorrectionModal(chatItem)}>
                    <span className="flex-shrink-0">
                      <svg viewBox="0 0 24 24" focusable="false" className="w-4 h-4" aria-hidden="true">
                        <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2">
                          <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                          <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                        </g>
                      </svg>
                    </span>
                    <span className="flex-shrink-0">Create correction</span>
                  </button>
                )}
                {category !== "approved" && category !== "pending" && (
                  <button type="button" className="flex items-center space-x-2 bg-gray-500 text-white px-2 py-1 rounded-lg hover:bg-gray-400 focus:outline-none focus:ring focus:border-blue-300" onClick={() => handleCategoryChange(chatItem, "pending")}>
                    <span className="flex-shrink-0">
                      <svg viewBox="0 0 24 24" focusable="false" className="w-4 h-4" aria-hidden="true">
                        <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2">
                          <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                          <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                        </g>
                      </svg>
                    </span>
                    <span className="flex-shrink-0">Move to Pending</span>
                  </button>
                )}
                {category !== "approved" && category !== "ignored" && (
                  <button type="button" className="flex ml-3 items-center space-x-1 bg-gray-500 text-white px-2 py-1 rounded-md hover:bg-gray-400 focus:outline-none focus:ring focus:border-blue-300" onClick={() => handleCategoryChange(chatItem, "ignored")}>
                    <span className="flex-shrink-0">
                      <svg viewBox="0 0 24 24" focusable="false" className="w-3 h-3 fill-current" aria-hidden="true">
                        <path d="M.439,21.44a1.5,1.5,0,0,0,2.122,2.121L11.823,14.3a.25.25,0,0,1,.354,0l9.262,9.263a1.5,1.5,0,1,0,2.122-2.121L14.3,12.177a.25.25,0,0,1,0-.354l9.263-9.262A1.5,1.5,0,0,0,21.439.44L12.177,9.7a.25.25,0,0,1-.354,0L2.561.44A1.5,1.5,0,0,0,.439,2.561L9.7,11.823a.25.25,0,0,1,0,.354Z"></path>
                      </svg>
                    </span>
                    <span className="flex-shrink-0">Ignore</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        ))
      )}
      <SideModal isOpen={isCorrectionModalOpen} selectedChatItem={selectedChatItem} onClose={closeCorrectionModal} />
    </div>
  )
}

export default QuestionCard
