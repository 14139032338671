import * as types from "./actionTypes"

export const getReviewPlatformAddress = (data: any) => {
  return {
    type: types.GET_REVIEW_PLATFORM_ADDRESSES,
    payload: data,
  }
}
export const storeReviewPlatformAddress = (data: any) => {
  return {
    type: types.STORE_REVIEW_PLATFORM_ADDRESS,
    payload: data,
  }
}
export const updateReviewPlatformAddress = (data: any) => {
  return {
    type: types.UPDATE_REVIEW_PLATFORM_ADDRESS,
    payload: data,
  }
}
export const deleteReviewPlatformAddress = (data: any) => {
  return {
    type: types.DELETE_REVIEW_PLATFORM_ADDRESS,
    payload: data,
  }
}
export const getReviewPlatformAddressSuccess = (data: any) => {
  return {
    type: types.GET_REVIEW_PLATFORM_ADDRESSES_SUCCESS,
    payload: data,
  }
}
