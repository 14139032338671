export const GET_SOURCES = "get_sources"
export const GET_SOURCES_SUCCESS = "get_sources_success"
export const GET_SOURCE_KEYS = "get_source_keys"
export const GET_SOURCE_KEYS_SUCCESS = "get_source_keys_success"
export const GET_POLICY_LIST = "get_policy_list"
export const GET_POLICY_LIST_SUCCESS = "get_policy_list_success"
export const DELETE_POLICY = "delete_policy"
export const POLICY_STATUS = "policy_status"
export const GET_SINGLE_POLICY = "get_single_policy"
export const GET_SINGLE_POLICY_SUCCESS = "get_single_policy_success"
export const CREATE_AUTOMATION = "create_automation"
