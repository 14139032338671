import { produce, current } from "immer"
import { LOGOUT } from "../Auth/actionTypes"
import * as types from "./actionTypes"

type patientQueue = {
  virtuals: any
  virtual: any // agora token object and current/active virtual call object
  virtualTableSubcscription: any
  virtualPeopleSubscription: any
  virtualAppointmentSubscription: any
}

const initialState: patientQueue = {
  virtuals: {},
  virtual: null,
  virtualTableSubcscription: null,
  virtualPeopleSubscription: null,
  virtualAppointmentSubscription: null,
}

const VirtualReducers = produce((state, action) => {
  switch (action.type) {
    case LOGOUT:
      state.virtuals = {}
      state.virtual = null
      state.virtualTableSubcscription = null
      state.virtualPeopleSubscription = null
      state.virtualAppointmentSubscription = null
      break
    case types.GET_PATEINT_QUEUE_SUCCESS:
      if (action.payload.length) {
        Object.values(action.payload).forEach((queueItem: any) => {
          state.virtuals[queueItem.objectId] = queueItem
        })
      }
      break
    case types.GET_SINGLE_PATIENT_QUEUE_SUCCESS:
      state.virtual = action.payload
      break
    case types.UPDATE_LOCAL_QUEUE_ITEM: {
      state.virtuals[action.payload.objectId] = action.payload
      break
    }
    case types.ADD_LOCAL_QUEUE_ITEM: {
      state.virtuals[action.payload.objectId] = action.payload
      break
    }
    case types.DELETE_LOCAL_QUEUE_ITEM: {
      delete state.virtuals[action.payload.objectId]
      break
    }
    case types.SET_VIRTUAL_TABLE_SUBSCRIPTION: {
      state.virtualTableSubcscription = action.payload
      break
    }
    case types.SET_PEOPLE_TABLE_SUBSCRIPTION: {
      state.virtualPeopleSubscription = action.payload
      break
    }
    case types.SET_APPOINTMENT_TABLE_SUBSCRIPTION: {
      state.virtualAppointmentSubscription = action.payload
      break
    }
    default:
      break
  }
}, initialState)

export default VirtualReducers
