import { FC } from "react"
import Spinner from "./Spinner"
import logo from "../assets/images/logo.png"

const Loader: FC = () => {
  return (
    <div className="ml-8 flex items-center justify-center inset-0 overlay-loader z-100 h-screen">
      <div className="relative w-40 h-40 flex items-center justify-center">
        <div className="absolute left-0 top-0 w-full h-full">
          <Spinner name={`h-full w-full`} />
        </div>
        <img src={logo} className="w-24" alt="logo" />
      </div>
    </div>
  )
}
export default Loader
