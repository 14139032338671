import Parse from "parse"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Tab } from "@headlessui/react"
import { toast } from "react-toastify"
import classNames from "classnames"
import { getRandomString } from "../../../utils/helpers"
import { getPateintQueue, updateLocalQueueItem, addLocalQueueItem, deleteLocalQueueItem } from "../../../Store/Virtual/actions"
import VirtualTable from "../VirtualTable/Table"
import { PatientCell, DetailsCell, TeamCell, ChiefConcernCell, SocialHistoryCell, Level4Cell, ActionCell, KickCell, ActiveQueueDetailsCell } from "../../../components/DataDisplay/Table/Cells/WaitingQueueCells"
import Container from "../Modal/Container"
import { PatientQueueItem } from "./WaitingQueue.interface"

const WaitingQueue = (): JSX.Element => {
  const dispatch = useDispatch()

  const WAITING_QUEUE_KEY = "waiting"
  const UPCOMING_QUEUE_KEY = "upcoming"
  const ACTIVE_QUEUE_KEY = "active"

  const [selectedRow, setSelectedRow] = useState<any>()
  //Need these counts later when integrating it for counting rows for waiting,upcoming and active
  // const [waitingQueueCount, setWaitingQueueCount] = useState(0)
  // const [upcomingQueueCount, setUpcomingQueueCount] = useState(0) 
  // const [activeQueueCount, setActiveQueueCount] = useState(0)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [showVirtualPopup, setShowVirtualPopup] = useState(false)
  const [removeSuccess, setRemoveSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  let virtualCallList: any = useSelector((state: any) => Object.values(state?.Virtual.virtuals))

  const waitingQueueCells = [
    { Header: "Patient", Cell: PatientCell, accessor: "patient" },
    { Header: "Details", Cell: DetailsCell, accessor: "appointment" },
    { Header: "Team", Cell: TeamCell, accessor: "appointment.provider" }, // need to identify accessor
    {
      Header: "Chief Concern",
      Cell: ChiefConcernCell,
      accessor: "appointment.chiefConcern",
    },
    {
      Header: "Social History",
      Cell: SocialHistoryCell,
      accessor: "patient.socialHistory",
    },
    { Header: "Level 4", Cell: Level4Cell, accessor: "patient.levelNeeds" },
    {
      Header: "",
      Cell: ActionCell,
      accessor: "action",
      onClick: (value: PatientQueueItem) => handlePrepareVirtual(value),
    },
    {
      Header: "",
      Cell: KickCell,
      accessor: "RemoveAction",
      onClick: (value: PatientQueueItem) => handleRemoveVirtual(value),
    },
  ]

  const activeQueueCells = [
    { Header: "Patient", Cell: PatientCell, accessor: "patient" },
    { Header: "Details", Cell: ActiveQueueDetailsCell, accessor: "appointment" },
    { Header: "Team", Cell: TeamCell, accessor: "appointment.provider" }, // need to identify accessor
    {
      Header: "Chief Concern",
      Cell: ChiefConcernCell,
      accessor: "appointment.chiefConcern",
    },
    {
      Header: "Social History",
      Cell: SocialHistoryCell,
      accessor: "patient.socialHistory",
    },
    { Header: "Level 4", Cell: Level4Cell, accessor: "patient.levelNeeds" },
    {
      Header: "",
      Cell: KickCell,
      accessor: "RemoveAction",
      onClick: (value: PatientQueueItem) => handleRemoveVirtual(value),
    },
  ]

  // initial page load call
  useEffect(() => {
    if (!virtualCallList?.length) {
      setIsLoading(true)
    }
    dispatch(
      getPateintQueue({
        updateQueueItem: (queueItem: any) => dispatch(updateLocalQueueItem(queueItem)),
        addQueueItem: (queueItem: any) => dispatch(addLocalQueueItem(queueItem)),
        deleteQueueItem: (objectId: any) => dispatch(deleteLocalQueueItem({ objectId })),
        setIsLoading: setIsLoading
      })
    )
  }, [])

  useEffect(() => {
    if (removeSuccess) {
      setRemoveSuccess(false)
    }
  }, [removeSuccess])

  const handleRemoveVirtual = (value: any): void => {
    try {
      Parse.Cloud.run("crmVirtualCallKickPatient", { virtualCallId: value?.objectId })
      setRemoveSuccess(true)
    } catch (error) {
      console.log(error)
      setRemoveSuccess(false)
    }
  }

  // fix any
  const handlePrepareVirtual = (value: any): void => {
    setSelectedRow(value)
    setShowVirtualPopup(true)
  }

  return (
    <div className="flex flex-1 flex-col m-2 h-full dark:text-white col-span-6">
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className="flex justify-between items-center mb-5">
          <div className="flex justify-center items-center relative">
            <TabItem>Waiting Queue</TabItem>
            <TabItem>Upcoming</TabItem>
            <TabItem>Active</TabItem>
          </div>
        </Tab.List>

        <div className="mt-5 relative flex flex-1 h-full flex-col">
          <div className={`absolute top-0 left-0 right-0 bottom-0 flex flex-1 h-full flex-col ${selectedIndex === 0 ? "opacity-1 z-20" : "opacity-0 z-10"}`}>
            <VirtualTable tableId="virtual-waiting-table" queueKey={WAITING_QUEUE_KEY} columns={waitingQueueCells} data={virtualCallList} isLoading={isLoading} />
          </div>
          <div className={`absolute top-0 left-0 right-0 bottom-0 flex flex-1 h-full flex-col ${selectedIndex === 1 ? "opacity-1 z-20" : "opacity-0 z-10"}`}>
            <VirtualTable tableId="virtual-upcoming-table" queueKey={UPCOMING_QUEUE_KEY} columns={waitingQueueCells} data={virtualCallList} />
          </div>
          <div className={`absolute top-0 left-0 right-0 bottom-0 flex flex-1 h-full flex-col ${selectedIndex === 2 ? "opacity-1 z-20" : "opacity-0 z-10"}`}>
            <VirtualTable tableId="virtual-active-table" queueKey={ACTIVE_QUEUE_KEY} columns={activeQueueCells} data={virtualCallList} />
          </div>
        </div>
      </Tab.Group>
      {selectedRow && <Container open={showVirtualPopup} setOpen={(): void => setShowVirtualPopup((prev) => !prev)} virtualId={selectedRow?.objectId} patient={selectedRow?.patient} appointment={selectedRow?.appointment} />}
    </div>
  )
}

export default WaitingQueue

const TabItem = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return <Tab className={({ selected }): string => classNames(selected ? "border-green-600 text-green-600" : "text-gray-500 hover:text-gray-700 hover:border-gray-400", "whitespace-nowrap py-4 px-5 border-b-2 font-medium text-sm outline-none")}>{children}</Tab>
}
