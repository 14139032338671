export const SET_DATE = "set_date"
export const NEXT_DATE = "next_date"
export const PREV_DATE = "prev_date"
export const SET_APPTS = "set_appts"
export const FETCH_APPTS = "fetch_appts"
export const SET_PROVIDERS = "set_providers"
export const FETCH_PROVIDERS = "fetch_providers"
export const SET_OPERATORIES = "set_operatories"
export const FETCH_OPERATORIES = "fetch_operatories"
export const HANDLE_APPTS_SUBSCRIPTION = "handle_appts_subscription"
export const ADD_APPT = "add_appt"
export const DELETE_APPT = "delete_appt"
export const UPDATE_PATIENT_IN_APPTS = "update_patient_in_appts"
export const UPDATE_LOCAL_APPOINTMENT = "update_local_appointment"
export const SET_IS_FETCHING_APPOINTMENTS = "set_is_fetching_appointments"
export const SET_IS_FETCHING_PROVIDERS = "set_is_fetching_providers"
export const SET_IS_FETCHING_OPERATORIES = "set_is_fetching_operatories"

export const SET_IS_FETCHED_APPOINTMENTS = "set_is_fetched_appointments"
export const SET_IS_FETCHED_PROVIDERS = "set_is_fetched_providers"
export const SET_IS_FETCHED_OPERATORIES = "set_is_fetched_operatories"
export const SCHEDULE_PAGE_LOCATION_AND_DATE = "schedule_page_location_and_date"
