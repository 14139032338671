import { Dialog, Transition } from "@headlessui/react"
import { ExclamationIcon } from "@heroicons/react/outline"
import moment from "moment"
import { Fragment, useRef } from "react"
import { useSelector } from "react-redux"
import Button from "../../../../components/Inputs/Button"
const LocationAndPendingAppointmentsConfirmation = ({ isWelcomeCenterLocation, setIsWelcomeCenterLocation, setSelectedPatientOption, selectedPatientOption, setPendingAppointment, pendingAppointment, setOpenConfirmLocation, openConfirmLocation, handleAgree }: any): JSX.Element => {
  const cancelButtonRef = useRef<HTMLDivElement>(null)
  const refDiv = useRef<HTMLDivElement>(null)
  const selectedLocation = useSelector((state: any) => state?.Appointment?.location)

  const handleCancel = () => {
    setOpenConfirmLocation(false)
    setTimeout(() => {
      setSelectedPatientOption(null)
      setPendingAppointment(null)
      setIsWelcomeCenterLocation(null)
    }, 500)
  }

  return (
    <Transition.Root show={openConfirmLocation} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-y-auto" onClose={handleCancel} initialFocus={cancelButtonRef.current ? cancelButtonRef : refDiv} style={{ zIndex: 100 }}>
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" ref={refDiv}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity overlay-dialog" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white dark:bg-black-700 rounded-lg space-y-3 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full p-10">
              <div className="sm:flex sm:flex-col">
                <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 lg:h-14 lg:w-14 rounded-full bg-white-100 shadow-xl border border-1 border-gray-100 dark:bg-black-800 mx-auto ">
                  <ExclamationIcon className="h-6 w-6 text-red-600 dark:text-black-200" aria-hidden="true" />
                </div>
                <div className="text-center mt-5 sm:text-left">
                  <Dialog.Title as="h2" className="text-xl text-center leading-6 font-medium text-gray-900 dark:text-white">
                    Create Appointment
                  </Dialog.Title>
                  <div className="my-4 text-md text-gray-500 dark:text-gray-200 px-4">
                    {pendingAppointment &&
                      `${pendingAppointment?.get("patient")?.get("firstName")} ${pendingAppointment?.get("patient")?.get("lastName")} 
                        has a pending appointment at ${pendingAppointment?.get("location")?.get("name")} at ${moment.utc(pendingAppointment?.get("start")).tz(selectedLocation.timeZone).format("hh:mm a Do MMM YYYY")}. `}
                    {pendingAppointment && isWelcomeCenterLocation === false && <hr className="w-36 mx-auto my-4" />}
                    {!selectedPatientOption?.hasCompletedAppointment && isWelcomeCenterLocation === false && `${selectedPatientOption?.firstName} ${selectedPatientOption?.lastName} is a New patient and ${selectedLocation?.name} is not a Welcome Center. `}
                    Do you wish to continue ?
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse justify-center">
                <div>
                  <Button className="w-32 ml-3 border-green-500" variant="outlined" onClick={handleAgree} /* loading={updateBtnLoading} */>
                    Agree
                  </Button>
                </div>
                <div>
                  <Button className="w-32 border-red-500" variant="outlined" onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default LocationAndPendingAppointmentsConfirmation
