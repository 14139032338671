import * as Parse from "parse"
import { fork, put, all, takeLatest } from "redux-saga/effects"
import * as types from "./actionTypes"
import { sagaErrorHandler } from "../../utils/SagaErrorHandler"
import { getPateintQueueSuccess, getSinglePatientQueueSuccess } from "./actions"
import moment from "moment"

function* getPatientQueueRequest({ payload }: any): any {
  const { updateQueueItem, addQueueItem, deleteQueueItem, setIsLoading } = payload
  try {
    const start = moment.tz("America/Los_Angeles").startOf("day") // Start of day
    const end = moment.tz("America/Los_Angeles").endOf("day") // End of day
    const virtualCallQuery = new Parse.Query("VirtualCall").greaterThanOrEqualTo('updatedAt', start.utc().format()).lessThan('updatedAt', end.utc().format()).include(["patient", "appointment", "appointment.operatory", "appointment.location", "appointment.provider"]).limit(1000)
    const queue = yield virtualCallQuery.find()
    yield put(getPateintQueueSuccess(queue?.map((virtualCall: any) => virtualCall.toJSON())))
    setIsLoading(false)

    const subscription = yield virtualCallQuery.subscribe()

    subscription.on("create", async (virtualQueueObject: any) => {
      const dynamicCreateObject = await virtualQueueObject.fetchWithInclude(["appointment", "appointment.provider", "appointment.operatory", "appointment.location", "patient"])
      addQueueItem(dynamicCreateObject?.toJSON())
    })

    subscription.on("enter", async (virtualQueueObject: any) => {
      const dynamicCreateObject = await virtualQueueObject.fetchWithInclude(["appointment", "appointment.provider", "appointment.operatory", "appointment.location", "patient"])
      addQueueItem(dynamicCreateObject?.toJSON())
    })

    subscription.on("update", async (virtualQueueObject: any) => {
      const dynamicUpdateObject = await virtualQueueObject.fetchWithInclude(["appointment", "appointment.provider", "appointment.operatory", "appointment.location", "patient"])
      updateQueueItem(dynamicUpdateObject?.toJSON())
    })

    subscription.on("delete", async (virtualQueueObject: any) => {
      deleteQueueItem(virtualQueueObject?.toJSON()?.objectId)
    })

    subscription.on("leave", async (virtualQueueObject: any) => {
      deleteQueueItem(virtualQueueObject?.toJSON()?.objectId)
    })
  } catch (error: any) {
    setIsLoading(false)
    yield sagaErrorHandler(error)
  }
}

function* getSinglePatientQueueRequest({ payload }: any): any {
  const { virtualId, navigate, setStartSpinner } = payload
  try {
    setStartSpinner(true)
    const response = yield Parse.Cloud.run("crmVirtualCallJoin", { virtualCallId: virtualId })
    const virtualCall = response?.virtualCall?.toJSON()
    yield put(getSinglePatientQueueSuccess({
      agoraUserAccountId: response.agoraUserAccountId,
      agoraToken: response.agoraToken,
      agoraScreenSharingUserAccountId: response.agoraScreenSharingUserAccountId,
      agoraScreenSharingToken: response.agoraScreenSharingToken,
      virtualCall: virtualCall
    }))
    navigate(`/virtuals/${virtualId}`)
    setStartSpinner(false)
  } catch (error: any) {
    yield sagaErrorHandler(error)
  }
}

// fix any
export function* watchGetPatientQueue(): any {
  yield takeLatest(types.GET_PATEINT_QUEUE, getPatientQueueRequest)
}
export function* watchGetSinglePatientQueue(): any {
  yield takeLatest(types.GET_SINGLE_PATIENT_QUEUE, getSinglePatientQueueRequest)
}
// fix any
export default function* virtualSaga(): any {
  yield all([fork(watchGetPatientQueue), fork(watchGetSinglePatientQueue)])
}
