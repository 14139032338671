import Prompt from "./prompt"

const Chat = () => {
  return (
    <div className="flex bg-slate-200">
      <Prompt />
    </div>
  )
}

export default Chat
