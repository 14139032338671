import Parse from "parse"
import { useState, useEffect } from "react"
import { createAutomation, getSinglePolicySuccess } from "../../Store/Automation/actions"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { ArrowLeftIcon } from "@heroicons/react/outline"
import Button from "../Inputs/Button"
import CriteriaSection from "./CriteriaSection"
import ActionSection from "./ActionSection"
import TextField from "../Inputs/TextField"

const CreatePolicy = (props: any): JSX.Element => {
  const { closeModal, setPage, selectedPolicyId, setSelectedPolicyId } = props

  const dispatch = useDispatch()

  const [singlePolicy, setSinglePolicy] = useState<any>()
  const [sourceValue, setSourceValue] = useState<any>(singlePolicy?.source ? singlePolicy?.source : "")
  const [criteriaValues, setCriteriaValues] = useState<any>(singlePolicy?.criterias ?? [{ key: "", operator: "", value: "", priority: "" }])
  const [actionValues, setActionValues] = useState<any>(singlePolicy?.actions ?? [{ actionName: "", params: [] }])
  const [policyName, setPolicyName] = useState<any>(1)
  const [policyNameValue, setPolicyNameValue] = useState<any>(singlePolicy?.policyName ?? "")
  const [isTestCase, setIsTestCase] = useState<any>(false)

  const getSinglePolicy = async () => {
    try {
      let data = await Parse.Cloud.run("getAutomationPolicyData", {
        policyId: selectedPolicyId,
      })
      setSinglePolicy(data)
    } catch (error: any) {
      toast.error(JSON.stringify(error.message))
    }
  }

  useEffect(() => {
    setSourceValue(singlePolicy?.source ?? "")
    setCriteriaValues(singlePolicy?.criterias ?? [{ key: "", operator: "", value: "", priority: "" }])
    setActionValues(singlePolicy?.actions ?? [{ actionName: "", params: [] }])
    setPolicyNameValue(singlePolicy?.policyName ?? "")
  }, [singlePolicy])

  useEffect(() => {
    if (selectedPolicyId) {
      getSinglePolicy()
    }
    console.log("selectedPolicyId", selectedPolicyId)
  }, [])

  const handleSubmit = async () => {
    let sourceValidation = criteriaValues?.map((obj: any) => {
      if (obj.key === "" || obj.operator === "") {
        return false
      } else if (obj.operator.getInput === true && obj.value === "") {
        return false
      } else {
        return true
      }
    })
    let actionValidation = actionValues?.map((obj: any) => {
      if (obj.actionName === "") {
        return false
      } else if (obj.params.map((obj1: any) => obj1.value === "").filter((obj2: any) => obj2).length > 0) {
        return false
      } else {
        return true
      }
    })

    if (sourceValidation.filter((val: any) => !val).length > 0) {
      toast.error("Please add all source fields")
    } else {
      if (actionValidation.filter((val: any) => !val).length > 0) {
        toast.error("Please add all action fields")
      } else {
        if (isTestCase) {
          handleSubmitPolicyValue()
        } else {
          setPolicyName(2)
        }
      }
    }
  }
  const handleSubmitPolicyValue = () => {
    dispatch(
      createAutomation({
        sourceValue: sourceValue,
        policyNameValue: policyNameValue,
        isTestCase: isTestCase,
        actionValues: actionValues,
        criteriaValues: criteriaValues,
        setPage: setPage,
        selectedPolicyId: selectedPolicyId,
        setSelectedPolicyId: setSelectedPolicyId,
        setSourceValue: setSourceValue,
        setCriteriaValues: setCriteriaValues,
        setActionValues: setActionValues,
      })
    )
  }

  const clearStates = () => {
    setActionValues([{ actionName: "", params: [] }])
    dispatch(getSinglePolicySuccess(null))
    setCriteriaValues([{ key: "", operator: "", value: "", priority: "" }])
    setSourceValue("")
    setSelectedPolicyId("")
  }

  return (
    <form>
      <div className="flex-1 flex flex-col-reverse md:flex-row h-full relative">
        {policyName === 1 ? (
          <>
            {selectedPolicyId ? (
              <div className="absolute right-5 text-mobile-gray-600 flex" style={{ top: "-32px" }}>
                <button className="dark:text-white text-red-500 text-base" type="button" onClick={() => clearStates()}>
                  Clear all
                </button>
              </div>
            ) : null}
            <CriteriaSection sourceValue={sourceValue} setSourceValue={setSourceValue} criteriaValues={criteriaValues} setCriteriaValues={setCriteriaValues} singlePolicy={singlePolicy} />
            <ActionSection actionValues={actionValues} setActionValues={setActionValues} sourceValue={sourceValue} singlePolicy={singlePolicy} />
          </>
        ) : (
          <div className="flex flex-col automationHeight flex-1 w-full px-4 pt-4 pb-5 overflow-auto md:w-1/2 md:px-8 lg:px-8 lg:pt-5 2xl:pr-8 2xl:pl-8 mb-8 border-r border-gray-300">
            <div className="p-4 mb-5 ml-auto mr-auto mt-10" style={{ boxShadow: "rgb(0 0 0 / 10%) 1px 1px 8px 5px", width: "60%" }}>
              <p className="mb-3">Policy name</p>
              <TextField value={policyNameValue} placeholder="Policy name" onChange={(e: any) => setPolicyNameValue(e.target.value)} />
              <button type="button" className="text-sm mt-3 flex justify-end ml-auto" onClick={() => setPolicyName(1)}>
                <ArrowLeftIcon height="14px" width="14px" className="mr-2" style={{ marginTop: "3px" }} />
                Back
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="p-4 border-t border-gray-300 flex">
        {/* <div className="mt-2">
          {policyName === 1 ? (
            <Button
              type="submit"
              onClick={() => {
                setIsTestCase(true)
              }}
              disabled={sourceValue ? false : true}
              className="inline-flex justify-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
            >
              Policy test run
            </Button>
          ) : null}
        </div> */}
        <div className="ml-auto">
          <button
            type="button"
            className="mr-2 inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
            onClick={() => {
              closeModal(false)
              clearStates()
            }}
          >
            Close
          </button>
          {policyName === 1 ? (
            <Button
              type="button"
              onClick={() => handleSubmit()}
              disabled={sourceValue ? false : true}
              className="inline-flex justify-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
            >
              Next
            </Button>
          ) : (
            <Button
              type="button"
              onClick={() => handleSubmitPolicyValue()}
              className="inline-flex justify-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
            >
              {selectedPolicyId ? "Update" : "Create"}
            </Button>
          )}
        </div>
      </div>
    </form>
  )
}
export default CreatePolicy
