import * as Parse from "parse"
import React, { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router"

import { ClientConfig, IAgoraRTCRemoteUser, ICameraVideoTrack, IMicrophoneAudioTrack, AgoraVideoPlayer, createClient, createScreenVideoTrack, createMicrophoneAndCameraTracks, IRemoteAudioTrack, IRemoteVideoTrack, ILocalVideoTrack, ILocalAudioTrack } from "agora-rtc-react"

// ICONS
import { MicrophoneIcon, VideoCameraIcon, DesktopComputerIcon } from "@heroicons/react/solid"
import Avatar from "../../../components/DataDisplay/Avatar"
import Button from "../../../components/Inputs/Button"
import Icon from "../../../components/DataDisplay/Icon"
import IconButton from "../../../components/Inputs/IconButton"
import Loader from "../../../components/Loader"

const config: ClientConfig = {
  mode: "rtc",
  codec: "vp8",
}

// Create screen client
const useScreenVideoClient = createClient({
  mode: "rtc",
  codec: "vp8",
})

// Screenshare props
interface Props {
  screenshareConfig: ScreenShareConfig
  onScreenSharingStopped(): void
  setIsScreenSharing: any
  isScreenSharing: boolean
}

// Setting the config for screenshare
interface ScreenShareConfig {
  appId: string
  channelName: string
  token: string | null
  uid: string | number | null | undefined
}

const appId: string = process.env.REACT_APP_AGORA_APP_ID!

const VirtualPlayer = ({ currentVirtualCallData }: any): JSX.Element => {
  const [inCall, setInCall] = useState(true)
  const [isScreenSharing, setIsScreenSharing] = useState<boolean>(false)
  const [channelName, setChannelName] = useState(currentVirtualCallData.virtualCall.channelName)

  const screenshareConfig: ScreenShareConfig = {
    appId,
    channelName: currentVirtualCallData.virtualCall.channelName,
    token: currentVirtualCallData.agoraScreenSharingToken,
    uid: `${Parse.User.current()?.id}-screen`,
  }
  return (
    <div>
      {inCall ? (
        <>
          <VideoCall isScreenSharing={isScreenSharing} setIsScreenSharing={setIsScreenSharing} currentVirtualCallData={currentVirtualCallData} setInCall={setInCall} channelName={channelName} />
          {isScreenSharing && <ScreenSharing isScreenSharing={isScreenSharing} setIsScreenSharing={setIsScreenSharing} screenshareConfig={screenshareConfig} onScreenSharingStopped={() => console.log("Screensharing stopped.")} />}
        </>
      ) : (
        <>
        </>
      )}
    </div>
  )
}

const useClient = createClient(config)
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks()

const VideoCall = (props: { setInCall: React.Dispatch<React.SetStateAction<boolean>>; channelName: string; currentVirtualCallData: any; isScreenSharing: boolean; setIsScreenSharing: any }) => {
  const { setInCall, channelName, currentVirtualCallData, isScreenSharing, setIsScreenSharing } = props
  let navigate = useNavigate()

  const [users, setUsers] = useState<IAgoraRTCRemoteUser[]>([])

  const [hasJoined, setHasJoined] = useState<boolean>(false)
  const [hasRemoteJoined, setHasRemoteJoined] = useState<boolean>(false)

  const [isAudioMuted, setIsAudioMuted] = useState<boolean>(false)
  const [isVideoMuted, setIsVideoMuted] = useState<boolean>(false)
  const [isRemoteAudioMuted, setIsRemoteAudioMuted] = useState<boolean>(false)
  const [isRemoteVideoMuted, setIsRemoteVideoMuted] = useState<boolean>(false)

  // using the hook to get access to the client object
  const client = useClient()

  // ready is a state variable, which returns true when the local tracks are initialized, untill then tracks variable is null
  const { ready, tracks } = useMicrophoneAndCameraTracks()

  const [localAudioTrack, setLocalAudioTrack] = useState<IMicrophoneAudioTrack>()
  const [localVideoTrack, setLocalVideoTrack] = useState<ICameraVideoTrack>()
  const [remoteAudioTrack, setRemoteAudioTrack] = useState<IRemoteAudioTrack | undefined>()
  const [remoteVideoTrack, setRemoteVideoTrack] = useState<IRemoteVideoTrack | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    // function to initialise the SDK
    let init = async (name: string) => {
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType)
        console.log("subscribe success")

        setHasRemoteJoined(true)

        // VIDEO
        if (mediaType === "video") {
          setRemoteVideoTrack(user.videoTrack)
          setUsers((prevUsers) => {
            return [...prevUsers, user]
          })
        }

        // AUDIO
        if (mediaType === "audio") {
          setRemoteAudioTrack(user.audioTrack)
          user.audioTrack?.play()
        }
      })

      client.on("user-unpublished", (user, type) => {
        console.log("unpublished", user, type)

        setHasRemoteJoined(false)

        if (type === "audio") {
          user.audioTrack?.stop()
        }
        if (type === "video") {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid)
          })
        }
      })

      client.on("user-left", (user) => {
        console.log("leaving", user)
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => {
            console.log(User.uid !== user.uid, User.uid, user.uid)
            return User.uid !== user.uid
          })
        })
      })

      console.log(appId, name, currentVirtualCallData.agoraToken, Parse.User.current()?.id, "JOIN-A")

      await client.join(appId, name, currentVirtualCallData.agoraToken, Parse.User.current()?.id)
      if (tracks) await client.publish([tracks[0], tracks[1]])
      setHasJoined(true)
    }

    if (ready && tracks) {
      console.log("init ready")
      setLocalAudioTrack(tracks[0])
      setLocalVideoTrack(tracks[1])
      init(channelName)
    }
  }, [channelName, client, ready, tracks])

  useEffect(() => {
    if (isScreenSharing) {
      console.log("ENABLE SCREEN SHARING")
    } else {
      console.log("DISABLE SCREEN SHARING")
    }
  }, [isScreenSharing])

  const mute = async (type: "audio" | "video") => {
    if (type === "audio") {
      localAudioTrack?.setMuted(!isAudioMuted)
      setIsAudioMuted(!isAudioMuted)
    } else if (type === "video") {
      localVideoTrack?.setMuted(!isVideoMuted)
      setIsVideoMuted(!isVideoMuted)
    }
  }
  useEffect(() => {
    const handleTabClose = (event: any) => {
      event.preventDefault()

      Parse.Cloud.run("crmVirtualCallLeave", {
        virtualCallId: currentVirtualCallData.virtualCall.objectId,
      })
      return (event.returnValue = 'Please Join Again')
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  const leaveChannel = async () => {
    setIsLoading(true)
    await client.leave()
    client.removeAllListeners()
    // we close the tracks to perform cleanup
    tracks && tracks[0].close()
    tracks && tracks[1].close()
    setHasJoined(false)
    setIsScreenSharing(false)
    setInCall(false)
    localAudioTrack?.close()
    localVideoTrack?.close()
    await Parse.Cloud.run("crmVirtualCallLeave", {
      virtualCallId: currentVirtualCallData.virtualCall.objectId,
    })
    navigate("/virtuals")
    setIsLoading(false)
  }

  return (
    <div>
      {hasJoined && tracks ? (
        <Videos
          setIsScreenSharing={setIsScreenSharing}
          mute={mute}
          leaveChannel={leaveChannel}
          isScreenSharing={isScreenSharing}
          isAudioMuted={isAudioMuted}
          isVideoMuted={isVideoMuted}
          hasJoined={hasJoined}
          hasRemoteJoined={hasRemoteJoined}
          isRemoteAudioMuted={isRemoteAudioMuted}
          isRemoteVideoMuted={isRemoteVideoMuted}
          users={users}
          cameraVideoTrack={localVideoTrack}
          currentVirtualCallData={currentVirtualCallData}
          isLoading={isLoading}
        />
      ) : <Loader />
      }
    </div>
  )
}

const Videos = (props: {
  leaveChannel: any
  setIsScreenSharing: any
  mute: any
  users: IAgoraRTCRemoteUser[]
  cameraVideoTrack?: ICameraVideoTrack
  isAudioMuted: boolean
  isVideoMuted: boolean
  isScreenSharing: boolean
  hasJoined: boolean
  hasRemoteJoined: boolean
  isRemoteVideoMuted: boolean
  isRemoteAudioMuted: boolean
  currentVirtualCallData: any
  isLoading: boolean
}) => {
  const { users, cameraVideoTrack, setIsScreenSharing, mute, leaveChannel, isAudioMuted, isVideoMuted, isScreenSharing, hasJoined, hasRemoteJoined, isRemoteVideoMuted, isRemoteAudioMuted, currentVirtualCallData, isLoading } = props

  const currentUser = Parse.User.current()

  return (
    <div>
      <div className="relative w-full h-full dark:bg-black-700 bg-green-50">
        {/* {notify && (
        <Notification
          message='Patient has ended the virtual call.'
          open
          onClose={() => setNotify(false)}
          variant='error'
          title='Patient left'
        />
      )} */}

        <div className="w-full h-full flex justify-center items-center h-16 xl:min-h-screen" style={{ minHeight: "850px" }}>
          <div className="px-2 py-14 sm:py-18 sm:p-8 2xl:p-10">
            <div id="remote-stream" className="absolute overflow-hidden top-0 left-0 h-full w-full">
              {users.length > 0 && users.map((user) => (user.videoTrack && <AgoraVideoPlayer style={{ height: "100%", width: "100%" }} className="main-video" videoTrack={user.videoTrack} key={user.uid} />))}
            </div>

            {isRemoteVideoMuted && (
              <div className="absolute top-0 left-0 h-full w-full flex flex-col justify-center items-center text-red-400 bg-black-700">
                <Icon icon={VideoCameraIcon} fontSize="text-9xl" />
                <span className="text-white">Video is disabled</span>
              </div>
            )}

            {isRemoteAudioMuted && (
              <div className="absolute bottom-2 right-12 text-red-400">
                <Icon icon={MicrophoneIcon} fontSize="text-3xl" />
              </div>
            )}

            {/* <div className='absolute right-3 bottom-3'>
            {remoteNetworkStats && (
              <WifiIndicator
                style={{ height: '30px', marginBottom: '2px' }}
                strength={
                  signalStrenghtMapping[
                    remoteNetworkStats.downlinkNetworkQuality
                  ]
                }
              />
            )}
          </div> */}

            {!hasRemoteJoined && (
              <div className="flex justify-center items-center">
                <Avatar image={null} firstName={"P"} size="xlarge" bg="bg-gray-500 dark:bg-black-800" text="text-5xl text-white dark:text-white" />
              </div>
            )}

            {hasJoined && (
              <div className="flex justify-center items-center space-x-6 2xl:space-x-12 absolute left-0 right-0 bottom-3 md:bottom-8 lg:bottom-8 xl:bottom-8 2xl:bottom-8">
                <IconButton onClick={() => mute("audio")} size="2xl" className={`border border-transparent rounded-full shadow-sm dark:text-black-600 dark:bg-black-900 bg-gray-300 ${isAudioMuted ? "text-red-700 dark:text-red-500" : "text-gray-700 dark:hover:text-white"} focus:outline-none `}>
                  <Icon icon={MicrophoneIcon} fontSize="text-2xl" />
                </IconButton>
                <IconButton onClick={() => mute("video")} size="2xl" className={`border border-transparent rounded-full shadow-sm dark:text-black-600 dark:bg-black-900 bg-gray-300 ${isVideoMuted ? "text-red-700 dark:text-red-500" : "text-gray-700 dark:hover:text-white"} focus:outline-none`}>
                  <Icon icon={VideoCameraIcon} fontSize="text-2xl" />
                </IconButton>
                <IconButton
                  onClick={() => setIsScreenSharing(!isScreenSharing)}
                  size="2xl"
                  className={`border border-transparent rounded-full shadow-sm dark:text-black-600 dark:bg-black-900 bg-gray-300 ${isScreenSharing ? "text-red-700 dark:text-red-500" : "text-gray-700 dark:hover:text-white"} focus:outline-none`}
                >
                  <Icon icon={DesktopComputerIcon} fontSize="text-2xl" />
                </IconButton>
              </div>
            )}
          </div>

          <div className="absolute right-6 top-6 w-36 h-24 md:w-40 lg:w-48 2xl:w-56 md:h-28 lg:h-32 2xl:h-36 inline-flex justify-center items-center py-3 dark:bg-black-900 bg-gray-500 rounded-lg 2xl:right-8 2xl:top-8 2xl:py-5 2xl:rounded-xl">
            <div id="local-stream" className="absolute top-0 right-0 w-full h-full">
              {cameraVideoTrack && <AgoraVideoPlayer style={{ height: "100%", width: "100%" }} className="topRight-video" videoTrack={cameraVideoTrack} />}
            </div>
            {isVideoMuted && (
              <div className="absolute top-0 left-0 h-full w-full flex flex-col justify-center items-center text-red-400 bg-black-900 rounded-lg">
                <Icon icon={VideoCameraIcon} fontSize="text-3xl" />
              </div>
            )}

            {!hasJoined && (
              <div className="flex justify-center items-center w-full h-full">
                <Avatar image={null} firstName={currentUser?.get("username")} size="small" bg="bg-gray-100 dark:bg-black-700" text="text-5xl text-black dark:text-white" />
              </div>
            )}

            {/* <div className='absolute right-2 bottom-1'>
            {localNetworkStats && (
              <WifiIndicator
                style={{ height: '15px', marginBottom: '2px' }}
                strength={
                  signalStrenghtMapping[
                    localNetworkStats.downlinkNetworkQuality
                  ]
                }
              />
            )}
          </div> */}
          </div>

          {hasJoined && (
            <div className="absolute top-8 left-8 md:left-auto">
              <Button variant="outlined" color="indigo" className="rounded-full bg-gray-300 text-gray-700 dark:text-white" onClick={() => leaveChannel()} loading={isLoading}>
                End Virtual
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const getScreenSharingVideoTrack = (tracks: ILocalVideoTrack | [ILocalVideoTrack, ILocalAudioTrack]) => {
  if (Array.isArray(tracks)) {
    return tracks[0]
  } else {
    return tracks
  }
}

// ScreenSharing component
const ScreenSharing = (props: Props) => {
  const useScreenVideoTrack = createScreenVideoTrack({
    encoderConfig: '720p_2',
    optimizationMode: 'detail',
  })
  // Using the screen client hook
  const screenVideoClient = useScreenVideoClient()
  const { ready, tracks, error } = useScreenVideoTrack()
  const tracksRef = useRef(tracks)
  const [toggleState, setToggleState] = useState<boolean>(false)

  const { onScreenSharingStopped, isScreenSharing, setIsScreenSharing } = props

  useEffect(() => {
    tracksRef.current = tracks
  }, [tracks])

  useEffect(() => {
    if (error !== null) {
      console.error("An error occurred while sharing the screen.", error)
      onScreenSharingStopped()
    }
  }, [error, onScreenSharingStopped])

  useEffect(() => {
    const init = async (channelName: string) => {
      if (!props.screenshareConfig) return
      try {
        await screenVideoClient.join(props.screenshareConfig.appId, channelName, props.screenshareConfig.token, props.screenshareConfig.uid)
        const videoTrack = getScreenSharingVideoTrack(tracks)
        if (tracks) await screenVideoClient.publish(videoTrack)
      } catch (e) {
        console.error(e)
      }
    }

    if (props.screenshareConfig && ready && tracks) {
      init(props.screenshareConfig.channelName)
    }
  }, [props.screenshareConfig, screenVideoClient, ready, tracks])

  useEffect(() => {
    const videoTrack = getScreenSharingVideoTrack(tracks)
    if (videoTrack) {
      videoTrack.on("track-ended", () => {
        onScreenSharingStopped()
        stopScreenshare()
        setToggleState(false)
      })
    }
    // Stop and remove all tracks for screenshared client
    function stopScreenshare() {
      if (tracksRef.current) {
        const track = getScreenSharingVideoTrack(tracksRef.current)
        track.close()
        track.removeAllListeners()
        setIsScreenSharing(false)
      }
      (async () => {
        await screenVideoClient.leave()
        screenVideoClient.removeAllListeners()
      })()
    }
  }, [onScreenSharingStopped, tracks, screenVideoClient])

  useEffect(() => {
    return () => {
      if (tracksRef.current) {
        const track = getScreenSharingVideoTrack(tracksRef.current)
        track.close()
        track.removeAllListeners()
      }
      (async () => {
        await screenVideoClient.leave()
        screenVideoClient.removeAllListeners()
      })()
    }
  }, [tracks, screenVideoClient])

  if (!ready) {
    return null
  }

  // Toggle tracks for screenshared client
  if (toggleState) {
    // If on then turn it off
    if (tracksRef.current) {
      const track = getScreenSharingVideoTrack(tracksRef.current)
      track.close()
      track.removeAllListeners()
    }
    (async () => {
      await screenVideoClient.leave()
      screenVideoClient.removeAllListeners()
    })()
  } else {
    // If off then turn it on
    (async () => {
      await screenVideoClient.join(props.screenshareConfig.appId, props.screenshareConfig.channelName, props.screenshareConfig.token, props.screenshareConfig.uid)
      // Using the screen client hook
      if (!null) {
        const videoTrack = getScreenSharingVideoTrack(tracks)
        if (tracks) await screenVideoClient.publish(videoTrack)
      }
    })()
  }

  const videoTrack = getScreenSharingVideoTrack(tracks)

  return (
    <>
      {isScreenSharing && <div className="absolute left-6 top-6 w-36 h-24 md:w-40 lg:w-48 2xl:w-56 md:h-28 lg:h-32 2xl:h-36 inline-flex justify-center items-center py-3 dark:bg-black-900 bg-gray-500 rounded-lg 2xl:left-8 2xl:top-8 2xl:py-5 2xl:rounded-xl">
        <div className="relative left-0 top-0 w-full h-full">
          <AgoraVideoPlayer className="screenShare-video-topLeft" videoTrack={videoTrack} style={{ height: "100%" }} />
        </div>
      </div>}
    </>
  )
}
export default VirtualPlayer
