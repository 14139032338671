export const GET_LOCATION = "get_location"
export const GET_LOCATION_SUCCESS = "get_location_success"
export const GET_PROVIDER = "get_provider"
export const GET_PROVIDER_SUCCESS = "get_provider_success"
export const GET_OPERATORY = "get_operatory"
export const GET_OPERATORY_SUCCESS = "get_operatory_success"
export const GET_APPOINTMENT = "get_appointment"
export const GET_APPOINTMENT_SUCCESS = "get_appointment_success"
export const ADD_APPOINTMENT = "add_appointment"
export const ADD_APPOINTMENT_SUCCESS = "add_appointment_success"
export const UPDATE_APPOINTMENT = "update_appointment"
export const DELETE_APPOINTMENT = "delete_appointment"
export const UPDATE_LOCAL_APPOINTMENT = "update_local_appointment"
export const ADD_LOCAL_APPOINTMENT = "add_local_appointment"
export const DELETE_LOCAL_APPOINTMENT = "delete_local_appointment"
export const APPOINTMENT_SUBSCRIPTION_SUCCESS = "appointment_subscription_success"
// export const UPDATE_PATIENT_IN_APPTS = "update_patient_in_appts"
export const SET_LOCATION = "set_location"
