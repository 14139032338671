import React, { useEffect, useState } from "react"
import Assets from "../../constants/AssetsConstants"
import { toast } from "react-toastify"
import * as Parse from "parse"

interface Prompt {
  question: string
  answer: string
}
const Chat: React.FC = () => {
  const [inputText, setInputText] = useState<string>("")
  const [chatData, setChatData] = useState<Prompt[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const apiUrl = process.env.REACT_APP_API_URL_GPT || ""
  const maxTokens = 1000
  const authorImage = "https://dummyimage.com/256x256/9E9E9E/ffffff&text=U"

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setInputText(e.target.value)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!inputText.trim()) {
      toast.error("Input is empty. Please provide a question.")
      return
    }

    setLoading(true)
    try {
      const response = await fetch(`${apiUrl}/api/chat-completion?max_tokens=${maxTokens}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: inputText,
        }),
      })

      if (!response.ok) {
        throw new Error(`Failed to fetch data from API. Status: ${response.status}`)
      }

      const data = await response.json()
      const answerText = data.html_response || "No response from GPT API. Please try again"
      const createChatResponse = await fetch(`${process.env.REACT_APP_SERVER_URL_CHAT}/api/chats/createChat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: Parse.User.current()?.id,
          question: inputText,
          answer: answerText,
        }),
      })

      if (!createChatResponse.ok) {
        throw new Error(`Failed to create chat. Status: ${createChatResponse.status}`)
      }
      await createChatResponse.json()

      setChatData((prevChatData: Prompt[]) => [
        ...prevChatData,
        {
          userId: Parse.User.current()?.id,
          question: inputText,
          answer: answerText,
          category: "pending",
        },
      ])

      setInputText("")
    } catch (error: any) {
      toast.error("Error fetching data from GPT API or creating chat in the backend")
    } finally {
      setLoading(false)
    }
  }

  const handleEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault()
      handleSubmit(e)
    }
  }

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const userId = Parse.User.current()?.id
        if (userId) {
          const response = await fetch(`${process.env.REACT_APP_SERVER_URL_CHAT}/api/chats/getChatsByUserId/${userId}`)
          if (!response.ok) {
            throw new Error(`Failed to fetch chats. Status: ${response.status}`)
          }
          const chats = await response.json()
          setChatData(chats)
        }
      } catch (error: any) {
        console.error("Error fetching chats:", error)
      }
    }

    fetchChats()
  }, [])

  return (
    <div className="flex h-[90vh] w-full flex-col">
      <div className="flex-1 overflow-y-auto bg-slate-200 p-4 text-sm leading-6 text-slate-900 sm:text-base sm:leading-7">
        {chatData &&
          chatData.map((prompt, index) => (
            <div key={index} className="mb-4">
              <div className="flex px-2 py-4 sm:px-4">
                <img className="mr-2 flex h-8 w-8 rounded-full sm:mr-4" src={authorImage} alt="Author" />
                <div className="flex max-w-3xl items-center">
                  <p>{prompt.question}</p>
                </div>
              </div>
              <div className="mb-4 flex bg-slate-50 px-2 py-6 sm:px-4">
                <img className="mr-2 flex h-8 w-8 rounded-full sm:mr-4" src={Assets.APP_LOGO} alt="Author" />
                <div className="flex flex-col max-w-7xl items-start rounded-xl" dangerouslySetInnerHTML={{ __html: prompt.answer }} />
              </div>
            </div>
          ))}
      </div>
      <form className="mt-2 ml-4 mr-8" onSubmit={handleSubmit}>
        <label htmlFor="chat-input" className="sr-only">
          Enter your prompt
        </label>
        <div className="relative">
          <textarea
            id="chat-input"
            className="block w-full resize-none rounded-xl border-none bg-slate-200 p-4 ring-gray-500 pl-10 pr-20 ring-2 outline-none text-sm text-slate-900 sm:text-base"
            placeholder="Enter your prompt"
            rows={3}
            value={inputText}
            onChange={handleInputChange}
            onKeyDown={handleEnterPress}
            required
            readOnly={loading}
          ></textarea>
          <button type="submit" className="absolute bottom-4 right-8 rounded-lg bg-green-600 px-4 py-2 text-sm font-medium text-slate-200 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 sm:text-base" disabled={loading}>
            {loading ? "fetching..." : "Send"}
            <span className="sr-only">{loading ? "Loading message" : "Send message"}</span>
          </button>
        </div>
      </form>
    </div>
  )
}

export default Chat
